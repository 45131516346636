var base = "./assets/images/";
var typeSvg = ".svg";

export const ICONS = [
    { name: "icono-persona_codigo", path: base + "icono-persona_codigo" + typeSvg },
    { name: "icono-celular_codigo", path: base + "icono-celular_codigo" + typeSvg },
    { name: "cerrar-gris_codigo", path: base + "cerrar-gris_codigo" + typeSvg },
    { name: "flecha-abajo-gris", path: base + "flecha-abajo-gris" + typeSvg },
    { name: "flecha-arriba_gris", path: base + "flecha-arriba_gris" + typeSvg },
    { name: "flecha-derecha-gris_codigo", path: base + "flecha-derecha-gris_codigo" + typeSvg },
    { name: "icono-valid_codigo", path: base + "icono-valid_codigo" + typeSvg },
    { name: "icono-invalid_codigo", path: base + "icono-invalid_codigo" + typeSvg },
    { name: "icono-reloj_codigo", path: base + "icono-reloj_codigo" + typeSvg },
    { name: "icono-search_codigo", path: base + "icono-search_codigo" + typeSvg },
    { name: "icon-filtros_codigo_blanco", path: base + "icon-filtros_codigo_blanco" + typeSvg }
]