<app-header></app-header>
<div class="container">
  <h1 class="title">Lo sentimos</h1> 
  <p class="label">Al parecer esta página no está disponible</p>
  <button mat-button class="back-btn" (click)="onHome()">
    <p>volver al inicio</p>
    <custom-icon [routerLink]="['/home']" routerLinkActive="router-link-active" [svgName]="'flecha-derecha-gris_codigo'"
      [classIcon]="'btn-arrow'"></custom-icon>
  </button>
  <div class="error-image"></div>
</div>
