import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Router} from '@angular/router';
import {ObsService} from '../service/obs.service';
import {ServiceService} from '../service/service.service';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {StorageService} from '../service/storage.service';
import {CoreService} from '../service/core.service';
import {ValidateHelper} from '../utilities/validate.helper';
import {DatalayerService} from '../shared/services/datalayer.service';
import { DatalayerClickGa4Service } from '../shared/services/datalayer-click-ga4.service';

@Component({
  selector: 'app-comparador',
  templateUrl: './comparador.component.html',
  styleUrls: ['./comparador.component.scss']
})
export class ComparadorComponent implements OnInit {
  isFilter: boolean;
  showForm: boolean;
  openedSpecs: boolean = false;
  selectItems = [];
  array = [];

  categoryClick= {
    catComparador: 'comparador',
  }; 
  actionClick="click";
  labelClick={
    lblCambEquipo: 'cambiar equipo',
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private obs: ObsService,
    private router: Router,
    private service: ServiceService,
    private storageService: StorageService,
    private readonly coreService: CoreService,
    private readonly datalayer: DatalayerService,
    private svcClickDataLayer: DatalayerClickGa4Service,
  ) {
    let meta = {
      title: 'Catálogo Movistar Empresas',
      descrption: 'Description del comparar catalogo',
      ogTitle: 'Description del comparar catalogo para redes',
      ogImage: './assets/images/m-empresas-blanco.png'
    };
    this.obs.setValue(this.obs.metaData, meta);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }

    //Mostrar autocomplete en el header
    this.obs.setValue(this.obs.showAutocomplete, true);

    //Muestra el filtro en responsive
    this.obs.getValue(this.obs.showFilter).subscribe(resp => {
      this.isFilter = resp;
    });

    //Cerrar el modal del formulario
    this.obs.getValue(this.obs.showLoQuiero).subscribe(resp => {
      this.showForm = resp;
    });

    this.obs.getValue(this.obs.arrayComparador).subscribe(resp => {
      console.log(resp);
      this.selectItems = resp.map(i => {
        if (i.id != undefined) {
          this.service.getPhoneInfo(i.id).subscribe(data => {
            data.map(item => {
              switch (item.featureId) {
                case 'TEC':
                  i['tecnologia'] = item.value;
                  break;
                case 'PRO':
                  i['procesador'] = item.value;
                  break;
                case 'MIG':
                  i['memoriaInterna'] = item.value;
                  break;
                case 'MRG':
                  i['memoriaRAM'] = item.value;
                  break;
                case 'BAT':
                  i['bateria'] = item.value;
                  break;
                case 'RCT':
                  i['camaraTracera'] = item.value;
                  break;
                case 'RCF':
                  i['camaraFrontal'] = item.value;
                  break;
                case 'TDP':
                  i['pantalla'] = item.value;
                  break;
                default:
                  break;
              }
            });
          });
        }
        return i;
      });
    });
  }

  // Abre el detalle de equipos
  detailEquipo() {
    this.obs.setValue(this.obs.showAutocomplete, true);
    this.router.navigate(['/detalle']);
  }

  // Abre el modal con el formulario de pedido
  quieroEquipo(modalidadValue, selectPlanId, idPhone, phoneId?): void {

    this.storageService.setData('modalidadId', modalidadValue);
    this.storageService.setData('planId', selectPlanId);
    this.storageService.setData('itemSelectedPhone', idPhone);

    const phone = this.selectItems.find(val => val.id === phoneId);
    this.obs.setValue(this.obs.ecommerce, {phone, eventLabel: 'detalle de producto'});
    this.coreService.showLoQuiero = true;
    this.obs.setValue(this.obs.showLoQuiero, true);

    if (phone) {
      this.datalayer.datalayerEcommerce({
        gtmCategory: 'Ecommerce',
        gtmAction: 'Product click',
        gtmLabel: 'detalle de producto',
        gtmActionFieldList: 'detalle de producto',
        gtmProducts: [
          {
            name: ValidateHelper.datalayerString(phone.nombre),
            id: ValidateHelper.datalayerString(phone.codigo),
            price: ValidateHelper.datalayerString(phone.precio),
            brand: ValidateHelper.datalayerString(phone.brand),
            category: 'equipo',
            variant: 'negro',
            position: `${1}`
          }
        ]
      }, 1);
    }
  }

  return(id) {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catComparador,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblCambEquipo,
      gtmTipoProducto: this.storageService.getData('valueSelectModal'),
    },29);

    const change = this.selectItems.find(e => e.id === id);
    this.obs.setValue(this.obs.listComp, this.selectItems);
    this.obs.setValue(this.obs.itemChange, change.id);
    this.obs.setValue(this.obs.showAutocomplete, false);
    this.router.navigate(['/home']);
  }


  openSpecs() {
    this.openedSpecs = !this.openedSpecs;
  }

  numberWithSpaces(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return x;
    }
  }
}
