<footer>
  <section class="section-terminos-y-condiciones" *ngIf="showTerms">
    <div class="contenedor-general">
      <div class="section-terminos-y-condiciones-content">
        <div class="acordion-general acordion-terminos-y-condiciones" [class.active-acordion]="openedTerms">
          <div class="acordion-general__header acordion-terminos-y-condiciones__header" (click)="openTermsCond()">
            <button class="acordion-general__button"
                    appDatalayer gtm-category="catalogo b2b – footer" gtm-label="terminos y condiciones" (click)="DataLayerClick(1)">
              <span>Términos y condiciones</span><span><svg width="16"
                  height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 0.869831C0 0.626233 0.104724 0.394018 0.284576 0.230102C0.648833 -0.0954539 1.20433 -0.0726878 1.54126 0.280188L8.05919 7.17833L14.4519 0.284741C14.7866 -0.0704113 15.3421 -0.095454 15.7086 0.227825C16.0637 0.539722 16.1002 1.07928 15.7883 1.43443C15.7814 1.44126 15.7769 1.44809 15.7701 1.45492L8.72396 9.04971C8.55549 9.23184 8.31873 9.33429 8.07058 9.33429C7.82243 9.33656 7.58338 9.23639 7.41264 9.05654L0.236767 1.45947C0.0845137 1.30097 -0.000356674 1.08961 0 0.869831Z"
                    fill="#019DF4" />
                </svg></span></button>
          </div>
          <div class="acordion-general__body acordion-terminos-y-condiciones__body" *ngIf="openedTerms">
            <div class="acordion-general__body-content" [innerHTML]="termsInfo">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #footer>
    <div class="flex">
      <div class="col">
        <img src="../../../assets/icon/telefonica-logo.svg" alt="telefonica logo">

          <img src="../../../assets/icon/empresa-logo.svg" alt="empresa logo" style="width: 116px;">

      </div>
      <div *ngIf="showNav" class="col">
        <ul>
          <li appDatalayer gtm-category="catalogo b2b – footer" gtm-label="mas de movistar empresas" (click)="DataLayerClick(2)">
            <a target="_blank" href="https://tiendaonline.movistar.com.pe/empresas/ofertas-empresas">Más de Movistar Empresas</a></li>
          <li appDatalayer gtm-category="catalogo b2b – footer" gtm-label="fija" (click)="DataLayerClick(3)">
            <a target="_blank" href="https://tiendaonline.movistar.com.pe/empresas/ofertas-empresas?sc=1">Fija</a></li>
          <li appDatalayer gtm-category="catalogo b2b – footer" gtm-label="productos digitales" (click)="DataLayerClick(4)">
            <a target="_blank" href="https://tiendaonline.movistar.com.pe/empresas/ofertas-empresas/Productosdigitales">Productos digitales</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</footer>
