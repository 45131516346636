<app-header></app-header>
<section class="section-banner">
    <div class="swiper-container banner-swiper" [class.banner-type1]="typeBanner == 1" [class.banner-type2]="typeBanner == '2'" [class.banner-type3]="typeBanner == '3'" [class.banner-type4]="typeBanner == '4'" [class.banner-type5]="typeBanner == '5'" [class.banner-type6]="typeBanner == '6'">
        <swiper [config]="swiperBannerConfig" class="swiper-wrapper" (mouseover)="hoverInSwiper('banner')" (mouseout)="hoverOutSwiper('banner')" (indexChange)="onSlideChange($event)">
            <div swiperSlide class="swiper-slide" *ngFor="let banner of bannerList">
                <div class="banner-content" *ngIf="banner.type == 1" [ngStyle]="{'background-image': isResponsive == true ? 'url(' + banner.bannerMovile + ')' : 'url(' + banner.bannerDescktop + ')'}">
                    <div class="content-flex">
                        <div class="col-left">
                            <h2 class="title" *ngIf="banner.showTitle == true">{{banner.title}}</h2>
                            <p class="description" *ngIf="banner.showSubTitle == true">{{banner.description}}</p>
                            <div class="buttons" *ngIf="banner.showWantBtn == true || banner.showDetailBtn == true">
                                <button *ngIf="banner.showDetailBtn == true" [ngClass]="{'outlineBtn': banner.detailType == 'outline','flatBtn': banner.detailType == 'flat'}" [style.background-color]="banner.detailBackgroundColor" [style.color]="banner.detailColor" [style.border-color]="banner.detailBorderColor"
                                    mat-button (click)="detailEquipo(banner.model)">VER DETALLE</button>
                                <button appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero" *ngIf="banner.showWantBtn == true"
                                        [class.outlineBtn]="banner.wantType == 'outline'" [class.flatBtn]="banner.wantType == 'flat'" [style.background-color]="banner.wantBackgroundColor" [style.color]="banner.wantColor" [style.border-color]="banner.wantBorderColor"
                                    mat-button (click)="quieroEquipo(banner.phoneId,null,banner.bannerId, null)">LO QUIERO</button>
                            </div>
                        </div>
                        <div class="col-right">
                            <div class="phone-descript">
                                <div class="label-nuevo" *ngIf="banner.showFlag == true" [ngStyle]="{'background-color': banner.flagBackgroundColor, 'color': banner.flagColor}">
                                    {{banner.flagText}}</div>
                                <div class="bannerCard" *ngIf="banner.showDiscount == true || banner.showPrices == true" [class.cardRadius]="banner.showDiscount == false || banner.showPrices == false">
                                    <div class="descuento" *ngIf="banner.showDiscount == true" [ngStyle]="{'background-color': banner.discountBackgroundColor}" [ngClass]="{'only': banner.showPrices == false}">
                                        <p [ngStyle]="{'color': banner.discountColor}">
                                            <span class="descuento-number">{{banner.descuento}}</span>
                                            <span class="descuento-label">descuento</span>
                                        </p>
                                    </div>
                                    <div class="precios" *ngIf="banner.showPrices == true" [ngStyle]="{'background-color': banner.priceBackgroundColor}" [ngClass]="{'only': banner.showDiscount == false}">
                                        <p [ngStyle]="{'color': banner.priceColor}">
                                            <span class="precios-label">Precio al contado</span>
                                            <span class="precios-count">{{numberWithSpaces(banner.priceCount)}}</span>
                                            <span class="precios-regular">P.regular {{numberWithSpaces(banner.priceRegular)}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <img [src]="isResponsive == true ? banner.phoneMovile : banner.phoneDesktop">
                        </div>
                    </div>
                </div>
                <div class="banner-content" *ngIf="typeBanner == '2'" [ngStyle]="{'background-image': isResponsive == true ? 'url(' + banner.bannerMovile + ')' : 'url(' + banner.bannerDescktop + ')'}">
                    <div class="content-flex">
                        <div class="col-left">
                            <img [src]="isResponsive == true ? banner.phoneMovile : banner.phoneDesktop">
                            <div class="phone-descript">
                                <div class="label-nuevo" *ngIf="banner.showFlag == true" [ngStyle]="{'background-color': banner.flagBackgroundColor, 'color': banner.flagColor}">
                                    {{banner.flagText}}</div>
                                <div class="bannerCard" *ngIf="banner.showDiscount == true || banner.showPrices == true" [class.cardRadius]="banner.showDiscount == false || banner.showPrices == false">
                                    <div class="descuento" *ngIf="banner.showDiscount == true" [ngStyle]="{'background-color': banner.discountBackgroundColor}" [ngClass]="{'only': banner.showPrices == false}">
                                        <p [ngStyle]="{'color': banner.discountColor}">
                                            <span class="descuento-number">{{banner.descuento}}</span>
                                            <span class="descuento-label">descuento</span>
                                        </p>
                                    </div>
                                    <div class="precios" *ngIf="banner.showPrices == true" [ngStyle]="{'background-color': banner.priceBackgroundColor}" [ngClass]="{'only': banner.showDiscount == false}">
                                        <p [ngStyle]="{'color': banner.pricecolor}">
                                            <span class="precios-label">Precio al contado</span>
                                            <span class="precios-count">{{numberWithSpaces(banner.priceCount)}}</span>
                                            <span class="precios-regular">P.regular {{numberWithSpaces(banner.priceRegular)}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-right">
                            <h2 class="title" *ngIf="banner.showTitle == true">{{banner.title}}</h2>
                            <p class="description" *ngIf="banner.showSubTitle == true">{{banner.description}}</p>
                            <div class="buttons" *ngIf="banner.showWantBtn == true || banner.showDetailBtn == true">
                                <button *ngIf="banner.showDetailBtn == true" [ngClass]="{'outlineBtn': banner.detailType == 'outline','flatBtn': banner.detailType == 'flat'}" [style.background-color]="banner.detailBackgroundColor" [style.color]="banner.detailColor" [style.border-color]="banner.detailBorderColor"
                                    mat-button (click)="detailEquipo(banner.model)">VER
                  DETALLE</button>
                                <button appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero" *ngIf="banner.showWantBtn == true"
                                        [class.outlineBtn]="banner.wantType == 'outline'" [class.flatBtn]="banner.wantType == 'flat'" [style.background-color]="banner.wantBackgroundColor" [style.color]="banner.wantColor" [style.border-color]="banner.wantBorderColor"
                                    mat-button (click)="quieroEquipo(banner.phoneId,null,banner.bannerId, null)">LO QUIERO</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-content" *ngIf="typeBanner == '3'" [ngStyle]="{'background-image': isResponsive == true ? 'url(' + banner.bannerMovile + ')' : 'url(' + banner.bannerDescktop + ')'}">
                    <div class="content-flex">
                        <div class="col-left">
                            <h2 class="title" *ngIf="banner.showTitle == true">{{banner.title}}</h2>
                            <p class="description" *ngIf="banner.showSubTitle == true">{{banner.description}}</p>
                            <div class="buttons" *ngIf="banner.showWantBtn == true || banner.showDetailBtn == true">
                                <button *ngIf="banner.showDetailBtn == true" [ngClass]="{'outlineBtn': banner.detailType == 'outline','flatBtn': banner.detailType == 'flat'}" [style.background-color]="banner.detailBackgroundColor" [style.color]="banner.detailColor" [style.border-color]="banner.detailBorderColor"
                                    mat-button (click)="detailEquipo(banner.model)">VER
          DETALLE</button>
                                <button appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero" *ngIf="banner.showWantBtn == true"
                                        [class.outlineBtn]="banner.wantType == 'outline'" [class.flatBtn]="banner.wantType == 'flat'" [style.background-color]="banner.wantBackgroundColor" [style.color]="banner.wantColor" [style.border-color]="banner.wantBorderColor"
                                    mat-button (click)="quieroEquipo(banner.phoneId,null,banner.bannerId, null)">LO QUIERO</button>
                            </div>
                            <div class="aditional">
                                <div><img src=" ./assets/images/iconPostpago.svg">Postpago</div>
                                <div><img src=" ./assets/images/iconDelivery.svg">Delivery gratis</div>
                            </div>
                        </div>
                        <div class="col-right">
                            <img [src]="isResponsive == true ? banner.phoneMovile : banner.phoneDesktop">
                        </div>
                    </div>
                </div>
                <div class="banner-content" *ngIf="typeBanner == '4'" [ngStyle]="{'background-image': isResponsive == true ? 'url(' + banner.bannerMovile + ')' : 'url(' + banner.bannerDescktop + ')'}">
                    <div class="content-flex">
                        <div class="col-left">
                            <h2 class="title" *ngIf="banner.showTitle == true">{{banner.title}}</h2>
                            <p class="description" *ngIf="banner.showSubTitle == true" [innerHtml]='banner.description'></p>
                            <div class="buttons" *ngIf="banner.showWantBtn == true || banner.showDetailBtn == true">
                                <button *ngIf="banner.showDetailBtn == true" [ngClass]="{'outlineBtn': banner.detailType == 'outline','flatBtn': banner.detailType == 'flat'}" [style.background-color]="banner.detailBackgroundColor" [style.color]="banner.detailColor" [style.border-color]="banner.detailBorderColor"
                                    mat-button (click)="detailEquipo(banner.model)">VER
          DETALLE</button>
                                <button appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero" *ngIf="banner.showWantBtn == true"
                                        [class.outlineBtn]="banner.wantType == 'outline'" [class.flatBtn]="banner.wantType == 'flat'" [style.background-color]="banner.wantBackgroundColor" [style.color]="banner.wantColor" [style.border-color]="banner.wantBorderColor"
                                    mat-button (click)="quieroEquipo(banner.phoneId,null,banner.bannerId, null)">LO QUIERO</button>
                            </div>
                            <div class="aditional">
                                <div><img src=" ./assets/images/iconPostpagoBlue.svg">Postpago</div>
                                <div><img src=" ./assets/images/iconDeliveryBlue.svg">Delivery gratis</div>
                            </div>
                        </div>
                        <div class="col-right">
                            <div class="phone-descript">
                                <div class="bannerCard" *ngIf="banner.showDiscount == true || banner.showPrices == true" [class.cardRadius]="banner.showDiscount == false || banner.showPrices == false">
                                    <div class="descuento" *ngIf="banner.showDiscount == true" [ngStyle]="{'background-color': banner.discountBackgroundColor}" [ngClass]="{'only': banner.showPrices == false}">
                                        <p [ngStyle]="{'color': banner.discountColor}">
                                            <span class="descuento-text">Hasta</span>
                                            <span class="descuento-number">{{banner.descuento}}%</span>
                                            <span class="descuento-label">Dscto.</span>
                                        </p>
                                    </div>
                                    <div class="precios" *ngIf="banner.showPrices == true" [ngStyle]="{'background-color': banner.priceBackgroundColor}" [ngClass]="{'only': banner.showDiscount == false}">
                                        <p [ngStyle]="{'color': banner.priceColor}">
                                            <span class="precios-label">Desde plan Mi Movistar Empresas S/ {{numberWithSpaces(banner.priceCount)}}</span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <img [src]="isResponsive == true ? banner.phoneMovile : banner.phoneDesktop">
                        </div>
                    </div>
                </div>
                <div class="banner-content" *ngIf="banner.type == 5" [ngStyle]="{'background-image': isResponsive == true ? 'url(' + banner.bannerMovile + ')' : 'url(' + banner.bannerDescktop + ')'}">
                    <div class="content-flex">
                        <div class="col-left">
                            <h2 class="title" *ngIf="banner.showTitle == true">{{banner.title}}</h2>
                            <p class="description" *ngIf="banner.showSubTitle == true" [innerHTML]="banner.description"></p>
                            <div class="buttons" *ngIf="banner.showWantBtn == true || banner.showDetailBtn == true">
                                <button *ngIf="banner.showDetailBtn == true" [ngClass]="{'outlineBtn': banner.detailType == 'outline','flatBtn': banner.detailType == 'flat'}" [style.background-color]="banner.detailBackgroundColor" [style.color]="banner.detailColor" [style.border-color]="banner.detailBorderColor"
                                    mat-button (click)="detailEquipo(banner.model)">VER
          DETALLE</button>
                                <button appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero" *ngIf="banner.showWantBtn == true"
                                        [class.outlineBtn]="banner.wantType == 'outline'" [class.flatBtn]="banner.wantType == 'flat'" [style.background-color]="banner.wantBackgroundColor" [style.color]="banner.wantColor" [style.border-color]="banner.wantBorderColor"
                                    mat-button (click)="quieroEquipo(banner.phoneId,null,banner.bannerId, null)">LO QUIERO</button>
                            </div>
                            <div class="aditional">
                                <div><img src=" ./assets/images/iconPostpago.svg">Postpago</div>
                                <div><img src=" ./assets/images/iconDelivery.svg">Delivery gratis</div>
                            </div>
                        </div>
                        <div class="col-right">
                            <img [src]="isResponsive == true ? banner.phoneMovile : banner.phoneDesktop">
                        </div>
                    </div>
                </div>
                <div class="banner-content" *ngIf="typeBanner == '6'" [ngStyle]="{'background-image': isResponsive == true ? 'url(' + banner.bannerMovile + ')' : 'url(' + banner.bannerDescktop + ')'}">
                    <div class="content-flex">
                        <div [innerHTML]='banner.html'></div>
                    </div>
                </div>
            </div>
        </swiper> 
    </div>
</section>
<section class="section-buscador">
    <div class="contenedor-general">
        <div class="buscador-content">
            <h1 class="buscador--titulo">Encuentra el equipo que tu empresa necesita</h1>
            <div class="buscador--rutas">
                <mat-chip-list class="buscador--rutas_body" aria-label="line selection">
                    <mat-chip *ngFor="let search of searchItem" class="buscador--rutas_enlace"
                              appDatalayer gtm-category="catalogo b2b - buscador" [gtm-label]="search.name"
                              (click)="filterModalidad(search)" [class.active]="search.active">{{search.name}}</mat-chip>
                </mat-chip-list>
            </div>
            <div class="buscador--input">
                <form class="autocomplete-form" [class.showSearch]="openSearch" (scroll)="scrollAutocomplete()">
                    <mat-form-field class="example-full-width" appearance="outline" [class.activeInput]="isOpenAutocomplete">
                        <mat-label class="autocompleteLabel">¿Qué equipo necesitas?</mat-label>
                        <input type="text" aria-label="Number" matInput #autocompleteInput
                               appDatalayer gtm-category="catalogo b2b - buscador" gtm-label="¿que equipo necesitas?"
                               [formControl]="myControl" [matAutocomplete]="auto" (keyup)="reload($event)" [(ngModel)]="selectedOptionClear" (click)="onClickHomeBuscadorDataLayer()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (opened)="openPanel()" (closed)="closePanel()" class="autoPanel respAutocomplete" classList="autoPanel">
                            <hr *ngIf="isOpenAutocomplete" class="inputSeparator">
                            <mat-optgroup label="Marca">
                                <mat-option *ngFor="let brand of filterBrand | async"
                                            appDatalayer gtm-category="catalogo b2b - buscador" gtm-label="sugerencia marcas - {{brand.name}}"
                                            [value]="brand.name" (click)="filterAuto(brand.name, 'brand')">
                                    {{brand.name}}
                                </mat-option>
                            </mat-optgroup>
                            <hr style="width:95%; margin:0 auto; border: 1px solid #D3D4D3;">
                            <mat-optgroup label="Equipos">
                                <mat-option *ngFor="let option of filterPhones | async"
                                            appDatalayer gtm-category="catalogo b2b - buscador" gtm-label="sugerencia equipo - {{option.name}}"
                                            [value]="option.name" (click)="filterAuto(option.name, 'phones')">
                                    {{option.name}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                        <span matSuffix>
              <custom-icon *ngIf="isOpenAutocomplete" [svgName]="'flecha-arriba_gris'" [classIcon]="'searchIcon'">
              </custom-icon>
              <custom-icon *ngIf="!isOpenAutocomplete"
                           appDatalayer gtm-category="catalogo b2b - buscador" gtm-label="buscar"
                           [svgName]="'icono-search_codigo'" [classIcon]="'searchIcon'">
              </custom-icon>
            </span>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
</section>
<section class="section-layout-general">
    <div class="contenedor-general">
        <div class="layout-content">
            <div class="layout-left layout-left_filtros">
                <h2 class="layout-left_filtros--titulo">Filtros</h2>
                <p class="layout-left_filtros--descripcion">Encuentra el equipo ideal</p>
                <div class="palabras-filtradas">
                    <mat-chip-list #chipList aria-label="filter selection" class="palabras-filtradas__item">
                        <mat-chip class="palabras-filtradas__item-body" *ngFor="let filter of filterItem" [selectable]="selectable" [removable]="removable" (removed)="remove(filter.name, filter.type)">
                            {{filter.name}}
                            <mat-icon appDatalayer gtm-category="catalogo b2b – filtros" gtm-label="quitar filtro" matChipRemove *ngIf="removable">close</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <button class="layout-left_filtros_button-limpiar" appDatalayer gtm-category="catalogo b2b – filtros" gtm-label="limpiar filtros" (click)="clean()">Limpiar filtros</button>
                <h3 class="layout-left_filtros--subtitulo">Marca</h3>
                <ul class="checkbox-contenedor" id="brandCheckbox" [ngClass]="{'active': showAll}">
                    <li class="checkbox-contenedor__item" *ngFor="let brand of brandItem">
                        <mat-checkbox (change)="selectCheckbox($event, brand)" [(ngModel)]="brand.active"
                                      appDatalayer gtm-category="catalogo b2b – filtros" gtm-label="marca - {{brand.name}}">
                          {{brand.name}}
                        </mat-checkbox>
                    </li>
                </ul>
                <div class="showMore" (click)="showMore()">
                    <p>{{showAll == true ? 'Mostrar menos' : 'Mostrar más'}}</p>
                    <mat-icon>{{showAll == true ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                </div>
                <h3 class="layout-left_filtros--subtitulo">Plan</h3>
                <ul class="checkbox-contenedor" id="planCheckbox">
                    <li class="checkbox-contenedor__item" *ngFor="let plan of planItem">
                        <mat-checkbox (change)="selectCheckboxPlan($event, plan)" [(ngModel)]="plan.active"
                                      appDatalayer gtm-category="catalogo b2b – filtros" gtm-label="plan - {{plan.name}}">
                          S/ {{plan.label}}
                        </mat-checkbox>
                    </li>
                </ul>
                <h3 class="layout-left_filtros--subtitulo">Precio de equipos</h3>
                <div class="range-container">
                    <ngx-slider *ngIf="isBrowser" class="multi-range-slider" [(value)]="value" [(highValue)]="highValue" [options]="options" (mouseup)="sliderEvent(value, highValue)">
                    </ngx-slider>
                    <div class="flex-form">
                        <mat-form-field>
                            <span matPrefix>S/ &nbsp;</span>
                            <input matInput [(ngModel)]="value">
                        </mat-form-field>
                        <mat-form-field>
                            <span matPrefix>S/ &nbsp;</span>
                            <input matInput [(ngModel)]="highValue">
                        </mat-form-field>
                    </div>
                    <div class="multi-range-aviso">
                        <p>Monto máx. a ingresar S/ 10000</p>
                    </div>
                    <div class="multi-range-aplicar-filtro">
                        <button appDatalayer gtm-category="catalogo b2b – filtros" gtm-label="aplicar filtro" (click)="applyFilter()">APLICAR FILTRO</button>
                    </div>
                </div>
            </div>

            <div class="layout-right layout-right_celulares">
                <div class="layout-right_body" > 
                    <div class="section-ordenar-content" (click)="DataLayerClick(10000)">
                        <app-custom-select class="order" [label]="'Ordenar por'"
                                           appDatalayer gtm-category="catalogo b2b – orden" gtm-label="ordenar por"
                                           [options]="tipoOrdenItem" (sendValue)="setValueOrder($event)">
                        </app-custom-select>
                    </div>

                    <div [hidden]="isSwiperVisible()">
                        <div #swiperContainer class="swiper-container equipos_navidad_mobile">
                            <div class="swiper-wrapper">
                                <div swiperSlide class="swiper-slide navidad_card_slide" *ngFor="let phone of navidadEquipos.slice(0, 4); let i = index"> 
                                    <div class="equipos_navidad_card">
                                        <div class="equipos_navidad_card_contenido">
                                            <div class="equipos_navidad_card_info">
                                                <p class="equipos_navidad_card_marca">
                                                    {{phone.brand}}
                                                </p>
                                                <p class="equipos_navidad_card_name">
                                                    {{phone.name}}
                                                    <span *ngIf="phone.name2"> <span class="plus">+</span> {{ phone.name2 }}</span>
                                                </p>
                                                <div class="equipos_navidad_card_contenedor">
                                                    <img src="../../assets/images/pack-navidad/PNG_NOV_MonioEquiposNavidad.png" alt="Monio" class="equipos_navidad_card_monio_img">
                                                    <div class="equipos_navidad_card_contado">
                                                        <p class="equipos_navidad_card_paymentMethod">
                                                            {{phone.paymentMethod}}
                                                        </p>
                                                        <div class="equipos_navidad_card_precio">
                                                            <span>{{phone.typeCurrency}}</span>{{phone.price}}
                                                        </div>
                                                    </div>
                                                    <div class="equipos_navidad_card_plan">
                                                        <p class="equipos_navidad_card_planText">
                                                            {{phone.planText}}
                                                        </p>
                                                        <div class="equipos_navidad_card_precio_plan">
                                                            <span class="moneda_plan">{{phone.PlanValueTypeCurrency}}</span>
                                                            {{phone.planValue}}
                                                            <span class="precio_decimal">.{{phone.PlanValueDecimal}}</span>
                                                        </div>
                                                        <p class="equipos_navidad_card_precio_precio">
                                                            {{phone.previousPrice}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button class="equipos_navidad_card_btn_loquiero"
                                                    appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero"
                                                    (click)="quieroEquipo(phone.id,null, phone.idBanner, null)">Lo quiero</button>
                                            </div>
                                            <div class="equipos_navidad_card_ilustracion">
                                                <img [src]="phone.imageCelphone" [alt]="phone.name"  class="equipos_navidad_card_ilust_img"/>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                            </div> 
                            <div class="swiper-pagination"></div>
                        </div>
                        <div #swiperContainer2 class="swiper-container equipos_navidad_mobile">
                            <div class="swiper-wrapper">
                                <div swiperSlide class="swiper-slide navidad_card_slide" *ngFor="let phone of navidadEquipos.slice(4, 8); let i = index"> 
                                    <div class="equipos_navidad_card">
                                        <div class="equipos_navidad_card_contenido">
                                            <div class="equipos_navidad_card_info">
                                                <p class="equipos_navidad_card_marca">
                                                    {{phone.brand}}
                                                </p>
                                                <p class="equipos_navidad_card_name">
                                                    {{phone.name}}
                                                    <span *ngIf="phone.name2"> <span class="plus">+</span> {{ phone.name2 }}</span>
                                                </p>
                                                <div class="equipos_navidad_card_contenedor">
                                                    <img src="../../assets/images/pack-navidad/PNG_NOV_MonioEquiposNavidad.png" alt="Monio" class="equipos_navidad_card_monio_img">
                                                    <div class="equipos_navidad_card_contado">
                                                        <p class="equipos_navidad_card_paymentMethod">
                                                            {{phone.paymentMethod}}
                                                        </p>
                                                        <div class="equipos_navidad_card_precio">
                                                            <span>{{phone.typeCurrency}}</span>{{phone.price}}
                                                        </div>
                                                    </div>
                                                    <div class="equipos_navidad_card_plan">
                                                        <p class="equipos_navidad_card_planText">
                                                            {{phone.planText}}
                                                        </p>
                                                        <div class="equipos_navidad_card_precio_plan">
                                                            <span class="moneda_plan">{{phone.PlanValueTypeCurrency}}</span>
                                                            {{phone.planValue}}
                                                            <span class="precio_decimal">.{{phone.PlanValueDecimal}}</span>
                                                        </div>
                                                        <p class="equipos_navidad_card_precio_precio">
                                                            {{phone.previousPrice}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button class="equipos_navidad_card_btn_loquiero"
                                                appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero"
                                                        (click)="quieroEquipo(phone.id,null, phone.idBanner, null)">Lo quiero</button>
                                            </div>
                                            <div class="equipos_navidad_card_ilustracion">
                                                <img [src]="phone.imageCelphone" [alt]="phone.name"  class="equipos_navidad_card_ilust_img"/>
                                            </div> 
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div class="swiper-pagination"></div> 
                        </div>
                        <div #swiperContainer3 class="swiper-container equipos_navidad_mobile">
                            <div class="swiper-wrapper">
                                <div swiperSlide class="swiper-slide navidad_card_slide" *ngFor="let phone of navidadEquipos.slice(8, 13); let i = index">
                                    <div class="equipos_navidad_card">
                                        <div class="equipos_navidad_card_contenido">
                                            <div class="equipos_navidad_card_info">
                                                <p class="equipos_navidad_card_marca">
                                                    {{phone.brand}}
                                                </p>
                                                <p class="equipos_navidad_card_name">
                                                    {{phone.name}}
                                                    <span *ngIf="phone.name2"> <span class="plus">+</span> {{ phone.name2 }}</span>
                                                </p>
                                                <div class="equipos_navidad_card_contenedor">
                                                    <img src="../../assets/images/pack-navidad/PNG_NOV_MonioEquiposNavidad.png" alt="Monio" class="equipos_navidad_card_monio_img">
                                                    <div class="equipos_navidad_card_contado">
                                                        <p class="equipos_navidad_card_paymentMethod">
                                                            {{phone.paymentMethod}}
                                                        </p>
                                                        <div class="equipos_navidad_card_precio">
                                                            <span>{{phone.typeCurrency}}</span>{{phone.price}}
                                                        </div>
                                                    </div>
                                                    <div class="equipos_navidad_card_plan">
                                                        <p class="equipos_navidad_card_planText">
                                                            {{phone.planText}}
                                                        </p>
                                                        <div class="equipos_navidad_card_precio_plan">
                                                            <span class="moneda_plan">{{phone.PlanValueTypeCurrency}}</span>
                                                            {{phone.planValue}}
                                                            <span class="precio_decimal">.{{phone.PlanValueDecimal}}</span>
                                                        </div>
                                                        <p class="equipos_navidad_card_precio_precio">
                                                            {{phone.previousPrice}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button class="equipos_navidad_card_btn_loquiero"
                                                    appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero"
                                                    (click)="quieroEquipo(phone.id,null, phone.idBanner, null)">Lo quiero</button>
                                            </div>
                                            <div class="equipos_navidad_card_ilustracion">
                                                <img [src]="phone.imageCelphone" [alt]="phone.name"  class="equipos_navidad_card_ilust_img"/>
                                            </div> 
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="swiper-pagination"></div> 
                        </div>
                        
                        <ul class="equipos_navidad_content equipos_navidad_desktop">
                            <li class="equipos_navidad_card_item" *ngFor="let phone of navidadEquipos; let i = index"> 
                                 <div class="equipos_navidad_card">
                                    <div class="equipos_navidad_card_contenido">
                                        <div class="equipos_navidad_card_info">
                                            <p class="equipos_navidad_card_marca">
                                                {{phone.brand}}
                                            </p>
                                            <p class="equipos_navidad_card_name">
                                                {{phone.name}}
                                                <span *ngIf="phone.name2"> <span class="plus">+</span> {{ phone.name2 }}</span>
                                            </p>
                                            <div class="equipos_navidad_card_contenedor">
                                                <img src="../../assets/images/pack-navidad/PNG_NOV_MonioEquiposNavidad.png" alt="Monio" class="equipos_navidad_card_monio_img">
                                                <div class="equipos_navidad_card_contado">
                                                    <p class="equipos_navidad_card_paymentMethod">
                                                        {{phone.paymentMethod}}
                                                    </p>
                                                    <div class="equipos_navidad_card_precio">
                                                        <span>{{phone.typeCurrency}}</span>{{phone.price}}
                                                    </div>
                                                </div>
                                                <div class="equipos_navidad_card_plan">
                                                    <p class="equipos_navidad_card_planText">
                                                        {{phone.planText}}
                                                    </p>
                                                    <div class="equipos_navidad_card_precio_plan">
                                                        <span class="moneda_plan">{{phone.PlanValueTypeCurrency}}</span>
                                                        {{phone.planValue}}
                                                        <span class="precio_decimal">.{{phone.PlanValueDecimal}}</span>
                                                    </div>
                                                    <p class="equipos_navidad_card_precio_precio">
                                                        {{phone.previousPrice}}
                                                    </p>
                                                </div>
                                            </div>
                                            <button class="equipos_navidad_card_btn_loquiero"
                                                appDatalayer gtm-category="catalogo b2b - slider" gtm-label="lo quiero"
                                                (click)="quieroEquipo(
                                                    phone.id,null, phone.idBanner, null
                                                )">Lo quiero</button>
                                        </div>
                                        <div class="equipos_navidad_card_ilustracion">
                                            <img [src]="phone.imageCelphone" [alt]="phone.name"  class="equipos_navidad_card_ilust_img"/>
                                        </div>
                                        
                                    </div>
                                 </div>
                            </li>
                        </ul> 
                    </div>

                    <div [hidden]="!isSwiperVisible()">
                        <ul class="celular-card-content">
                            <li class="celular-card__item" *ngFor="let phone of phoneItem | paginate: {id: 'pagination_home', itemsPerPage: itemMax, currentPage: p }; let i = index">
                                <div class="celular-card">
                                    <div class="celular-card--comparar">
                                        <mat-checkbox class="checkbox-container" [(ngModel)]="phone.checked" labelPosition="before" (change)="modalComparar($event, phone)" [disabled]="phone.disabled">Comparar
                                        </mat-checkbox>
                                    </div>
                                    <div class="celular-card--top">
                                        <div class="celular-card__img">
                                            <img [src]="phone.image" [alt]="phone.model" />
                                        </div>
                                        <div class="celular-card__info">
                                            <h3 class="celular-card__info-nombre">
                                                <span class="celular-card__info-nombre--top">{{phone.brand}}</span>
                                                <span class="celular-card__info-nombre--bottom">{{phone.model}}</span>
                                            </h3>
                                            <p class="celular-card__info-plan">{{phone.plan}}</p>
                                            <p class="celular-card__info-precio">
                                                <span class="celular-card__info-precio--top">Precio al contado</span>
                                                <span class="celular-card__info-precio--bottom">S/ {{numberWithSpaces(phone.priceCount)}}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="celular-card--bottom">
                                        <div class="celular-card__info-botones">
                                            <button class="celular-card__info-botones--detalle"
                                                    appDatalayer [gtm-equiment]="true" [data-auto]="true" [data-id]="phone.id"
                                                    gtm-category="catalogo b2b – equipos" gtm-label="ver detalle" [gtm-brand]="phone.brand" [gtm-type]="phone.modality?.name"
                                                    (click)="detailEquipo(phone.model,phone.planInfo.value, phone.id, true, 'catalogo',phone)">VER DETALLE</button>
                                            <button class="celular-card__info-botones--loquiero"
                                                    appDatalayer [gtm-equiment]="true" [data-auto]="true" [data-id]="phone.id"
                                                    gtm-category="catalogo b2b – equipos" gtm-label="lo quiero" [gtm-brand]="phone.brand" [gtm-type]="phone.modality?.name"
                                                    (click)="quieroEquipo(phone.id,phone.planInfo.value,null, phone.modality?.value, true, 'catalogo')">LO QUIERO</button>
                                            <span *ngIf="phone.tagColor !== null" class="celular-card--etiqueta ">
                          <span class="etiqueta-content" [style.background-color]='phone.tagColor'>
                            <span class="etiqueta-content__info">{{phone.tagText}}</span>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="section-pagination-content">
                            <pagination-controls id="pagination_home" (pageChange)="p = $event; pageChanged($event)"
                                previousLabel="Anterior" nextLabel="Siguiente" appDatalayer gtm-category="catalogo b2b – equipos" 
                                [gtm-label]="'paginacion ' + p" (click)="VerificarClickAntSig($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div> 
            </div>  
        </div>
    </div>
</section>
<section class="section-equipos-buscados">
    <div class="contenedor-general">
        <div class="equipos-buscados-content">
            <h2 class="equipos-buscados-content__titulo">Conoce los equipos más buscados</h2>
            <div class="swiper-container equipos-buscados-swiper">
                <swiper [config]="swiperPhoneConfig" class="swiper-wrapper" (mouseover)="hoverInSwiper('phone')" (mouseout)="hoverOutSwiper('phone')"
                        (slidePrevTransitionEnd)="onSlidePrev()" (slideNextTransitionEnd)="onSlideNext()">
                    <div swiperSlide class="swiper-slide" *ngFor="let salient of salientList">
                        <div class="equipo-buscado">
                            <div class="equipo-buscado__img">
                                <img [src]="salient.image" alt="celular" />
                                <span *ngIf="salient.tagColor !== null" class="celular-card--etiqueta2">
                                    <span class="etiqueta-content2" [style.background-color]='salient.tagColor'>
                                      <span class="etiqueta-content__info">{{salient.tagText}}</span>
                                </span>
                                </span>
                            </div>
                            <h3 class="equipo-buscado__titulo"><span class="equipo-buscado__titulo-top">{{salient.brand}}</span><span class="equipo-buscado__titulo-bottom">{{salient.model}}</span></h3>
                            <p class="equipo-buscado__plan">{{salient.plan}}</p>
                            <p class="equipo-buscado__precio"><span class="equipo-buscado__precio-top">Precio al contado</span><span class="equipo-buscado__precio-bottom">S/ {{numberWithSpaces(salient.priceCount)}}</span></p>
                            <button class="equipo-buscado__button"
                                    appDatalayer [gtm-equiment]="true" gtm-category="catalogo b2b – carrusel equipos" gtm-label="lo quiero" [gtm-brand]="salient.brand" [gtm-type]="salient.modality?.name"
                                    (click)="quieroEquipo(salient.id,salient.planInfo.value,null, salient.modality?.value, true, 'equipo mas buscados')">LO QUIERO</button>

                        </div>
                    </div>
                </swiper>
            </div>
        </div>
    </div>
</section>
<app-footer [showTerms]="'true'"></app-footer>
<app-modal-filter *ngIf="isFilter"></app-modal-filter>
<!-- <app-filter-modal *ngIf="isFilter"></app-filter-modal> -->
<app-modal-comparador *ngIf="openComparador"></app-modal-comparador>
<app-modal-form *ngIf="showForm"></app-modal-form>
