import {ObsService} from '../service/obs.service';
import {Component, OnInit, ViewChild, ViewEncapsulation, PLATFORM_ID, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ServiceService} from '../service/service.service';
import {AfterContentChecked} from '@angular/core';
import {GESTION_TYPE, JSON_BANNER_URL, JSON_DESTACADO_URL} from '../utilities/globalConstants';
import {ApiService} from '../service/api.service';
import {StorageService} from '../service/storage.service';

import {
  SwiperConfigInterface,
} from 'ngx-swiper-wrapper';
import {DOCUMENT, isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import {PaginationOptions} from 'swiper/types/components/pagination';
import {ScrollbarOptions} from 'swiper/types/components/scrollbar';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {FormControl} from '@angular/forms';
import {DatalayerService} from '../shared/services/datalayer.service';
import {ValidateHelper} from '../utilities/validate.helper';
import {CoreService} from '../service/core.service';
import { DatalayerGA4Service } from '../shared/services/datalayer-ga4.service';
import { DatalayerClickGa4Service } from '../shared/services/datalayer-click-ga4.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  showTermsFooter: boolean = false;
  openedTerms: boolean = false;
  openedDetail: boolean = false;
  openedSpecs: boolean = false;
  phoneBrand: string;
  phoneModel: string;
  selectImage: any;
  thumbSwip: any;
  termsInfo: string;
  /* Valores */
  pricebasic: string = '';
  pricePlanDinamic: string = '';
  valoresWay2Pay = [];
  modalidad;
  plan;
  payType;
  myControl = new FormControl();
  detailNoAll: boolean = false;
  selectPlanId: string = '';
  listDeta = [];
  listDetatitle: string = '';
  listDetaDes: string = '';
  //isFilter: boolean;
  /*modalidadList: Array<any> = [
    { name: 'Portabilidad', value: 'portabilidad' },
    { name: 'Línea Nueva', value: 'linea_nueva' },
    { name: 'Renovación', value: 'renovacion' }
  ];*/

  /*payTypeList: Array<any> = [
    { name: 'Al contado', value: '8266a6a5-6b03-47d3-ac86-05440d1b1d37' },
    { name: '6 cuotas', value: '632e70ad-93bf-410b-ab64-251c3bb136d7' },
    { name: '12 cuotas', value: '57426070-4107-486b-8c8c-295d8ef0aa8e' },
  ]*/

  payTypeList: Array<any> = [];
  modalidadList: Array<any> = [];
  planList: Array<any> = [];

  memoryList: Array<any> = [
    {name: '32 GB', selected: false},
    {name: '64 GB', selected: false},
    {name: '128 GB', selected: false},
    {name: '256 GB', selected: false},
  ];

  phonesItem: Array<any> = [];


  tecnologia: string;
  procesador: string;
  memoriaInterna: string;
  memoriaRAM: string;
  bateria: string;
  camaraTracera: string;
  camaraFrontal: string;
  pantalla: string;

  public config: SwiperConfigInterface = {
    a11y: {enabled: true},
    direction: 'vertical',
    slidesPerView: 4,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.downBtn',
      prevEl: '.upBtn',
    },
    pagination: false
  };

  modalidadValue: any = '';
  payTypeValue: any = '';
  planValue: any = '';
  valorModalidad: string;
  valorPayType: string;
  valorPlan: string;
  valuePagoDinamic = '';
  valuePagoDinamicId;
  valuePlanDinamic = (0).toFixed(2);
  valuePricePagoDinamic: any = '';
  valueTotalDinamic: any = '';

  idPhone;
  phone;

  phoneData: Array<any> = [];

  // selectTest: FormControl;

  categoryClick= {
    catDettEquipo: 'detalle equipo',
  }; 
  actionClick= 'click';
  labelClick= {
    lblImagenEquipo: 'foto',
    lblColorGris: 'color - gris',
    lblColorAzul: 'color - azul',
    lblModPortabilidad: 'modalidad - portabilidad',
    lblFormPagoAlContado: 'forma de pago - al contado',
    lblVerDetDelPlan: 'ver detalle del plan',
  }; 

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) document: any,
    private obs: ObsService,
    private activeRoute: ActivatedRoute,
    private service: ServiceService,
    private apiService: ApiService,
    private storageService: StorageService,
    private router: Router,
    private readonly datalayer: DatalayerService,
    private dataLayerGa4: DatalayerGA4Service,
    private readonly coreService: CoreService,
    private svcClickDataLayer: DatalayerClickGa4Service,
    private location: Location,
  ) {

    let meta = {
      title: 'Catálogo Movistar Empresas',
      descrption: 'Description del detalle catalogo',
      ogTitle: 'Description del detalle catalogo para redes',
      ogImage: './assets/images/m-empresas-blanco.png'
    };
    this.obs.setValue(this.obs.metaData, meta);

    if (isPlatformBrowser(this.platformId)) {
      if (document.location.href != undefined) {
        let length = document.location.href.split('/').length;
        let path = document.location.href.split('/')[length - 1];
        path = decodeURIComponent(path);
        this.service.getPhone().subscribe(resp => {          
          this.phoneData = resp.filter(data => {
            let modelo = data.model.toLowerCase().replace(/ /g, '-');            
            if (path == modelo) {
              return data;
            }
          });
        
          if (this.phoneData.length != 0) {
            this.service.getPhonePrduct(this.phoneData[0].id).subscribe(res => {
              // console.log('getPhonePrduct', res);
              this.phoneBrand = res.brand.name;
              this.phoneModel = res.comercialName;
              if (res.images.length > 0) {
                this.phonesItem = res.images;
                this.selectImage = this.phonesItem[0].url;
              }
              this.valoresWay2Pay = res.way2Pays;
              this.pricebasic = res.fullPrice;
              this.pricePlanDinamic = res.fullPrice;
              this.idPhone = res.id;
              this.phone = res;
              this.storageService.setData('itemSelectedPhone', this.idPhone);
              this.valorPriceList(this.modalidadValue, this.payTypeValue, this.planValue, res.way2Pays);

              let arr = [{
                intStorage: this.memoriaInterna,
                ram: this.memoriaRAM,
                frontCamera: this.camaraFrontal,
                postCamera: this.camaraTracera,
                batery: this.bateria,
                id: this.idPhone,
                brand: this.phoneBrand,
                modality: this.valorModalidad
              }];
              this.obs.setValue(this.obs.detailModal, arr);

              this.service.getPhoneInfo(res.id).subscribe(info => {
                const codigo = info && info instanceof Array ? info.find(val => val.featureId === 'DCP') : null;
                const nombre = info && info instanceof Array ? info.find(val => val.featureId === 'DNP') : null;
                const precio = info && info instanceof Array ? info.find(val => val.featureId === 'DPP') : null;

                this.datalayer.datalayerEcommerce({
                  gtmCategory: 'Ecommerce',
                  gtmAction: 'Product Detail',
                  gtmLabel: ValidateHelper.datalayerString(res.name),
                  gtmActionFieldList: 'catalogo',
                  gtmProducts: [
                    {
                      name: nombre ? ValidateHelper.datalayerString(nombre.value) : '',
                      id: codigo ? ValidateHelper.datalayerString(codigo.value) : '',
                      price: precio ? ValidateHelper.datalayerString(precio.value) : '',
                      brand: ValidateHelper.datalayerString(res.brand.name),
                      category: 'equipo',
                      variant: 'negro'
                    }
                  ]
                }, 2);

                // ESTA OK
                this.dataLayerGa4.datalayerEcommerceGa4({
                  gtmCategory: 'Ecommerce',
                  gtmAction: 'view_item',
                  gtmLabel: ValidateHelper.datalayerString(res.name),
                  gtmActionFieldList: 'catalogo', 
                  gtmProducts:{
                    productsItems:[
                      {
                        item_id: res.id ? ValidateHelper.datalayerString(res.id) : '',
                        item_name: nombre ? ValidateHelper.datalayerString(nombre.value) : '',
                        item_list_name: 'catalogo',
                        item_list_id: 'catalogo',
                        price: Number(precio ? precio.value : ''),
                        item_brand: ValidateHelper.datalayerString(res.brand.name),
                        item_category:  this.storageService.getData("valueSelectModal"),
                        item_variant: "unico",
                        currency: "PEN",
                        quantity: 1
                      }
                    ],
                    value: Number(precio ? precio.value : ''),
                    currency: "PEN",
                  }
                }, 2);
              });
            });

            this.service.getPhoneInfo(this.phoneData[0].id).subscribe(data => {
              data.map(item => {
                switch (item.featureId) {
                  case 'TEC':
                    this.tecnologia = item.value;
                    break;
                  case 'PRO':
                    this.procesador = item.value;
                    break;
                  case 'MIG':
                    this.memoriaInterna = parseInt(item.value) + ' GB';
                    break;
                  case 'MRG':
                    this.memoriaRAM = parseInt(item.value) + ' GB';
                    break;
                  case 'BAT':
                    this.bateria = parseInt(item.value) + ' mAh​';
                    break;
                  case 'RCT':
                    this.camaraTracera = item.value;
                    break;
                  case 'RCF':
                    this.camaraFrontal = item.value;
                    break;
                  case 'TDP':
                    this.pantalla = item.value;
                    break;
                  default:
                    break;
                }
              });
              let arr = [{
                intStorage: this.memoriaInterna,
                ram: this.memoriaRAM,
                frontCamera: this.camaraFrontal,
                postCamera: this.camaraTracera,
                batery: this.bateria,
                id: this.idPhone,
                brand: this.phoneBrand,
                modality: this.valorModalidad
              }];
              this.obs.setValue(this.obs.detailModal, arr);
              this.memoryList.map(e => {
                if (e.name == this.memoriaInterna) {
                  e.selected = true;
                }
              });
              // console.log(this.memoryList)
            });
          } else {
            let arr = [];
            this.obs.setValue(this.obs.detailModal, arr);
            this.router.navigate(['/no-encontrada']);
          }
        }, err => {
          this.router.navigate(['/no-encontrada']);
        });
      }
    }
  }

  ngOnInit(): void {
    this.location.subscribe((event) => {
      if (event && event.type === 'popstate') {        
        this.obs.setValue(this.obs.detailModal, []);        
      }
    });
    this.modalidadValue = '';
    this.payTypeValue = '';
    this.planValue = '';
    this.initTypeLine();
    this.initTypeList();
    this.initgetPlan();

    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
    //Mostrar autocomplete en el header
    this.obs.setValue(this.obs.showAutocomplete, true);

    this.service.getTerms().subscribe(resp => {
      this.termsInfo = resp.info;
    });

    /*this.obs.getValue(this.obs.showFilter).subscribe(resp => {
      this.isFilter = resp;
    });*/

    //this.selectDefault();

    // Servicio Autocomplete
    this.service.getAutocomplete().subscribe(resp => {
      this.obs.setValue(this.obs.autocompleteList, resp);
    });

    // Servicio Marcas
    this.service.getMarcas().subscribe(resp => {
      this.obs.setValue(this.obs.brandList, resp);
    });
  }

  ngAfterViewInit() {
  }

  DataLayerClick(opc: number) {
    if(opc==1){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblImagenEquipo,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },34)
    }else if(opc==2){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblColorAzul,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },41)
    }else if(opc==3){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblColorGris,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },36)
    }else if(opc==4){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: 'modalidad - ' + this.valorModalidad,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },37)
    }else if(opc==5){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: 'forma de pago - ' + this.valorPayType,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },38)
    }else if(opc==6){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: 'plan – ' + this.valorPlan,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },39)
    }else if(opc==7){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catDettEquipo,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblVerDetDelPlan,
        gtmMarcaEquipo: this.phoneBrand,
        gtmProductoNombre: this.phoneModel,
        gtmTipoProducto: this.valorModalidad,
      },40)
    }
  }

  showSlides(n) {
    this.selectImage = n;
  }

  onSwiperThumbs(e) {
    this.thumbSwip = e;
  }

  desfaultRamValue(value) {//{ name: '32GB', selected: false },
    let valorRam = parseInt(value);
    let valorStringRam = valorRam + 'GB';
    this.memoryList.find(i => {
      if (i.name === valorStringRam) {
        i.selected = true;
      }
    });
  }

  async initTypeList() {
    this.apiService.getServiceJson(GESTION_TYPE.JSON_LIST_PAYTYPES).subscribe(response => {
      this.payTypeList = response;
      this.valorPayType = 'Al contado';
      this.jsonListHtml(this.payTypeList);
    });
  }

  async initTypeLine() {
    this.apiService.getServiceJson(GESTION_TYPE.JSON_LIST_MODALITIES).subscribe(resp => {
      this.modalidadList = resp;
      this.valorModalidad = this.storageService.getData('valueSelectModal') ? this.storageService.getData('valueSelectModal') : 'Portabilidad';
      this.jsonListHtml(this.modalidadList);
    });
  }

  async initgetPlan() {
    this.service.getPlan().subscribe(planes => {
      this.planList = planes.map(item => {
        item.name = item.name.replace('Plan ', 'Plan Mi Movistar Empresas S/ ');
        return item;
      });
      this.selectCombo();
      this.jsonListHtml(this.planList);
    });
  }

  selectCombo() {
    if (this.storageService.getData('planId')) {
      this.planList.filter(e => e.value == this.storageService.getData('planId')).map(plan => {
        this.valorPlan = plan.name;
      });
    } else {
      this.valorPlan = 'Plan Mi Movistar Empresas S/ 39.90';
    }
  }

  /*
    this.searchItem.filter(e => e.active == true).map(item=>{
      modality = item
    });
  */

  jsonListHtml(valores) {
    for (let i = 0; i < valores.length; i++) {
      switch (valores[i].name) {
        case this.valorModalidad:
          this.modalidadValue = valores[i].value;
          this.storageService.setData('modalidadId', this.modalidadValue);
          break;
        case this.valorPayType:
          this.payTypeValue = valores[i].value;
          break;
        case this.valorPlan:
          this.planValue = valores[i].value;
          this.storageService.setData('planId', this.planValue);
          break;
      }
    }
  }

  /*selectDefault() {
    this.valorModalidad = this.storageService.getData('valueSelectModal')?this.storageService.getData('valueSelectModal'):'Portabilidad';
    this.valorPayType = 'Al contado';
    this.valorPlan = 'Plan Mi Movistar Empresas S/ 39.90';
  }*/

  changed(e, id) {
    /*this.valorModalidad = '';
    this.valorPayType = '';
    this.valorPlan = '';*/
    switch (id) {
      case 'modalidad':
        this.modalidadValue = e;
        this.valorModalidad = this.modalidadList.find(val => val.value === e)?.name;
        // ESTA OK
        this.storageService.setData('valueSelectModal', this.valorModalidad);
        this.storageService.setData('modalidadId', this.modalidadValue);
        this.datalayer.datalayerEquiment({
          gtmCategory: 'catalogo b2b – detalle equipo',
          gtmAction: 'click', 
          gtmLabel: 'modalidad - ' + this.valorModalidad,
          gtmBrand: this.phoneBrand,
          gtmType: this.valorModalidad
        });
        const arr = [{
          intStorage: this.memoriaInterna,
          ram: this.memoriaRAM,
          frontCamera: this.camaraFrontal,
          postCamera: this.camaraTracera,
          batery: this.bateria,
          id: this.idPhone,
          brand: this.phoneBrand,
          modality: this.valorModalidad
        }];
        this.obs.setValue(this.obs.detailModal, arr);
        return this.validaciónSelect();
      case 'payType':
        this.payTypeValue = e;
        this.valorPayType = this.payTypeList.find(val => val.value === e)?.name;
        this.datalayer.datalayerEquiment({
          gtmCategory: 'catalogo b2b – detalle equipo',
          gtmAction: 'click',
          gtmLabel: 'forma de pago – ' + this.valorPayType,
          gtmBrand: this.phoneBrand,
          gtmType: this.valorModalidad
        });
        return this.validaciónSelect();
      case 'plan':
        
        this.planValue = e;
        this.valorPlan = this.planList.find(val => val.value === e)?.name;
        this.storageService.setData('planId', this.planValue);
        this.datalayer.datalayerEquiment({
          gtmCategory: 'catalogo b2b – detalle equipo',
          gtmAction: 'click',
          gtmLabel: 'plan – ' + this.valorPlan,
          gtmBrand: this.phoneBrand,
          gtmType: this.valorModalidad
        });
        return this.validaciónSelect();
    }
  }

  validaciónSelect(): void {
    //console.log(this.modalidadValue);
    //console.log(this.payTypeValue);
    //console.log(this.planValue);
    if (this.modalidadValue !== '' && this.payTypeValue !== '' && this.planValue !== '') {
      this.valorPriceList(this.modalidadValue, this.payTypeValue, this.planValue, this.valoresWay2Pay);
    }
  }

  valorPriceList(modalidadValue, payTypeValue, planValue, general): void {
    //this.valuePlanDinamic = (0).toFixed(2);
    this.valuePlanDinamic = this.planList.find(val => val.value === planValue)?.price.toFixed(2);
    for (let i = 0; i < general.length; i++) {
      if (general[i].way2Pay.id === payTypeValue) {
        let valueMmodalities = general[i].modalities;
        for (let j = 0; j < valueMmodalities.length; j++) {
          if (valueMmodalities[j].modality.id === modalidadValue) {
            let valuePrice = valueMmodalities[j].prices;
            this.selectPlanId = '';
            for (let x = 0; x < valuePrice.length; x++) {
              if (valuePrice[x].plan.id === planValue) {
                this.selectPlanId = valuePrice[x].plan.id;
                this.pricePlanDinamic = valuePrice[x].price;
                if (general[i].way2Pay.name !== 'Al contado') {
                  this.detailNoAll = true;
                } else {
                  this.detailNoAll = false;
                }
                /* */
                this.valuePagoDinamic = general[i].way2Pay.name;
                let valuePagoDinamicInt = 1;
                if (this.valuePagoDinamic === '12 cuotas') {
                  valuePagoDinamicInt = 12;
                } else if (this.valuePagoDinamic === '6 cuotas') {
                  valuePagoDinamicInt = 6;
                } else {
                  valuePagoDinamicInt = 1;
                }
                this.valuePlanDinamic = valuePrice[x].plan.price.toFixed(2);
                // console.log("PRECIO DEL PLAN ", valuePrice[x].plan.price.toFixed(2))
                this.valuePricePagoDinamic = Math.ceil(parseFloat(this.pricePlanDinamic) / valuePagoDinamicInt);
                this.valuePricePagoDinamic = this.valuePricePagoDinamic.toFixed(2);
                this.valueTotalDinamic = parseFloat(this.valuePricePagoDinamic) + parseFloat(valuePrice[x].plan.price);
                this.valueTotalDinamic = this.valueTotalDinamic.toFixed(2);
                this.valuePagoDinamicId = general[i].way2Pay.id;
                this.storageService.setData('valuePagoId', this.valuePagoDinamicId);
              }
            }
          }

        }
      }
    }
    this.planDetalle(this.selectPlanId);
  }

  planDetalle(valor): void {
    this.listDeta = [];
    this.listDetatitle = '';
    this.listDetaDes = '';
    this.service.getPlanDetail(valor).subscribe(resp => {
      const plan = resp instanceof Array ? resp.find(val => val.id === this.storageService.getData('planId')) : resp;
      if (plan) {
        for (let i = 0; i < plan.detail.length; i++) {
          if (i === 0) {
            this.listDetatitle = plan.detail[i].title;
            this.listDetaDes = plan.detail[i].description;
          } else {
            this.listDeta.push(plan.detail[i]);
          }
        }
      }
    });
  }

  openTermsCond() {
    this.openedTerms = !this.openedTerms;
  }

  openDetail() {
    this.openedDetail = !this.openedDetail;
  }

  openSpecs() {
    this.openedSpecs = !this.openedSpecs;
  }

  selectMemory(item) {
    for (let i = 0; i < this.memoryList.length; i++) {
      if (item.name == this.memoryList[i].name) {
        this.memoryList[i].selected = true;
        this.memoriaInterna = this.memoryList[i].name;
        let arr = [{
          intStorage: this.memoriaInterna,
          ram: this.memoriaRAM,
          frontCamera: this.camaraFrontal,
          postCamera: this.camaraTracera,
          batery: this.bateria,
          id: this.idPhone,
          brand: this.phoneBrand,
          modality: this.valorModalidad
        }];
        this.obs.setValue(this.obs.detailModal, arr);
      } else {
        this.memoryList[i].selected = false;
      }
    }
  }

  public onSwiperEvent(event: string): void {
    //console.log('Swiper event: ', event);
  }

  public onIndexChange(index: number): void {
    //console.log('Swiper index: ', index);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (isPlatformBrowser(this.platformId)) {
      this.obs.setValue(this.obs.detailModal, []);
    }
  }

  quieroEquipo(idPhone, idBanner): void {
    this.storageService.setData('modalidadId', this.modalidadValue);
    this.storageService.setData('valuePagoId', this.valuePagoDinamicId);
    this.storageService.setData('planId', this.selectPlanId);
    this.storageService.setData('itemSelectedPhone', idPhone);
    this.storageService.setData('itemSelectedBanner', idBanner);
    this.coreService.showLoQuiero = true;
    this.obs.setValue(this.obs.showLoQuiero, true);
    this.obs.setValue(this.obs.ecommerce, null);

    this.service.getPhoneInfo(this.idPhone).subscribe(resp => {
      const codigo = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DCP') : null;
      const nombre = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DNP') : null;
      const precio = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DPP') : null;

      this.datalayer.datalayerEcommerce({
        gtmCategory: 'Ecommerce',
        gtmAction: 'Product click',
        gtmLabel: 'Product Detail',
        gtmActionFieldList: 'Product Detail',
        gtmProducts: [
          {
            name: nombre ? ValidateHelper.datalayerString(nombre.value) : '',
            id: codigo ? ValidateHelper.datalayerString(codigo.value) : '',
            price: precio ? ValidateHelper.datalayerString(precio.value) : '',
            brand: ValidateHelper.datalayerString(this.phone.brand.name),
            category: 'equipo',
            variant: 'negro',
            position: `${1}`
          }
        ]
      }, 1);
    });
  }

  numberWithSpaces(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return x;
    }
  }

}
