import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ICONS } from '../iconsList';

@Component({
  selector: 'custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss']
})
export class CustomIconComponent implements OnInit {
  @Input() svgName: any;
  @Input() classIcon?: any;
  @Input() suflix?: boolean;
  @Input() typeStyle?: any;
  

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {

  }


  ngOnInit(): void {
    ICONS.find(e => {
      if (e.name == this.svgName) {
        this.iconRegistry.addSvgIcon(e.name, this.sanitizer.bypassSecurityTrustResourceUrl(e.path));
      }
    })
  }

}
