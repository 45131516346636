<section class="section-comparador">
  <div class="contenedor-general">
    <div class="comparador-content" [class.active-movil-comparador]="dropComparador">
      <div class="comparador-content__left">
        <button class="comparador-content__left-button" (click)="openComparador()"><span
            class="comparador-content__left-button--top">Se agregó un
            equipo al cuadro de comparación</span><span class="comparador-content__left-button--bottom">¡Elige un equipo
            más para empezar a comparar!</span><span class="comparador-content__left-button--flecha"><svg width="20"
              height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M20 9.97495C20 10.262 19.8691 10.5357 19.6443 10.7288C19.189 11.1125 18.4947 11.0857 18.0735 10.6698L9.92633 2.54072L1.93567 10.6644C1.51735 11.083 0.823008 11.1125 0.364855 10.7315C-0.0790708 10.364 -0.124602 9.72813 0.265256 9.3096C0.273793 9.30155 0.279484 9.2935 0.288021 9.28545L9.09539 0.335402C9.30597 0.120773 9.60192 4.48227e-05 9.9121 4.48227e-05C10.2223 -0.00263882 10.5211 0.115408 10.7345 0.327354L19.7041 9.28009C19.8944 9.46687 20.0005 9.71595 20 9.97495Z"
                fill="#019DF4" />
            </svg></span></button>
      </div>
      <div class="comparador-content__right">
        <div class="comparador" *ngFor="let comp of compararItem; let i = index"
          [class.active-comparador]="comp.checked">
          <div class="comparador-body">
            <ng-container *ngIf="comp.checked == true; else elseTemplate">
              <div class="comparador-body__info">
                <div class="comparador-body__info-img"><img [src]="comp.image" [alt]="comp.brand + '_' + comp.model">
                </div>
                <div class="comparador-body__info-body">
                  <h3 class="comparador-body__info-body--titulo">{{comp.brand}}</h3>
                  <p class="comparador-body__info-body--nombre">{{comp.model}}</p>
                </div>
                <button class="comparador-body__cerrar"
                        appDatalayer gtm-category="catalogo b2b – comparador" gtm-label="cerrar"
                        (click)="removeEquipo(comp, i)"><span><svg width="12"
                      height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.82134 6L11.8297 0.991675C12.0568 0.764568 12.0568 0.397439 11.8297 0.170331C11.6026 -0.0567769 11.2354 -0.0567769 11.0083 0.170331L6 5.17865L0.991675 0.170331C0.759445 -0.0516542 0.390608 -0.0414087 0.170331 0.190822C-0.0431163 0.414514 -0.0431163 0.766275 0.170331 0.991675L5.17866 6L0.170331 11.0083C-0.0567769 11.2354 -0.0567769 11.6043 0.170331 11.8297C0.397439 12.0551 0.764568 12.0551 0.991675 11.828L6 6.81964L11.0083 11.828C11.2337 12.0551 11.5991 12.0568 11.8263 11.8314L11.8297 11.828C12.0568 11.6009 12.0568 11.2337 11.8297 11.0083L6.82134 6Z"
                        fill="#86888C" />
                    </svg></span></button>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div *ngIf="isResp == false" class="comparador-body__button desktop">
                <span class="comparador-body__button-img">
                  <img src="../assets/images/cel-vacio.png" alt="">
                </span>
                <span class="comparador-body__button-agregar">Agrega un equipo más</span>
              </div>
              <button *ngIf="isResp == true" class="comparador-body__button movile" (click)="openComparador()">
                <span class="comparador-body__button-img">
                  <img src="../assets/images/cel-vacio.png" alt="">
                </span>
                <span class="comparador-body__button-agregar">Agrega un equipo más</span>
              </button>
            </ng-template>
          </div>
        </div>
        <div class="comparar-content">
          <button mat-stroked-button class="comparar-button" [disabled]="disabledComparar"
                  appDatalayer gtm-category="catalogo b2b – comparador" gtm-label="comparar equipos"
                  (click)="compEquipo()">COMPARAR EQUIPOS</button>
        </div>
      </div>
      <div class="section-comparador-bg"></div>
    </div>
  </div>
</section>
