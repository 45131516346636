import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObsService } from '../service/obs.service';

import { FilterChip } from '../model/filterChip';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss']
})
export class ModalFilterComponent implements OnInit {
  searchItem: Array<any> = [];
  sortItem: Array<any> = [];
  brandItem: Array<any> = [];
  planItem: Array<any> = [];
  filterItem: FilterChip[] = [];
  removable = true;
  value: number = 0;
  highValue: number = 10000;
  options: Options = {
    floor: 0,
    ceil: 10000
  };
  constructor(
    private obs: ObsService
  ) {
    this.obs.getValue(this.obs.brandList).subscribe(resp => this.brandItem = resp);
    this.obs.getValue(this.obs.planList).subscribe(resp => this.planItem = resp);
    this.obs.getValue(this.obs.searchList).subscribe(resp => this.searchItem = resp);
    this.obs.getValue(this.obs.sortList).subscribe(resp => this.sortItem = resp);
  }

  ngOnInit(): void {
    this.brandItem
  }

  close() {
    this.obs.setValue(this.obs.showFilter, false);
  }

  selectBrand(event, item, type) {
    if (event.checked == true) {
      this.filterItem.push({ name: item, type: type });
    }
    else if (event.checked == false) {
      this.remove(item, type);
    }
  }

  sliderEvent(min: number, max: number) {
    this.filterItem.push({ name: 'S/ ' + min + ' - ' + 'S/ ' + max, type: 'range' });
  }

  remove(item, type): void {
    if (type == 'plan') {
      this.planItem.find(e => {
        if (e.name == item) {
          e.active = false;
          for (let i = 0; i <= this.filterItem.length; i++) {
            if (this.filterItem[i].name === item) {
              this.filterItem.splice(i, 1);
            }
          }
        }
      })
    } else if (type == 'brand') {
      this.brandItem.find(e => {
        if (e.name == item) {
          e.active = false;
          for (let i = 0; i <= this.filterItem.length; i++) {
            if (this.filterItem[i].name === item) {
              this.filterItem.splice(i, 1);
            }
          }
        }
      })
    } else if (type == 'range') {
      this.highValue = 10000;
      this.value = 0;
      for (let i = 0; i <= this.filterItem.length; i++) {
        if (this.filterItem[i].name === item) {
          this.filterItem.splice(i, 1);
        }
      }
    }
  }

  clean() {
    this.filterItem = [];
    for (let i = 0; i < this.brandItem.length; i++) {
      this.brandItem[i].active = false;
    }
    for (let j = 0; j < this.planItem.length; j++) {
      this.planItem[j].active = false;
    }
    for (let j = 0; j < this.sortItem.length; j++) {
      this.sortItem[j].active = false;
    }
    for (let j = 0; j < this.searchItem.length; j++) {
      this.searchItem[j].active = false;
    }
    this.obs.setValue(this.obs.reload, true);
  }

  activateClassSearch(item) {
    this.searchItem.map(e => {
      if (e == item) {
        e.active = true;
      } else {
        e.active = false;
      }
    });
  }

  activateClassSort(item) {
    this.sortItem.map(e => {
      if (e == item) {
        e.active = true;
      } else {
        e.active = false;
      }
    });
  }

  apliFilter() {
    let orderActive = this.sortItem.filter(e => e.active == true)
    let brandCheckList = this.brandItem.filter(e => e.active == true);
    let planCheckList = this.planItem.filter(e => e.active == true);
      let arr = {
        brand: brandCheckList,
        plan: planCheckList,
        min: this.value,
        max: this.highValue,
        order: orderActive
      }
      this.obs.setValue(this.obs.filterData, arr);
      this.obs.setValue(this.obs.showFilter, false);
  }

}
