import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {ObsService} from '../service/obs.service';
import {ServiceService} from '../service/service.service';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import { DatalayerClickGa4Service } from '../shared/services/datalayer-click-ga4.service';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-modal-comparador',
  templateUrl: './modal-comparador.component.html',
  styleUrls: ['./modal-comparador.component.scss']
})
export class ModalComparadorComponent implements OnInit {
  isResp: boolean;
  compararItem: Array<any> = [{}, {}, {}];

  disabledComparar: boolean = true;

  dropComparador: boolean = false;

  contador: Array<any> = [];

  isReturn: boolean = false;

  categoryClick= {
    catComparador: 'comparador',
  }; 
  actionClick="click";
  labelClick={
    lblEliminar: 'eliminar',
    lblCompEquipo: 'comparar equipos',
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private obs: ObsService,
    private router: Router,
    private service: ServiceService,
    private svcClickDataLayer: DatalayerClickGa4Service,
    private storageService: StorageService,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.matchMedia('(max-width: 768px),').matches) {
        this.isResp = true;
      } else {
        this.isResp = false;
      }
    }

    this.obs.getValue(this.obs.listComp).subscribe(resp => {
      if (resp.length === 1) {
        this.comparePhones(resp[0]);
      } else if (resp.length > 1) {
        this.comparePhoneExist(resp);
      }

    });

  }

  compEquipo() {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catComparador,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblCompEquipo,
      gtmTipoProducto: this.storageService.getData('valueSelectModal'),
    },28);
    
    this.obs.setValue(this.obs.arrayComparador, this.compararItem);
    this.router.navigate(['/comparar-equipos']);
  }

  openComparador() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.matchMedia('(max-width: 768px)').matches) {
        this.dropComparador = !this.dropComparador;
      } else {
        this.dropComparador = false;
      }
    }
  }

  removeEquipo(item, index) {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catComparador,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblEliminar,
      gtmMarcaEquipo: item.brand,
      gtmTipoProducto: item.modality.name,
    },27);

    this.compararItem[index] = {};
    const cleanElement = this.compararItem.filter(e => Object.entries(e).length != 0);
    item.checked = false;
    this.obs.setValue(this.obs.itemChangeCompare, item);
    if (cleanElement.length <= 1) {
      this.disabledComparar = true;
    } else {
      this.disabledComparar = false;
    }
    if (cleanElement.length == 0) {
      this.obs.setValue(this.obs.showComparador, false);
    }
  }

  comparePhones(item) {
    if (item.checked === true) {
      const newElement = this.compararItem.find(e => Object.entries(e).length === 0);
      const indexElement = this.compararItem.indexOf(newElement);
      this.compararItem[indexElement] = item;
    } else if (item.checked === false) {
      const removeItem = this.compararItem.find(e => e.id === item.id);
      const indexItem = this.compararItem.indexOf(removeItem);
      this.compararItem[indexItem] = {};
    }
    this.contador = this.compararItem.filter(e => e.checked === true);
    if (this.contador.length > 1) {
      this.disabledComparar = false;
    } else {
      this.disabledComparar = true;
    }
    if (this.contador.length === 0) {
      this.obs.setValue(this.obs.showComparador, false);
    } else {
      this.obs.setValue(this.obs.showComparador, true);
    }

  }

  comparePhoneExist(item) {
    this.isReturn = true;
    this.obs.getValue(this.obs.itemChange).subscribe(id => {
      this.compararItem = item.map(i => {
        if (i.id === id) {
          i = {};
        }
        return i;
      });

      const count = this.compararItem.filter(e => Object.entries(e).length !== 0);
      this.obs.setValue(this.obs.itemChecked, count);
      if (count.length <= 1) {
        this.disabledComparar = true;
      } else {
        this.disabledComparar = false;
      }
      if (count.length === 0) {
        this.obs.setValue(this.obs.showComparador, false);
      } else {
        this.obs.setValue(this.obs.showComparador, true);
      }
    });
  }

}
