import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObsService {
  activeHome: BehaviorSubject<any>;
  showFilter: BehaviorSubject<any>;
  showSearch: BehaviorSubject<any>;
  showComparador: BehaviorSubject<any>;
  listComp: BehaviorSubject<any>;
  itemChecked: BehaviorSubject<any>;
  itemChangeCompare: BehaviorSubject<any>;
  itemAfterCompare: BehaviorSubject<any>;
  showLoQuiero: BehaviorSubject<any>;
  showAutocomplete: BehaviorSubject<any>;
  arrayComparador: BehaviorSubject<any>;
  changePhone: BehaviorSubject<any>;
  customSelect: BehaviorSubject<any>;
  openPanel: BehaviorSubject<any>;
  brandList: BehaviorSubject<any>;
  planList: BehaviorSubject<any>;
  searchList: BehaviorSubject<any>;
  sortList: BehaviorSubject<any>;
  autocompleteList: BehaviorSubject<any>;
  headerStyle: BehaviorSubject<any>;
  detailModal: BehaviorSubject<any>;
  itemChange: BehaviorSubject<any>;
  autoFilterHeader: BehaviorSubject<any>;
  reload: BehaviorSubject<any>;
  selectedValue: BehaviorSubject<any>;
  filterData: BehaviorSubject<any>;
  disabledHunter: BehaviorSubject<any>;
  positionHunter: BehaviorSubject<any>;
  metaData: BehaviorSubject<any>;
  openTerms: BehaviorSubject<any>;

  ecommerce: BehaviorSubject<any>;

  constructor() {
    this.activeHome = new BehaviorSubject(false);
    this.showFilter = new BehaviorSubject(false);
    this.showSearch = new BehaviorSubject(false);
    this.showComparador = new BehaviorSubject(false);
    this.listComp = new BehaviorSubject([]);
    this.itemChecked = new BehaviorSubject([]);
    this.itemChangeCompare = new BehaviorSubject([]);
    this.itemAfterCompare = new BehaviorSubject([]);
    this.showLoQuiero = new BehaviorSubject(false);
    this.showAutocomplete = new BehaviorSubject(false);
    this.arrayComparador = new BehaviorSubject([]);
    this.changePhone = new BehaviorSubject(false);
    this.customSelect = new BehaviorSubject([]);
    this.openPanel = new BehaviorSubject(false);
    this.brandList = new BehaviorSubject([]);
    this.planList = new BehaviorSubject([]);
    this.searchList = new BehaviorSubject([]);
    this.sortList = new BehaviorSubject([]);
    this.autocompleteList = new BehaviorSubject([]);
    this.headerStyle = new BehaviorSubject([]);
    this.detailModal = new BehaviorSubject([]);
    this.itemChange = new BehaviorSubject([]);
    this.autoFilterHeader = new BehaviorSubject([]);
    this.reload = new BehaviorSubject(false);
    this.selectedValue = new BehaviorSubject([]);
    this.filterData = new BehaviorSubject([]);
    this.disabledHunter = new BehaviorSubject(false);
    this.positionHunter = new BehaviorSubject(null);
    this.metaData = new BehaviorSubject([]);
    this.openTerms = new BehaviorSubject(false);

    this.ecommerce = new BehaviorSubject(null);
  }

  getValue(variable): Observable<any> {
    return variable.asObservable();
  }

  setValue(variable, value): void {
    variable.next(value);
  }
}
