import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatalayerGA4Service {

  constructor() { }

  datalayerEcommerceGa4(datalayer: DatalayerList, event = 0): void {
    const data: any = {
      event: "hv.ga4.ecommerce",
      eventName: "view_item_list",
      eventCategory: datalayer.gtmCategory,
      eventAction: datalayer.gtmAction,
      eventLabel: datalayer.gtmLabel,
    };
    
    if (event === 0) {
      // listado de productos
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        item_list_name: datalayer.gtmProducts.productsName,
        item_list_id: datalayer.gtmProducts.productsID, 
      };
    } else if (event === 1 || event === 9) {
      // Btn Lo quiero- Btn detalle
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        item_list_name: datalayer.gtmProducts.productsName,
        item_list_id: datalayer.gtmProducts.productsID, 
      }; 
      data.eventName = "select_item";
    } else if (event === 2 || event === 10) {
      // detalle de producto
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value,
        currency: datalayer.gtmProducts.currency, 
      };
      data.eventName = "view_item";
    } else if (event === 3) {
      // Agregar al carrito
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value,
        currency: datalayer.gtmProducts.currency, 
      };
      data.eventName = "add_to_cart";
    } else if (event === 4) { 
      // View Cart
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value,
        currency: datalayer.gtmProducts.currency, 
      };
      data.eventName = "view_cart";
    } else if (event === 5) {
      // Begin Checkout
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value,
        currency: datalayer.gtmProducts.currency,
      };
      data.eventName = "begin_checkout";
    } else if (event === 6) {
      // Modal Btn Lo quiero/detalle
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value, 
        currency: datalayer.gtmProducts.currency,
      };
      data.eventName = "view_item"; 
    }else if (event === 7) {
      // Add payment info
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value,
        currency: datalayer.gtmProducts.currency,
        payment_type: datalayer.gtmProducts.payment_type,
      };
      data.eventName = "add_payment_info";
    }else if (event === 8) {
      // purchase
      data.ecommerce = {
        items: datalayer.gtmProducts.productsItems,
        value: datalayer.gtmProducts.value,
        currency: datalayer.gtmProducts.currency,
        shipping: datalayer.gtmProducts.shipping,
        transaction_id: datalayer.gtmProducts.transaction_id,
      };
      data.eventName = "purchase";
    }


    console.log("data GA4-->", data, "event -->", event);
    (window as any).dataLayer.push(data);
  }
}

export interface DatalayerBasic {
  gtmCategory: string;
  gtmAction: string;
  gtmLabel: string;
  gtmAuto?: boolean;
  gtmDataId?: string;
}

export interface DatalayerList extends DatalayerBasic { 
  gtmActionFieldList?: string;
  gtmCheckoutStep?: number;
  gtmPurchaseId?: string;
  gtmPurchaseRevenue?: string;
  gtmProducts?: ProductsItems;
}
export interface ProductsItems{
  productsItems?: Array<itemsProducts>;
  productsName?: string;
  productsID?: string;
  value?: number;
  currency?: string;
  payment_type?: string;
  shipping?: string;
  transaction_id?: string;
}
export interface itemsProducts{
  currency?: string;
  item_list_name?: string;
  item_list_id?: string;
  item_name?: string;
  item_id?: string;
  price: number;
  item_brand?: string;
  item_category?: string;
  item_variant?: string;
  quantity?: number;
  index?: number;
}
