import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROUTE_BASE_API, ROUTE_PATH_API } from '../utilities/globalConstants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private api: ApiService, private http: HttpClient) { }
  getAutocomplete(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.autocomplete)
  }

  getBanner(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.banner)
  }

  getDestacados(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.destacados)
      .pipe(
        map(e => {
          e.map(item => {
            item.plan = item.plan.replace('Plan ', 'Plan Mi Movistar Empresas S/ ');
            item['checked'] = false;
            item['disabled'] = false;
            item['position'] = e.indexOf(item);
          })
          return e;
        })
      )
  }

  // getHorario(): Observable<any> {
  //   return this.api.getService(ROUTE_BASE_API.base, ROUTE_PATH_API.horario)
  // }

  getMarcas(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.marcas)
      .pipe(
        map(e => {
          let list = e.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
          return list;
        })
      )
  }

  /*getPlan(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.plan)
      .pipe(
        map(e => {
          e.map(item => {            
            item['label'] = item.name.replace('Plan ', '');
          })
          return e;
        })
      )
  }*/

  getPlan(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.plan)
    .pipe(
      map((elements: any[]) => {
        return elements
          .filter(item => item.price !== 55.9 && item.price !== 74.9)
          .map(item => {
            item.label = item.name.replace('Plan ', '');
            return item;
          });
      })
    );
  }

  getPhone(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.phone)
      .pipe(
        map(e => {
          e.map(item => {
            item.plan = item.plan.replace('Plan ', 'Plan Mi Movistar Empresas S/ ');
            item['checked'] = false;
            item['disabled'] = false;
            item['position'] = e.indexOf(item);
          })
          return e;
        })
      )
  }

  getPhonePrduct(id) {
    return this.api.getServiceById(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.product, id)
  }

  getFilterModalidad(id,planValues,brandValues) {
    return this.api.getServiceById(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.phone, "?modality=" + id+"&plan="+planValues+"&brand="+brandValues)
    //return this.api.getServiceById(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.phone, "?modality=" + id)
    .pipe(
      map(e => {
        e.map(item => {
          item.plan = item.plan.replace('Plan ', 'Plan Mi Movistar Empresas S/ ');
          item['checked'] = false;
          item['disabled'] = false;
          item['position'] = e.indexOf(item);
        })
        return e;
      })
    )
  }

  getPhoneInfo(id): Observable<any> {
    return this.api.getServiceById(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.phone, id + '/features');
  }

  getTipoLinea(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.tipoLinea)
  }

  getTerms(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.terminos)
      .pipe(
        map(text => {
          return text;
        })
      )
  }

  getHunter(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.hunter);
  }
  getLogo(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.typestyle)
  }
  getPlanDetail(id): Observable<any> {
    return this.api.getServiceById(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.planDetail, id)
  }
  sendModal(data): Observable<any> {
    return this.api.sendService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.buyPhone, data)
  }

  getShowSchedule(){
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.enabledButton)
  }
}
