import {Component, ElementRef, HostListener, Inject, OnChanges, PLATFORM_ID, ViewChild} from '@angular/core';
import {ObsService} from './service/obs.service';
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Title, Meta} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {ServiceService} from './service/service.service';
import {StorageService} from './service/storage.service';
import {CoreService} from './service/core.service';
import {DatalayerService} from './shared/services/datalayer.service';
import {ValidateHelper} from './utilities/validate.helper';
import { DatalayerGA4Service } from './shared/services/datalayer-ga4.service';
import { DatalayerClickGa4Service } from './shared/services/datalayer-click-ga4.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showPanel: boolean;
  inputPostion: any;
  formWidth: any;
  showIcon: boolean;
  itemListSelect: any;
  activeHunterStyle: boolean;
  bottomPosition: string;
  isDetailPage: boolean;
  detailProduct: any;
  showForm: boolean;
  title = 'catalogo-b2b';
  telfLabel: string;
  telfNumber: string;
  telfLink: string;
  whatsLink: string;
  isOpenHunter: boolean = false;
  id: string;

  @ViewChild('hunter') hunter: ElementRef;

  @HostListener('window:scroll', ['$event']) scrollAutocomplete() {
    this.obs.getValue(this.obs.disabledHunter).subscribe(resp => {
      if (resp == false) {
        this.eventScrollHunter();
      }
    });
  }

  categoryClick= 'flotante';
  actionClick= 'click';
  labelClick= 'whatsapp';
  labelClick2= '0800 10065';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) document: any,
    private obs: ObsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private service: ServiceService,
    private storageService: StorageService,
    private readonly coreService: CoreService,
    private readonly datalayer: DatalayerService,
    private dataLayerGa4: DatalayerGA4Service,
    private svcClickDataLayer: DatalayerClickGa4Service,
  ) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // Servicio Hunter

    this.obs.getValue(this.obs.openTerms).subscribe(resp => {
      if (resp != undefined) {
        this.eventScrollHunter(resp);
      }
    });


    this.service.getHunter().subscribe(resp => {
      if (resp.length != 0) {
        this.telfLabel = resp.tel.text1;
        this.telfNumber = resp.tel.text2;
        this.telfLink = resp.tel.link;
        this.whatsLink = resp.wa.link;
      }
    });

    this.obs.getValue(this.obs.openPanel).subscribe(resp => {
      this.showPanel = resp;
    });

    //Cerrar el modal del formulario
    this.obs.getValue(this.obs.showLoQuiero).subscribe(resp => {
      this.showForm = resp;
    });

    this.obs.getValue(this.obs.metaData).subscribe(data => {
      if (data) {
        this.titleService.setTitle(data.title);
        if (data.descrption) {
          this.metaService.updateTag({name: 'description', content: data.descrption});
        }
        if (data.robots) {
          this.metaService.updateTag({name: 'robots', content: data.robots});
        }
        if (data.ogUrl) {
          this.metaService.updateTag({property: 'og:url', content: data.ogUrl});
        }
        if (data.ogTitle) {
          this.metaService.updateTag({property: 'og:title', content: data.ogTitle});
        }
        if (data.ogDescription) {
          this.metaService.updateTag({property: 'og:description', content: data.ogDescription});
        }
        if (data.ogImage) {
          this.metaService.updateTag({property: 'og:image', content: data.ogImage});
        }
      }
    });    
  }

  onClicFlotanteDataLayer(opc: number) {
    if (opc == 1) {
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick,
      },9)
    }else if(opc == 2){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick2,
      },10)
    }
  }

  ngAfterViewInit() {
    this.obs.getValue(this.obs.detailModal).subscribe(resp => { 
      if (resp.length !== 0) {
        this.detailProduct = resp[0];
        this.bottomPosition = '120px';
        this.id = resp[0].id;                
      }else{
        this.detailProduct = null;
      }
    });

    this.obs.getValue(this.obs.autoFilterHeader).subscribe(resp => {      
      if (resp) {        
        this.detailProduct = null;
      }
    });
  }

  wantIt() {
    this.coreService.showLoQuiero = true;
    this.obs.setValue(this.obs.showLoQuiero, true);
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  openHunter() {
    this.isOpenHunter = !this.isOpenHunter;
  }

  eventScrollHunter(value?) {
    if (isPlatformBrowser(this.platformId)) {
      this.obs.getValue(this.obs.positionHunter).subscribe(r => {
        if (this.hunter != undefined) {
          let bottom = this.hunter.nativeElement.getBoundingClientRect().bottom;
          let stop = document.body.clientHeight + r + 10;
          if (window.matchMedia('(min-width: 768px)').matches && !this.detailProduct) {
            if (value != undefined) {
              if (value == true) {
                this.activeHunterStyle = true;
                this.bottomPosition = (stop - bottom) + 'px';
              } else if (value == false) {
                this.activeHunterStyle = false;
                //this.bottomPosition = '20px';
              }
            } else {
              if (bottom <= stop) {
                this.activeHunterStyle = true;
                this.bottomPosition = (stop - bottom) + 'px';
              } else {
                this.activeHunterStyle = false;
                this.bottomPosition = '20px';
              }
            }
          } else {
            this.activeHunterStyle = false;
          }
        }
      });
    }
  }

  quieroEquipo(idPhone, idBanner): void {
    this.storageService.setData('itemSelectedPhone', idPhone ? idPhone : this.storageService.getData('itemSelectedPhone'));
    this.storageService.setData('itemSelectedBanner', idBanner);
    this.obs.setValue(this.obs.ecommerce, null);
    this.coreService.showLoQuiero = true;
    this.obs.setValue(this.obs.showLoQuiero, true);

    this.service.getPhoneInfo(idPhone).subscribe(resp => {
      const codigo = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DCP') : null;
      const nombre = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DNP') : null;
      const precio = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DPP') : null;

      this.datalayer.datalayerEcommerce({
        gtmCategory: 'Ecommerce',
        gtmAction: 'Product click',
        gtmLabel: 'Product Detail',
        gtmActionFieldList: 'Product Detail',
        gtmProducts: [
          {
            name: nombre ? ValidateHelper.datalayerString(nombre.value) : '',
            id: codigo ? ValidateHelper.datalayerString(codigo.value) : '',
            price: precio ? ValidateHelper.datalayerString(precio.value) : '',
            brand: ValidateHelper.datalayerString(this.detailProduct.brand),
            category: 'equipo',
            variant: 'negro',
            position: `${1}`
          }
        ]
      }, 1);

      // ESTA OK
      this.dataLayerGa4.datalayerEcommerceGa4({
        gtmCategory: 'Ecommerce',
        gtmAction: 'select_item',
        gtmLabel: this.detailProduct.brand+' '+nombre.value,
        gtmActionFieldList: 'Product Detail',
        gtmProducts:{
          productsItems:[
            {
              item_id: this.detailProduct.id ? ValidateHelper.datalayerString(this.detailProduct.id) : '',
              item_name: nombre ? ValidateHelper.datalayerString(nombre.value) : '',
              item_list_id: 'catalogo',
              item_list_name: 'catalogo',
              price: Number(precio ? precio.value : ''),
              item_brand: ValidateHelper.datalayerString(this.detailProduct.brand),
              item_category: ValidateHelper.datalayerString(this.detailProduct.modality),
              item_variant: "unico",
              currency: 'PEN',
              quantity: 1
            }
          ],
          value: Number(precio ? precio.value : ''),
          currency: 'PEN'
        } 
      }, 6);
    });
  }
}
