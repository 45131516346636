<div class="select-general" [ngClass]="{'active': isActive}">
    <button class="button-ordenar" (click)="openSelect()" [class.onlyInput]="activePanel" [ngStyle]="{'padding-left': startIcon != '' ? '42px' : '14px'}">
    <span class="button-ordenar__palabra">
      <span class="button-ordenar__palabra--top">{{inputValue == "" ? holderSelect : inputValue}}</span>
      <span class="button-ordenar__palabra--bottom">{{placeholder == "" ? label : placeholder}}</span>
    </span>
    <span class="button-ordenar__icon" [ngClass]="{'active': isActive, 'rotate': activePanel}">
      <custom-icon [svgName]="'flecha-abajo-gris'" [classIcon]="'arrowSelect'"></custom-icon>
    </span>
    <span class="button-ordenar__label">{{label}}</span>
    <span class="button-ordenar__icon--left" *ngIf="startIcon">
      <custom-icon [svgName]="startIcon" [classIcon]="'customIcon'"></custom-icon>
    </span>
  </button>
    <div class="ordenar-desplegable" [ngClass]="{'active': activePanel}">
        <ul>
            <li *ngFor="let option of options">
                <p class="ordenar-desplegable--opcion" (click)="selectItem(option)">{{option.name}}</p>
            </li>
        </ul>
    </div>
</div>