import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DatalayerClickGa4Service {
  constructor() {}

  datalayerClickGa4(datalayer: DatalayerListOptions, event=0): void {
    const data: any = {
      event: "hv.event",
      eventName: "catalogo_b2b",
      eventCategory: datalayer.gtmCategory,
      eventAction: datalayer.gtmAction,
      eventLabel: datalayer.gtmLabel,
    };
    const eventoclick = event === 0 || event === 1 || event === 2 || event === 4 || event ===5 || event===6 || event===8 || 
    event=== 9|| event===10|| event===11|| event===12|| event===13|| event===14 || event===15|| event===16 || event===17|| 
    event===18 || event===19|| event===21|| event===22|| event==24|| event==25|| event==26|| event==30|| event==31|| event==32||
    event==33||event==36 ;

    if (event === 7||event===23) {
      data.marca_equipo= datalayer.gtmMarcaEquipo;
      data.producto_nombre= datalayer.gtmProductoNombre;
      data.producto_precio= datalayer.gtmProductoPrecio;
      data.tipo_producto= datalayer.gtmTipoProducto;
    }else if(event===20||event===27||event==36||event==41){
      data.marca_equipo= datalayer.gtmMarcaEquipo;
      data.tipo_producto= datalayer.gtmTipoProducto;
    }else if(event===25||event===26){
      data.tipo_producto= datalayer.gtmTipoProducto;
    }else if(event===28||event===29){
      data.tipo_producto= datalayer.gtmTipoProducto;
    }else if(event==37||event==38||event==39||event==40){
      data.marca_equipo= datalayer.gtmMarcaEquipo;
      data.producto_nombre= datalayer.gtmProductoNombre;
      data.tipo_producto= datalayer.gtmTipoProducto;
    }
    console.log("data CLICK", data, "event", event);
    (window as any).dataLayer.push(data);
  }
}

export interface DatalayerBasic {
  gtmCategory: string;
  gtmAction: string;
  gtmLabel: string;
}

export interface DatalayerListOptions extends DatalayerBasic {
  gtmMarcaEquipo?: string;
  gtmProductoNombre?: string;
  gtmProductoPrecio?: string;
  gtmTipoProducto?: string;
}
