<app-header [isShowFilter]="false"></app-header>
<section class="section-header-paginacion">
  <div class="section-header-paginacion-contenedor"><a class="section-header-paginacion__enlace"
      href="#">Inicio</a><span class="section-header-paginacion_-separador"><svg width="7" height="12"
        viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.628989 11.9995C0.452839 11.9995 0.284921 11.921 0.166391 11.7861C-0.0690243 11.5129 -0.0525617 11.0963 0.202608 10.8436L5.19077 5.95531L0.205901 1.16091C-0.0509155 0.909924 -0.0690244 0.493317 0.164744 0.218425C0.390281 -0.0479308 0.780444 -0.0752492 1.03726 0.158665C1.0422 0.163788 1.04714 0.167202 1.05208 0.172325L6.54399 5.45674C6.67569 5.58309 6.74977 5.76066 6.74977 5.94677C6.75142 6.13288 6.67898 6.31216 6.54893 6.44021L1.05537 11.822C0.940754 11.9361 0.787917 11.9998 0.628989 11.9995Z"
          fill="#86888C" />return
      </svg></span><a class="section-header-paginacion__enlace active">Compara tu equipo</a></div>
</section>
<section class="section-buscador section-buscador-pagina-comparador">
  <div class="contenedor-general">
    <div class="buscador-content">
      <h1 class="buscador--titulo page-title">Compara tu equipo</h1>
      <p class="buscador--descripcion">Elige el equipo que más se acomode a tus necesidades</p>
    </div>
  </div>
</section>
<section class="section-comparar-equipos">
  <div class="contenedor-general">
    <div class="section-layout-comparador">
      <div class="equipos-seleccionados-content">
        <div class="equipos-seleccionados">
          <div class="equipo-seleccion" *ngFor="let select of selectItems, let i = index">
            <ng-container *ngIf="select.checked == true; else elseTemplate">
              <div class="equipo-seleccion__info">
                <button mat-stroked-button class="equipo-seleccion__button-cambiar"
                        appDatalayer gtm-category="catalogo b2b – comparador" gtm-label="cambiar equipo"
                        (click)="return(select.id)">CAMBIAR EQUIPO</button>
                <div class="equipo-seleccion__img"><img [src]="select.image" [alt]="select.brand + ' ' + select.model">
                </div>
                <h2 class="equipo-seleccion__nombre"> <span
                    class="equipo-seleccion__nombre-top">{{select.brand}}</span><span
                    class="equipo-seleccion__nombre-bottom">{{select.model}}</span></h2>
                <p class="equipo-seleccion__plan"><span class="equipo-seleccion__plan-top">{{select.plan}}
                    Empresas </span><span class="equipo-seleccion__plan-bottom">{{select.priceEnterprice}}</span></p>
                <p class="equipo-seleccion__precio"><span class="equipo-seleccion__precio-top">Precio al
                    contado</span><span class="equipo-seleccion__precio-bottom">S/ {{numberWithSpaces(select.priceCount)}}</span></p>
                <button class="equipo-seleccion__button-quiero"
                        appDatalayer gtm-category="catalogo b2b – comparador" gtm-label="lo quiero"
                        (click)="quieroEquipo(select.id,select.modality.value,select.planInfo.value, select.id)">LO QUIERO</button>
              </div>
              <section class="section-terminos-y-condiciones" *ngIf="select.checked == true">
                <div class="contenedor-general">
                  <div class="section-terminos-y-condiciones-content">
                    <div class="acordion-general acordion-terminos-y-condiciones" id="acordion_detalle_planwww"
                      [class.active-acordion]="openedSpecs">
                      <div class="acordion-general__header acordion-terminos-y-condiciones__header"
                        (click)="openSpecs()">
                        <!--
                        <button class="acordion-general__button" id="acordion_detalle_buttonwww"><span>Especificaciones
                            técnicas</span><span><svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0 0.869831C0 0.626233 0.104724 0.394018 0.284576 0.230102C0.648833 -0.0954539 1.20433 -0.0726878 1.54126 0.280188L8.05919 7.17833L14.4519 0.284741C14.7866 -0.0704113 15.3421 -0.095454 15.7086 0.227825C16.0637 0.539722 16.1002 1.07928 15.7883 1.43443C15.7814 1.44126 15.7769 1.44809 15.7701 1.45492L8.72396 9.04971C8.55549 9.23184 8.31873 9.33429 8.07058 9.33429C7.82243 9.33656 7.58338 9.23639 7.41264 9.05654L0.236767 1.45947C0.0845137 1.30097 -0.000356674 1.08961 0 0.869831Z"
                                fill="#019DF4" />
                            </svg></span></button>
                          -->
                      </div>
                      <div class="acordion-general__body acordion-especificaciones-tecnicas__body"
                        id="acordion_detalle_plan_bodywww" *ngIf="openedSpecs">
                        <div class="acordion-general__body-content">
                          <ul class="especificaciones-tecnicas__list">
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Tecnología</span><span
                                  class="especificaciones-tecnicas__item-right">{{select.tecnologia}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Procesador</span><span
                                  class="especificaciones-tecnicas__item-right">{{select.procesador}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Memoria</span><span
                                  class="especificaciones-tecnicas__item-right">{{select.memoriaInterna}}
                                  GB</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Memoria RAM</span><span
                                  class="especificaciones-tecnicas__item-right">{{select.memoriaRAM}} GB</span>
                              </p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Batería</span><span
                                  class="especificaciones-tecnicas__item-right">{{select.bateria}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Cámara</span><span
                                  class="especificaciones-tecnicas__item-right">Principal
                                  {{select.camaraTracera}} / Frontal
                                  {{select.camaraFrontal}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                              <p><span class="especificaciones-tecnicas__item-left">Pantalla</span><span
                                  class="especificaciones-tecnicas__item-right">{{select.pantalla}}</span></p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="equipo-seleccion__descripcion">
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Tecnología</h3>
                  <p>{{select.tecnologia}}</p>
                </div>
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Procesador</h3>
                  <p>{{select.procesador}}</p>
                </div>
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Almacenamiento Interno</h3>
                  <p>{{select.memoriaInterna}} GB</p>
                </div>
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Memoria RAM</h3>
                  <p>{{select.memoriaRAM}} GB</p>
                </div>
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Batería</h3>
                  <p>{{select.bateria}}</p>
                </div>
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Cámara</h3>
                  <p>Principal {{select.camaraTracera}} / Frontal {{select.camaraFrontal}}</p>
                </div>
                <div class="equipo-seleccion__descripcion-body equipo-seleccion__descripcion-tecnologia">
                  <h3 [class.hidden]="i != 0" class="equipos-seleccionados__titulo-h3">Pantalla</h3>
                  <p>{{select.pantalla}}</p>
                </div>
                <div class="equipo-seleccion__descripcion-button-quiero"
                     appDatalayer gtm-category="catalogo b2b – comparador" gtm-label="lo quiero"
                     (click)="quieroEquipo(select.id,select.modality.value,select.planInfo.value, select.id)">LO QUIERO</div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="equipo-seleccion__info">
                <button class="equipo-seleccion__button-cambiar" (click)="return(i)">CAMBIAR EQUIPO</button>
                <div class="equipo-seleccion__img"><img src="../../assets/images/cel-vacio-comparar.png" alt=""></div>
                <div class="equipo-seleccion__vacio-descripcion">
                  <p>Elige un equipo más para comparar</p>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer [showTerms]="'true'"></app-footer>
<app-modal-filter *ngIf="isFilter"></app-modal-filter>
<app-modal-form *ngIf="showForm"></app-modal-form>
