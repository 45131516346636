import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ROUTE_BASE_API, ROUTE_PATH_API } from '../utilities/globalConstants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EquiposNavidadService {

  constructor(
    private api: ApiService
  ) { }

  getBanner(): Observable<any> {
    return this.api.getService(ROUTE_BASE_API.baseCatalogo, ROUTE_PATH_API.banner)
  }
  
  getAllEquiposNavidad():Observable<any> {
    const idBanner: any = 38;
    const modalityId: any = '93393e29-2992-4eee-9d7d-6cd6df107149';
    const way2PayId: any = '8266a6a5-6b03-47d3-ac86-05440d1b1d37';
    
    let data: any= [
      {
        id: 1,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'SAMSUNG',
        name: 'GXY A34',
        name2: 'A24 LTE',
        paymentMethod: 'AL CONTADO',
        price: '1899',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_GXYA34+A24LTE_desktop.webp', 
      },
      {
        id: 2,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'SAMSUNG',
        name: 'GXY S23 FE',
        name2: 'A54 5G',
        paymentMethod: 'AL CONTADO',
        price: '3899',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_GXYS23FE+A545G_desktop.webp', 
      },
      {
        id: 3,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'SAMSUNG',
        name: 'GXY Z Flip5',
        name2: 'A54 5G',
        paymentMethod: 'AL CONTADO',
        price: '5199',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_GXYZFlip5+A545G_desktop.webp', 
      },
      {
        id: 4,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'MOTOROLA',
        name: 'G54',
        name2: 'Edge 40 NEO',
        paymentMethod: 'AL CONTADO',
        price: '1799',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_G54+Edge40NEO_desktop.webp', 
      },
      {
        id: 5,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'MOTOROLA',
        name: 'g14 y g84 5G',
        name2:'',
        paymentMethod: 'AL CONTADO',
        price: '5199',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_g14yg845G_desktop.webp', 
      },
      {
        id: 6,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'XIAOMI',
        name: 'Redmi Note 12S',
        name2: 'Redmi 12C',
        paymentMethod: 'AL CONTADO',
        price: '3899',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_RedmiNote12S+Redmi12C_desktop.webp', 
      },
      {
        id: 7,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'XIAOMI',
        name: 'Redmi Note 12 LTE',
        name2: '12 Pro+',
        paymentMethod: 'AL CONTADO',
        price: '1799',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_RedmiNote12LTEy12Pro+_desktop.webp', 
      },
      {
        id: 8,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'XIAOMI',
        name: 'MAGIC 5 LITE',
        name2: 'X8A',
        paymentMethod: 'AL CONTADO',
        price: '1799',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_MAGIC5LITE+X8A_desktop.webp', 
      },
      {
        id: 9,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'HONOR',
        name: '90 REA',
        name2: '90 5G',
        paymentMethod: 'AL CONTADO',
        price: '3099',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_90REA+905G_desktop.webp', 
      },
      {
        id: 10,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'OPPO',
        name: 'A38',
        name2: 'A78',
        paymentMethod: 'AL CONTADO',
        price: '1399',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_A38+A78_desktop.webp', 
      },
      {
        id: 11,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'OPPO',
        name: 'Reno 10',
        name2: 'A58',
        paymentMethod: 'AL CONTADO',
        price: '1999',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_Reno10+A58_desktop.webp', 
      },
      {
        id: 12,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'APPLE',
        name: 'iPhone 14',
        name2: 'iPhone 11',
        paymentMethod: 'AL CONTADO',
        price: '4699',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_iPhone14+iPhone11_desktop.webp', 
      },
      {
        id: 13,
        idBanner: idBanner,
        modalityId: modalityId,
        way2PayId: way2PayId,
        brand: 'ZTE',
        name: 'Axon 50',
        name2: 'Blade V50 Vita',
        paymentMethod: 'AL CONTADO',
        price: '1099',
        typeCurrency: 'S/',
        planText: 'Plan',
        planValue: '34',
        PlanValueDecimal: '95',
        PlanValueTypeCurrency: 'S/',
        previousPrice: 'S/69.90',
        imageCelphone: '../../assets/images/pack-navidad/desktop/WEBP_NOV_Axon 50+BladeV50Vita_desktop.webp', 
      }
    ];
    return of(data);
  }
}
