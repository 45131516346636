import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { ObsService } from 'src/app/service/obs.service';
import { ServiceService } from 'src/app/service/service.service';
import { DatalayerClickGa4Service } from 'src/app/shared/services/datalayer-click-ga4.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  openedTerms: boolean = false;
  @ViewChild('footer') footer: ElementRef;

  @Input() showTerms: boolean;
  @Input() showNav?: boolean = true;

  termsInfo: string;

  @HostListener('window:scroll', ['$event']) scrollAutocomplete() {
    let height = this.footer.nativeElement.getBoundingClientRect().height;
    this.obs.setValue(this.obs.positionHunter, height)
  }

  categoryClick= {
    catFooter: 'footer',
  }; 
  actionClick= 'click';
  labelClick= {
    lblTyC: 'terminos y condiciones',
    lblMasMovEmpresas: 'mas de movistar empresas',
    lblFija: 'fija',
    lblProdDigitales: 'productos digitales',
  }; 

  constructor(
    private service: ServiceService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private obs: ObsService,
    private svcClickDataLayer: DatalayerClickGa4Service,
  ) {

  }

  ngOnInit(): void {
    this.service.getTerms().subscribe(resp => {
      this.termsInfo = resp.info;
    })
  }

  ngAfterViewInit(): void {
  }

  openTermsCond() {
    this.openedTerms = !this.openedTerms;
    this.obs.setValue(this.obs.openTerms, this.openedTerms)
  }

  DataLayerClick(opcDl: number){
    if(opcDl==1){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFooter,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblTyC,
      },32);
    }else if(opcDl==2){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFooter,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblMasMovEmpresas,
      },33);
    }else if(opcDl==3){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFooter,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblFija,
      },34);
    }else if(opcDl==4){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFooter,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblProdDigitales,
      },35);
    }
  }
}
