<section class="section-modales" *ngIf="mofalForm">
    <div class="modal-bg modal-bg-active modal-formulario" id="modal-filtro">
        <div class="modal">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-body-content">
                        <div class="formulario">
                            <div class="formulario-logo"><img src="../assets/images/logo_celeste_codigo.svg" alt="">
                            </div>
                            <p class="formulario-titulo"><span class="formulario-titulo__top">Adquiere tu
                                    equipo</span><span class="formulario-titulo__bottom">ingresando tus datos</span></p>
                            <div class="formulario-input__ruc" (click)="onClickModalDataLayer(1)">
                                <app-custom-input class="w-100" [typeNumber]="true" [controler]="numberRuc"
                                    [startIcon]="'icono-persona_codigo'" [focusInput]="focusRuc"
                                    [labelRegular]="'Ingresa tu n° de RUC'" [labelFocus]="'RUC'" [showInvalid]="true"
                                    [showValid]="true" [showFeedback]="feedBackRUC" [feedbackMessage]="messageFeeback"
                                    [showCounter]="counterRUC" [maxlength]="'11'" [minlength]="'11'"
                                    [showInvalid]="invalidInput" [showValid]="validInput" (catchValue)="valueInputRUC()"
                                    (blurInput)="returnBlur($event)" appDatalayer gtm-category="catalogo b2b – c2c"
                                    gtm-label="ingresa tu numero de ruc">
                                </app-custom-input>
                            </div>
                            <div class="formulario-input__celular" (click)="onClickModalDataLayer(2)">
                                <app-custom-input class="w-100" [typeNumber]="true" [controler]="numbPhone"
                                    [startIcon]="'icono-celular_codigo'" [focusInput]="focusCelular"
                                    [labelRegular]="'Introduce tu celular'" [labelFocus]="'Celular'" [maxlength]="'9'"
                                    [minlength]="'9'" (catchValue)="valueInputCel()" appDatalayer
                                    gtm-category="catalogo b2b – c2c" gtm-label="introduce tu celular">
                                </app-custom-input>
                            </div>
                            <div class="formulario-input__horario w-100" *ngIf="showSchedule">
                                <app-custom-select (sendValue)='changed($event)' [label]="'Horario de llamada'"
                                    [placeholder]="'Selecciona el horario de llamada'" [holderSelect]="'Selecciona'"
                                    [startIcon]="'icono-reloj_codigo'" [options]="scheduleList"
                                    (sendValue)="valueSchedule()">
                                </app-custom-select>
                            </div>
                            <div class="formulario-autorizacion">
                                <div class="formulario-autorizacion-body">
                                    <mat-checkbox (change)="validTerms($event)" [(ngModel)]="aceptTerms" appDatalayer
                                        gtm-category="catalogo b2b – c2c"
                                        gtm-label="autorizo el tratamiento de mis datos - {{aceptTerms ? 'rechazo' : 'acepto'}}">
                                        Autorizo el
                                        <a (click)="showTerms()" appDatalayer gtm-category="catalogo b2b – c2c"
                                            gtm-label="tratamiento de mis datos">tratamiento de mis datos</a>
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="formulario-bottom">
                                <div class="formulario-bottom__body">
                                    <button mat-flat-button class="formulario-bottom__button" [disabled]="sendDisabled"
                                        [class.active]="!sendDisabled" (click)="buyPhones(1)">ENVIAR</button>
                                </div>
                            </div>
                            <div class="formulario-cerrar">
                                <button class="formulario-cerrar__button" (click)="close(1)" appDatalayer
                                    gtm-category="catalogo b2b – c2c" gtm-label="cerrar">
                                    <span>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L16.9999 17" stroke="#019BF2" stroke-width="2"
                                                stroke-linecap="round" />
                                            <path d="M17 1L1.00014 17" stroke="#019BF2" stroke-width="2"
                                                stroke-linecap="round" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-modales" *ngIf="confirmationForm">
    <div class="modal-bg modal-bg-active modal-formulario" id="modal-filtro">
        <div class="modal modal__confirmacion">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-body-content">
                        <div class="formulario formulario__confirmacion">
                            <div class="formulario__confirmacion-img"><img src="../../assets/images/mensaje-check.png"
                                    alt=""></div>
                            <h2 class="formulario__confirmacion-titulo">¡Gracias!</h2>
                            <p class="formulario__confirmacion-subtitulo">Nos pondremos en contacto contigo</p>
                            <div class="formulario-bottom">
                                <div class="formulario-bottom__body">
                                    <button class="formulario-bottom__button active" appDatalayer [data-auto]="true"
                                        [data-id]="idPhoneValue" gtm-category="catalogo b2b – c2c" gtm-action="vista"
                                        gtm-label="confirmación pedido" (click)="close(2)">ENTENDIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-modales" *ngIf="errorForm">
    <div class="modal-bg modal-bg-active modal-formulario" id="modal-filtro">
        <div class="modal modal__confirmacion">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-body-content">
                        <div class="formulario formulario__confirmacion">
                            <div class="formulario__confirmacion-img"><img src="../../assets/images/mensaje-cross.png"
                                    alt=""></div>
                            <h2 class="formulario__confirmacion-titulo">¡Ups! Algo salió mal</h2>
                            <p class="formulario__confirmacion-subtitulo">En este momento no podemos realizar tu
                                solicitud, por favor inténtalo más tarde.</p>
                            <div class="formulario-bottom">
                                <div class="formulario-bottom__body">
                                    <button class="formulario-bottom__button active"
                                        (click)="close(3)">ENTENDIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-modales none" *ngIf="termsAndCondition">
    <div class="modal-bg modal-bg-active modal-formulario" id="modal-filtro">
        <div class="modal modal__terminos">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-body-content">
                        <div class="formulario formulario__terminos">
                            <div class="modal-header" [class.active]="scrollingDown">
                                <div class="modal-header-top">
                                    <div class="formulario-logo">
                                        <img src="../assets/images/logo_celeste_codigo.svg" alt="">
                                    </div>
                                    <div class="formulario-cerrar">
                                        <button class="formulario-cerrar__button" (click)="closeTerms()"><span><svg
                                                    width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1L16.9999 17" stroke="#019BF2" stroke-width="2"
                                                        stroke-linecap="round" />
                                                    <path d="M17 1L1.00014 17" stroke="#019BF2" stroke-width="2"
                                                        stroke-linecap="round" />
                                                </svg></span>
                                        </button>
                                    </div>
                                </div>
                                <h2 class="formulario__terminos-titulo">Cláusula protección de datos personales -
                                    Usuarios Web</h2>
                            </div>
                            <div class="formulario_terminos-content" #termsAndCond (scroll)="scrollTerminos()">
                                <p>Autoriza a TELEFÓNICA DEL PERU S.A.A., con domicilio en Avenida Arequipa N° 1155,
                                    Santa Beatriz, Cercado de Lima, provincia y departamento de Lima (en adelante TDP),
                                    a utilizar los datos personales que ha registrado, con
                                    la finalidad de recibir información sobre los productos y/o servicios que ha
                                    solicitado mediante este formulario. Cabe indicar que sus datos personales serán
                                    registrados en el Banco de Datos de titularidad de TDP denominado
                                    CLIENTES. Finalmente, se informa que usted podrá ejercer su derecho de revocatoria
                                    y/o cualquier otro derecho previsto en la ley, tales como derechos de acceso,
                                    rectificación, cancelación y oposición (ARCO), presentando
                                    una solicitud escrita en cualquier Centro de Atención al Cliente o mediante el
                                    siguiente correo electrónico:
                                    <span>protecciondedatos@movistar.com.pe.</span> Para más información, revise nuestra
                                    <span>Política de
                                        Privacidad.</span>
                                </p>
                            </div>
                            <div class="formulario-bottom" [class.active]="scrollingTop">
                                <div class="formulario-bottom__body">
                                    <button class="formulario-bottom__button active"
                                        (click)="aceptTyC()">ACEPTO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>