import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { ObsService } from 'src/app/service/obs.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorPageComponent implements OnInit {

  constructor(private obs: ObsService,private router: Router) { 
    let meta = {
      title: 'Error Catalogo B2B',
      descrption: 'Description del error catalogo',
      ogTitle: 'Description del error catalogo para redes',
      ogImage: './assets/images/m-empresas-blanco.png'
    }
    this.obs.setValue(this.obs.metaData, meta)
  }

  ngOnInit(): void {
    this.obs.setValue(this.obs.disabledHunter, true)
  }

  onHome(){
    this.router.navigate(["/"])
  }

}
