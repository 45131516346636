// tslint:disable
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  PLATFORM_ID,
  HostListener,
  OnInit,
  ViewChild,
  Inject
} from '@angular/core';
import {Options} from '@angular-slider/ngx-slider';
import {FormControl} from '@angular/forms';
import {forkJoin, Observable} from 'rxjs';
import {startWith, map, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {FilterChip} from '../model/filterChip';
import {ObsService} from '../service/obs.service';
import {ApiService} from '../service/api.service';
import {Router} from '@angular/router';
import {ServiceService} from '../service/service.service';
import {GESTION_TYPE, JSON_BANNER_URL, JSON_DESTACADO_URL} from '../utilities/globalConstants';
import {
  SwiperConfigInterface,
  SwiperComponent
} from 'ngx-swiper-wrapper';
import {AfterContentChecked} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {StorageService} from '../service/storage.service';
import {DatalayerService} from '../shared/services/datalayer.service';
import {ValidateHelper} from '../utilities/validate.helper';
import {CoreService} from '../service/core.service';
import { DatalayerGA4Service } from '../shared/services/datalayer-ga4.service';
import { DatalayerClickGa4Service } from '../shared/services/datalayer-click-ga4.service';
import { EquiposNavidadService } from '../service/equipos-navidad.service';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentChecked, AfterViewInit {
  @ViewChild('swiperContainer') swiperContainer: any;
  @ViewChild('swiperContainer2') swiperContainer2: any;
  @ViewChild('swiperContainer3') swiperContainer3: any;
  private swiper: any;

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;

  @HostListener('window:scroll', ['$event']) scrollAutocomplete() {
    if (isPlatformBrowser(this.platformId)) {
      let top = this.autocompleteInput.nativeElement.getBoundingClientRect().top;
      if (top <= 30) {
        this.obs.setValue(this.obs.showAutocomplete, true);
      } else {
        this.obs.setValue(this.obs.showAutocomplete, false);
      }
    }
  }

  searchNewItem = {
    name: "Pack Navideño",
    value: "local111-1111-1111-1111-local1111111",
    active: true
  }
  selectedSearchValue: string = '';
  navidadEquipos: Array<any> = [];

  selectedOptionClear = '';
  swiperSlideShow: number;
  swiper_banner;
  swiper_phones;
  openSearch: boolean = false;
  defaultValue = new FormControl('popularidad');
  autocompleteItem: Array<any> = [];
  bannerList: Array<any> = [];
  typeBanner: any;
  brandItem: Array<any> = [];
  phoneItem: Array<any> = [];
  planItem: Array<any> = [];
  searchItem: Array<any> = [];
  tipoOrdenItem: Array<any> = [];
  filterItem: FilterChip[] = [];
  removable = true;
  value: number;
  highValue: number;
  options: Options = {};
  isBrowser: boolean;
  showAll: boolean = false;
  isFilter: boolean;
  showForm: boolean;
  openComparador: boolean = false;
  modalityId: '';


  myControl = new FormControl();
  filterPhones: Observable<any[]>;
  filterBrand: Observable<any[]>;
  payTypeList: Array<any> = [];

  isOpenAutocomplete: boolean = false;

  allCompare: boolean = false;

  p: any;
  itemMax: number = 12;

  isResponsive: boolean;

  noPrecios: boolean = true;
  noDescuento: boolean = false;
  noButtons: boolean = false;

  salientList: Array<any>;

  backCompare: boolean = false;

  /* Valores */
  valueSelectModal = 'Portabilidad';


  sortValue: string;

  public swiperBannerConfig: SwiperConfigInterface = {
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: 0,
    pagination: {el: '.swiper-pagination', clickable: true},
    slidesPerView: 1,
  };

  public swiperPhoneConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 3,
      }
    },
    spaceBetween: 0,
    navigation: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {el: '.swiper-pagination', clickable: true}
  };

  @ViewChild(SwiperComponent) componentRef?: SwiperComponent;

  categoryClick= {
    catBuscador: 'buscador',
    catFiltro: 'filtro',
    catEquipos: 'equipos',
    catFiltoOrden: 'orden',
    catCarrEquipos: 'carrusel equipos',
    filtro: 'filtro',
  }; 
  actionClick= 'click';
  labelClick= {
    lblQueEquipNec: '¿que equipo necesitas?',
    lblLimpiarFiltro: 'limpiar filtros',
    lblMostrarMas: 'mostrar mas',
    lblMostrarMenos: 'mostrar menos',
    lblAplicarFiltro: 'aplicar filtro',
    lblOrdenarPor: 'ordenar por',
    lblComparar: 'comparar',
    lblVerVerDetalle: 'ver detalle',
    lblPaginacion: 'paginacion',
    lblFlechaIzq: 'flecha izquierda',
    lblFlechaDer: 'flecha derecha',
  }; 
  currentPage: number = 1; 
  pageIsAnterior: boolean = false;
  pageIsSiguiente: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private obs: ObsService,
    private router: Router,
    private service: ServiceService,
    private cd: ChangeDetectorRef,
    private apiService: ApiService,
    private storageService: StorageService,
    private readonly datalayer: DatalayerService,
    private dataLayerGa4: DatalayerGA4Service,
    private readonly coreService: CoreService,
    private svcClickDataLayer: DatalayerClickGa4Service,
    private svcEquiposNavidadSvc: EquiposNavidadService,
    // @Inject(EquiposNavidadService) private svcEquiposNavidadSvc: EquiposNavidadService
  ) {
    let meta = {
      title: 'Catálogo Movistar Empresas',
      descrption: 'Description del home catalogo',
      ogTitle: 'Description del home catalogo para redes',
      ogImage: './assets/images/m-empresas-blanco.png'
    };
    this.obs.setValue(this.obs.metaData, meta);

    // Servicio Autocomplete
    this.service.getAutocomplete().subscribe(resp => {
      this.autocompleteItem = resp;
      this.obs.setValue(this.obs.autocompleteList, resp);

    });

    this.isBrowser = isPlatformBrowser(platformId);

    // this.apiService.getServiceJson(GESTION_TYPE.JSON_BANNER).subscribe(response => {
    //   this.bannerList = response;
    //   console.log(this.bannerList)
    // });
    // this.apiService.getServiceJson(GESTION_TYPE.JSON_DESTACADO).subscribe(responseD => {
    //   this.salientList = responseD;
    //   // console.log('responseD: '+ JSON.stringify(responseD));
    // });

    // Servicio Banner
    this.service.getBanner().subscribe(resp => {
      this.bannerList = resp;
      setTimeout(() => {
        this.hoverInSwiper('banner');
        this.hoverOutSwiper('banner');  
      }, 500);
      this.typeBanner = resp[0].type;
    });

    // Servicio Marcas
    this.service.getMarcas().subscribe(resp => {
      this.brandItem = resp;
      this.obs.setValue(this.obs.brandList, this.brandItem);
    });

    // Servicio Planes
    this.service.getPlan().subscribe(resp => {
      this.planItem = resp;
      this.obs.setValue(this.obs.planList, this.planItem);
    });

    // Servicio statico equipos de navidad
    this.svcEquiposNavidadSvc.getAllEquiposNavidad().subscribe(resp => {
      this.navidadEquipos = resp;
    });

    // Servicio Equipos
    this.service.getPhone().subscribe(resp => {
      this.phoneItem = resp;

      this.obs.getValue(this.obs.autoFilterHeader).subscribe(resp1 => {
        if (resp1.length != 0) {
          this.filterAuto(resp1.element, resp1.type, true);
        }
      });
    });

    // Servicio Tipo linea
    this.service.getTipoLinea().subscribe(resp => {
      // resp.unshift(this.searchNewItem); 
      resp = resp.filter(item => item.value != 'd360060b-f117-4007-be5d-1f63cec233fd');
      
      resp = resp.map(item => {
        if (item.value === "93393e29-2992-4eee-9d7d-6cd6df107149") {
          this.selectedSearchValue = item.value;
            return { ...item, active: true };
        }
        return item;
      });    
      //console.log("RESPUESTA MODIFICADA-->", resp);
      this.searchItem = resp;
      this.obs.setValue(this.obs.searchList, resp);
    });

    this.tipoOrdenItem = [{name: 'Popularidad', value: 'popularidad'}, {
      name: 'Menor Precio',
      value: 'menorPrecio'
    }, {name: 'Mayor Precio', value: 'mayorPrecio'}, {name: 'Nombre', value: 'nombre'}];
    this.obs.setValue(this.obs.sortList, this.tipoOrdenItem);

    // Servicio Destacados
    this.service.getDestacados().subscribe(resp => {
      this.salientList = resp;

      const request = this.salientList.map(val => {
        return this.service.getPhoneInfo(val.id);
      });
      forkJoin(request).subscribe(resps => {
        resps.forEach((features, index) => {
          const codigo = features && features instanceof Array ? features.find(val => val.featureId === 'DCP') : null;
          const nombre = features && features instanceof Array ? features.find(val => val.featureId === 'DNP') : null;
          const precio = features && features instanceof Array ? features.find(val => val.featureId === 'DPP') : null;
          this.salientList[index].codigo = codigo ? ValidateHelper.datalayerString(codigo.value) : '';
          this.salientList[index].nombre = nombre ? ValidateHelper.datalayerString(nombre.value) : '';
          this.salientList[index].precio = precio ? ValidateHelper.datalayerString(precio.value) : '';
        })

        this.datalayer.datalayerEcommerce({
          gtmCategory: 'Ecommerce',
          gtmAction: 'Impressions',
          gtmLabel: 'equipo mas buscados',
          gtmCurrencyCode: 'PEN',
          gtmProducts: this.salientList.map((val, index) => {
            return {
              name: ValidateHelper.datalayerString(val.nombre),
              id: ValidateHelper.datalayerString(val.codigo),
              price: ValidateHelper.datalayerString(val.precio),
              brand: ValidateHelper.datalayerString(val.brand),
              category: 'equipo',
              variant: '',
              list: 'equipo mas buscados',
              position: `${index + 1}`
            };
          })
        });
      });
    });

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);

      //Cambia la cantidad de elementos que se muestran en el slider final
      if (window.matchMedia('(min-width: 425px)').matches) {
        this.openSearch = true;
        this.swiperSlideShow = 3;
        this.isResponsive = false;
      } else {
        this.openSearch = false;
        this.swiperSlideShow = 1;
        this.isResponsive = true;
      }
    }

    this.obs.setValue(this.obs.activeHome, true);

    //Muestra el filtro en responsive
    this.obs.getValue(this.obs.showFilter).subscribe(resp => {
      this.isFilter = resp;
    });

    this.openComparador = false;
    //Cierra el modal de comparacion

    this.obs.getValue(this.obs.showComparador).subscribe(resp => {
      this.openComparador = resp;
    });

    //Cerrar el modal del formulario
    this.obs.getValue(this.obs.showLoQuiero).subscribe(resp => {
      this.showForm = resp;
    });

    this.obs.getValue(this.obs.filterData).subscribe(resp => {
      if (resp.length != 0) {
        if (resp.order.length != 0) {
          this.setValueOrder(resp.order[0].value);
        }
        this.apliFilter(resp);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      //Opciones de Rango de precios
      this.value = 0;
      this.highValue = 10000;
      this.options = {
        floor: 0,
        ceil: 10000,
      };
    }

    //Filtro de autocomplete para nombre de equipos
    this.filterPhones = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filterPhone(val || '');
      })
    );

    //Filtro de autocomplete para nombre de marcas
    this.filterBrand = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filterBrand(val || '');
      })
    );

    // this.initBanner();
    // this.initDestacados();
    this.valueDefaultModal();

    this.initTypeList();        
  }

  sendDatalayerTabs(item) {
    if(item.value != "local111-1111-1111-1111-local1111111"){
      const request = this.phoneItem.map(val => {
        return this.service.getPhoneInfo(val.id);
      });
      forkJoin(request).subscribe(resps => {
        resps.forEach((features, index) => {
          const codigo = features && features instanceof Array ? features.find(val => val.featureId === 'DCP') : null;
          const nombre = features && features instanceof Array ? features.find(val => val.featureId === 'DNP') : null;
          const precio = features && features instanceof Array ? features.find(val => val.featureId === 'DPP') : null;
          this.phoneItem[index].codigo = codigo ? ValidateHelper.datalayerString(codigo.value) : '';
          this.phoneItem[index].nombre = nombre ? ValidateHelper.datalayerString(nombre.value) : '';
          this.phoneItem[index].precio = precio ? ValidateHelper.datalayerString(precio.value) : '';
        })
  
        this.datalayer.datalayerEcommerce({
          gtmCategory: 'Ecommerce',
          gtmAction: 'Impressions',
          gtmLabel: 'catalogo',
          gtmCurrencyCode: 'PEN',
          gtmProducts: this.phoneItem.slice(0, this.itemMax).map((val, index) => {
            return {
              name: ValidateHelper.datalayerString(val.nombre),
              id: ValidateHelper.datalayerString(val.codigo),
              price: ValidateHelper.datalayerString(val.precio),
              brand: ValidateHelper.datalayerString(val.brand),
              category: 'equipo',
              variant: 'negro',
              list: 'catalogo',
              position: `${index + 1}`
            };
          })
        });
        // ESTA OK
        this.dataLayerGa4.datalayerEcommerceGa4({
          gtmCategory: "Ecommerce",
          gtmAction: "view_item_list",
          gtmLabel: "catalogo", 
          gtmProducts: {
            productsItems: this.phoneItem.slice(0, this.itemMax).map((val, index) => { 
              return {
                currency: "PEN",
                item_list_name: "catalogo",
                item_list_id: "catalogo",
                item_name: ValidateHelper.datalayerString(val.nombre),
                item_id: ValidateHelper.datalayerString(val.id),
                price: Number(val.precio),
                item_brand: ValidateHelper.datalayerString(val.brand),
                item_category: ValidateHelper.datalayerString(val.modality.name),
                item_variant: "unico",
                quantity:1,
                index: Number(`${index + 1}`),
              };
            }),
            productsName: 'catalogo',
            productsID: 'catalogo'
          },
        });
      });
    }
  }

  DataLayerClick(opc: number) {
    if(opc==1){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFiltoOrden,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblOrdenarPor,
      },18)
    }
  }

  VerificarClickAntSig(event) {
    const isLabelValid=event.target.ariaLabel=="Siguiente page"|| event.target.ariaLabel=="Anterior page";
    if(isLabelValid){
      if(this.pageIsAnterior==true){ 
        this.svcClickDataLayer.datalayerClickGa4({
          gtmCategory: this.categoryClick.catEquipos,
          gtmAction: this.actionClick,
          gtmLabel: `${this.labelClick.lblPaginacion} - Anterior`,
          gtmTipoProducto: this.storageService.getData('valueSelectModal'),
        },25)
      }else if(this.pageIsSiguiente==true){ 
        this.svcClickDataLayer.datalayerClickGa4({
          gtmCategory: this.categoryClick.catEquipos,
          gtmAction: this.actionClick,
          gtmLabel: `${this.labelClick.lblPaginacion} - Siguiente`,
          gtmTipoProducto: this.storageService.getData('valueSelectModal'),
        },26)
      }
    }else{ 
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catEquipos,
        gtmAction: this.actionClick,
        gtmLabel: `${this.labelClick.lblPaginacion} - ${this.currentPage}`,
      },24) 
    }
  }

  pageChanged(event: any) {
    if (event < this.currentPage) {
      this.pageIsAnterior = true;
      this.pageIsSiguiente = false;
    } else if (event > this.currentPage) {
      this.pageIsSiguiente = true;
      this.pageIsAnterior = false; 
    }
    this.currentPage = event; 
  }

  onClickHomeBuscadorDataLayer() {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catBuscador,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblQueEquipNec,
    },12)
  }

  private initTypeList() {
    this.apiService.getServiceJson(GESTION_TYPE.JSON_LIST_PAYTYPES).subscribe(response => {
      this.payTypeList = response;
    });
  }

  swiperEquiposNavidadConfig(){ 
    SwiperCore.use([Pagination]);
    const swiperOptions: SwiperOptions = {
      loop: false,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: false,
      // },
      slidesPerView: 1,
      breakpoints: {
        600: {
          slidesPerView: 2,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    const swiper = new SwiperCore(this.swiperContainer.nativeElement, swiperOptions);
    const swiper2 = new SwiperCore(this.swiperContainer2.nativeElement, swiperOptions);
    const swiper3 = new SwiperCore(this.swiperContainer3.nativeElement, swiperOptions);
  }

  isSwiperVisible(): boolean {
    return (
      this.selectedSearchValue !== 'local111-1111-1111-1111-local1111111'
    );
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    // swiper Equipos de Navidad 
    this.swiperEquiposNavidadConfig();

    this.obs.getValue(this.obs.autoFilterHeader).subscribe(resp => {
      if (resp.length != 0) {
        this.filterAuto(resp.element, resp.type);
      }
    });

    this.obs.getValue(this.obs.reload).subscribe(r => {
      if (r == true) {
        this.service.getPhone().subscribe(resp => {
          this.phoneItem = resp;
          this.sortValue = '';
        });
      }
    });

    this.obs.getValue(this.obs.itemChangeCompare).subscribe(resp => {
      if (resp.length = !0) {
        this.phoneItem.find(e => {
          if (e.id == resp.id) {
            e = resp;
          }
          return this.phoneItem;
        });
      }
    });

    this.obs.getValue(this.obs.itemChangeCompare).subscribe(resp => {
      if (resp.length = !0) {
        this.phoneItem.find(e => {
          if (e.id == resp.id) {
            return e.checked = resp.checked;
          }
        });
      }
    });

    this.cd.detectChanges();
  }

  ngAfterContentChecked() {
    this.obs.getValue(this.obs.itemChecked).subscribe(resp => {
      if (resp.lenght != 0) {
        let phoneDeselect;
        this.obs.getValue(this.obs.itemChange).subscribe(id => phoneDeselect = id);
        for (let i = 0; i < this.phoneItem.length; i++) {
          for (let j = 0; j < resp.length; j++) {
            if (this.phoneItem[i].id == resp[j].id) {
              this.phoneItem[i].checked = true;
            } else if (this.phoneItem[i].id == phoneDeselect) {
              this.phoneItem[i].checked = false;
            }
            this.phoneItem[i].disabled = false;
            this.backCompare = true;
          }
        }
      }
    });

    this.obs.getValue(this.obs.itemAfterCompare).subscribe(resp => {
      if (resp.length != 0) {
        for (let i = 0; i < this.phoneItem.length; i++) {
          if (this.phoneItem[i].id == resp.id) {
            this.phoneItem[i].checked = false;
          }
        }
      }
    });
  }

  onSlideChange(i) {
    this.bannerList.find(item => {
      let index = this.bannerList.indexOf(item);
      if (index == i) {
        this.typeBanner = item.type;
      }
    });
  }

  ngOnChanges(): void {
    // Mostrar autocomplete con el scroll
    if (isPlatformBrowser(this.platformId)) {
      let top = this.autocompleteInput.nativeElement.getBoundingClientRect().top;
      if (top <= 30) {
        this.obs.setValue(this.obs.showAutocomplete, true);
      } else {
        this.obs.setValue(this.obs.showAutocomplete, false);
      }
    }
  }

  onTest() {
  }

  onSlidePrev() {
    if (!this.swiperPhoneConfig.autoplay) {
      this.datalayer.datalayerBasic({
        gtmCategory: 'catalogo b2b – carrusel equipos',
        gtmAction: 'click',
        gtmLabel: 'flecha izquierda'
      });
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catCarrEquipos,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblFlechaIzq,
      },30);
    }
  }

  onSlideNext() {
    if (!this.swiperPhoneConfig.autoplay) {
      this.datalayer.datalayerBasic({
        gtmCategory: 'catalogo b2b – carrusel equipos',
        gtmAction: 'click',
        gtmLabel: 'flecha derecha'
      });
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catCarrEquipos,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblFlechaDer,
      },31);
    }
  }

  //Funcion para detener el swiper caundo se hace hover
  hoverInSwiper(element) {
    if (element == 'banner') {
      this.swiperBannerConfig.autoplay = false;
    } else if (element == 'phone') {
      this.swiperPhoneConfig.autoplay = false;
    }
  }

  hoverOutSwiper(element) {
    if (element == 'banner') {
      this.swiperBannerConfig.autoplay = true;
    } else if (element == 'phone') {
      this.swiperPhoneConfig.autoplay = true;
    }
  }


  //Funcion que filtrar el nombre de equipos segun el valor del input
  _filterPhone(val: string): Observable<any[]> {
    return this.service.getAutocomplete()
      .pipe(
        map(response => response.filter(option => {
          return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
        }))
      );
  }

  //Funcion que filtrar el nombre de marcas segun el valor del input
  _filterBrand(val: string): Observable<any[]> {
    return this.service.getMarcas()
      .pipe(
        map(response => response.filter(option => {
          return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
        }))
      );
  }

  //Funciones cuando se abre y cierra el input de autocompletado
  openPanel() {
    this.isOpenAutocomplete = true;
  }

  closePanel() {
    this.isOpenAutocomplete = false;
  }

  //Funcion para agregar chips segun los checkbox seleccionados del filtro
  selectCheckbox(event, item) {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catFiltro,
      gtmAction: this.actionClick,
      gtmLabel: `marca - ${item.name}`,
    },15)

    if (event.checked == true) {
      this.filterItem.push({name: item.name, type: 'brand'});
    } else if (event.checked == false) {
      this.remove(item.name, 'brand');
    }
  }

  selectCheckboxPlan(event, item) {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catFiltro,
      gtmAction: this.actionClick,
      gtmLabel: `plan - s/${item.price}`,
    },16)

    if (event.checked == true) {
      this.filterItem.push({name: item.name, type: 'plan'});
    } else if (event.checked == false) {
      this.remove(item.name, 'plan');
    }
  }

  //Funcion para quitar chips y deseleccionar sus respectivos checkbox del filtro
  remove(item, type): void {
    if (type == 'plan') {
      this.planItem.find(e => {
        if (e.name == item) {
          e.active = false;
          for (let i = 0; i <= this.filterItem.length; i++) {
            if (this.filterItem[i].name === item) {
              this.filterItem.splice(i, 1);
            }
          }
        }
      });
    } else if (type == 'brand') {
      this.brandItem.find(e => {
        if (e.name == item) {
          e.active = false;
          for (let i = 0; i <= this.filterItem.length; i++) {
            if (this.filterItem[i].name === item) {
              this.filterItem.splice(i, 1);
            }
          }
        }
      });
    } else if (type == 'range') {
      this.highValue = 10000;
      this.value = 0;
      for (let i = 0; i <= this.filterItem.length; i++) {
        if (this.filterItem[i].name === item) {
          this.filterItem.splice(i, 1);
        }
      }
    }
  }

  filterAuto(element, type, header = false) {
    if (type == 'brand') {
      let brand = this.phoneItem.filter(item => item.brand == element);
      this.phoneItem = brand;
    } else if (type == 'phones') {

      let phones = this.phoneItem.filter(item => {
        if (element.includes(item.model)) {
          return item;
        }
      });
      this.phoneItem = phones;
    }
    if (this.phoneItem.length && !header) {
      this.datalayer.datalayerView({
        gtmCategory: 'catalogo b2b - buscador',
        gtmAction: 'vista',
        gtmLabel: 'resultado buscador',
        gtmNonInteraction: 1
      });
    }
  }

  //Funcion para limpiar la lista de chips, checkbox del filtro y rango de precio
  clean() {
    this.filterItem = [];
    let brand = this.brandItem.map(e => {
      if (e.active == true) {
        e.active = false;
      }
      return e;
    });
    let plan = this.planItem.map(e => {
      if (e.active == true) {
        e.active = false;
      }
      return e;
    });

    this.brandItem = brand;
    this.planItem = plan;
    this.value = 0;
    this.highValue = 10000;
    this.sortValue = '';

    this.service.getPhone().subscribe(resp => {
      this.phoneItem = resp;
    });

    this.selectedOptionClear = '';

    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catFiltro,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblLimpiarFiltro,
    },14)
  }

  applyFilter() {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catFiltro,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblAplicarFiltro,
    },17)

    let brandArray = [];
    let planArray = [];
    var modality;
    this.brandItem.filter(e => e.active == true).map(item => {
      brandArray.push(item.value);
    });
    let brandValues = brandArray.join(',');

    this.planItem.filter(e => e.active == true).map(item => {
      planArray.push(item.value);
    });
    let planValues = planArray.join(',');

    this.searchItem.filter(e => e.active == true).map(item => {
      modality = item;
    });
    //this.modalityId = modality.value;

    var modalityIdDefault = '';
    if ((modality == undefined || modality == null) && this.searchItem) {
      this.searchItem.filter(e => e.name == 'Portabilidad').map(item => {
        modalityIdDefault = item.value;
      });
    }
    this.service.getFilterModalidad(modality ? modality.value : modalityIdDefault, planValues, brandValues).subscribe(resp => {
      this.phoneItem = resp.filter(item => item.priceCount >= this.value && item.priceCount <= this.highValue);
    });

    if (modality) {
      this.storageService.setData('valueSelectModal', modality.name);
    }
  }

  apliFilter(array?) {
    this.service.getPhone().subscribe(resp => {
      this.phoneItem = resp;
      let brand = [];
      let plan = [];
      let brandArr = [];
      let planArr = [];
      let ambosArr = [];
      let ningunoArr = [];
      if (array != undefined) {
        brand = array.brand;
        plan = array.plan;
        this.value = array.min;
        this.highValue = array.max;
      } else {
        brand = this.brandItem.filter(e => e.active == true);
        plan = this.planItem.filter(e => e.active == true);
      }

      if (brand.length > 0 && plan.length == 0) {
        for (let b = 0; b < brand.length; b++) {
          brandArr = this.phoneItem.filter(item => item.brand == brand[b].name && item.priceCount >= this.value && item.priceCount <= this.highValue);
        }
        this.phoneItem = brandArr;
      } else if (brand.length == 0 && plan.length > 0) {
        for (let p = 0; p < plan.length; p++) {
          const element = plan[p].name.replace('Plan ', 'Plan Mi Movistar Empresas S/ ');
          planArr = this.phoneItem.filter(item => item.plan == element && item.priceCount >= this.value && item.priceCount <= this.highValue);
        }
        this.phoneItem = planArr;
      } else if (brand.length > 0 && plan.length > 0) {
        for (let b = 0; b < brand.length; b++) {
          for (let p = 0; p < plan.length; p++) {
            const element = plan[p].name.replace('Plan ', 'Plan Mi Movistar Empresas S/ ');
            ambosArr = this.phoneItem.filter(item => item.brand == brand[b].name && item.plan == element && item.priceCount >= this.value && item.priceCount <= this.highValue);
          }
        }
        this.phoneItem = ambosArr;
      } else if (brand.length == 0 && plan.length == 0) {
        ningunoArr = this.phoneItem.filter(item => item.priceCount >= this.value && item.priceCount <= this.highValue);
        this.phoneItem = ningunoArr;

      }
    });
  }

  //Crea el chip para el rango de precios
  sliderEvent(min: number, max: number) {
    this.datalayer.datalayerBasic({
      gtmCategory: 'catalogo b2b – filtros',
      gtmAction: 'rango',
      gtmLabel: min + ' - ' + max
    });
    this.filterItem.push({name: 'S/ ' + min + ' - ' + 'S/ ' + max, type: 'range'});
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.filtro,
      gtmAction: this.actionClick,
      gtmLabel: "rango | " + min + " - " + max,
    },41)
  }

  //Funcion del mostrar mas en el filtro
  showMore() {
    this.showAll = !this.showAll;
    if (this.showAll) {
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFiltro,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblMostrarMas,
      },21)
    } else {
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catFiltro,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblMostrarMenos,
      },22)
    }
  }

  //Funcion para solo semodalleccionar una pastilla si es Portabilidad, Línea Nueva o Migracion
  filterModalidad(item) {
    this.selectedSearchValue = item.value;

    this.sendDatalayerTabs(item);

    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catBuscador,
      gtmAction: this.actionClick,
      gtmLabel: item.name,
    },13)

    this.searchItem.map(e => {
      if (e == item) {
        e.active = !e.active;
      } else {
        e.active = false;
      }
    });
    let brandArray = [];
    let planArray = [];
    this.brandItem.filter(e => e.active == true).map(item => {
      brandArray.push(item.value);
    });
    let brandValues = brandArray.join(',');

    this.planItem.filter(e => e.active == true).map(item => {
      planArray.push(item.value);
    });
    let planValues = planArray.join(',');

    this.modalityId = item.value;
    this.service.getFilterModalidad(item.value, planValues, brandValues).subscribe(resp => {
      this.phoneItem = resp;
    });

    /*let filter = this.searchItem.filter(item => item.active == true);
    if (filter.length == 0) {
      this.service.getPhone().subscribe(resp => {
        this.phoneItem = resp;
      });
    }*/
    this.storageService.setData('valueSelectModal', item.name);
  }

  // Abre el modal de comparador de equipos
  modalComparar(e, item) {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catEquipos,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick.lblComparar,
      gtmMarcaEquipo: item.brand,
      gtmTipoProducto: item.modality.name,
    },20)

    for (let i = 0; i < this.phoneItem.length; i++) {
      if (this.phoneItem[i].id == item.id) {
        this.phoneItem[i].checked = e.checked;
      }
    }
    this.obs.setValue(this.obs.listComp, [item]);
    this.openComparador = true;
    let filter = this.phoneItem.filter(e => e.checked == true);
    if (filter.length >= 3) {
      this.phoneItem.map(item => {
        if (item.checked == false) {
          item.disabled = true;
        }
      });
    } else if (filter.length == 0) {
      this.openComparador = false;
    } else {
      this.phoneItem.map(item => item.disabled = false);
    }
    if (e) {
      this.datalayer.datalayerEquiment({
        gtmCategory: 'catalogo b2b – equipos',
        gtmAction: 'click',
        gtmLabel: 'Comparar',
        gtmBrand: item.brand,
        gtmType: item.modality?.name,
        gtmCode: item.codigo,
        gtmName: item.nombre,
        gtmPrice: item.precio
      });
    }
  }


  // Abre el detalle de equipos
  detailEquipo(name, planId?, idPhone?, datalayer?, eventLabel?, phone?) {
    this.storageService.setData('planId', planId);
    let routerName = name.toLowerCase().replace(/ /g, '-');
    this.router.navigate(['/detalle/' + routerName]);

    const product = this.phoneItem.find(val => val.id === idPhone);
    if (datalayer && product) {
      this.datalayer.datalayerEcommerce({
        gtmCategory: 'Ecommerce',
        gtmAction: 'Product click',
        gtmLabel: eventLabel,
        gtmActionFieldList: eventLabel,
        gtmProducts: [
          {
            name: ValidateHelper.datalayerString(product.nombre),
            id: ValidateHelper.datalayerString(product.codigo),
            price: ValidateHelper.datalayerString(product.precio),
            brand: ValidateHelper.datalayerString(product.brand),
            category: 'equipo',
            variant: 'negro',
            position: `${1}`
          }
        ]
      }, 1);

      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick.catEquipos,
        gtmAction: this.actionClick,
        gtmLabel: this.labelClick.lblVerVerDetalle,
        gtmMarcaEquipo: product.brand,
        gtmProductoNombre: product.nombre,
        gtmProductoPrecio: product.precio,
        gtmTipoProducto: product.modality.name,
      },23)

      
      // ESTA OK
      this.dataLayerGa4.datalayerEcommerceGa4({
        gtmCategory: "Ecommerce",
        gtmAction: "select_item",
        gtmLabel: product.model, 
        gtmProducts: {
          productsItems: [{
              item_id: ValidateHelper.datalayerString(product.id),
              item_name: ValidateHelper.datalayerString(product.model),
              item_list_name: "catalogo",
              item_list_id: "catalogo",
              price: Number(product.planInfo.price),
              item_brand: ValidateHelper.datalayerString(product.brand),
              item_category: ValidateHelper.datalayerString(product.modality.name),
              item_variant: "unico",
              currency: "PEN",
              quantity:1,
          }],
          productsName: 'catalogo',
          productsID: 'catalogo'
        },
      },9);
    }
  }

  // Abre el modal con el formulario de pedido
  quieroEquipo(idPhone, planInfo, idBanner, modalityId, datalayer?, eventLabel?) {
    this.modalityId = modalityId;

    if (!this.modalityId && this.searchItem) {
      this.searchItem.filter(e => e.name === 'Portabilidad').map(item => {
        this.modalityId = item.value;
      });
    }

    const payTypeId = this.payTypeList.find(val => val.name === 'Al contado');

    this.storageService.setData('modalidadId', this.modalityId);
    this.storageService.setData('planId', planInfo);
    this.storageService.setData('itemSelectedPhone', idPhone);
    this.storageService.setData('itemSelectedBanner', idBanner);
    this.storageService.setData('valuePagoId', payTypeId ? payTypeId.value : '');
    this.coreService.showLoQuiero = true;
    this.obs.setValue(this.obs.showLoQuiero, true);

    const product = this.phoneItem.find(val => val.id === idPhone);
    if (datalayer && product) {
      this.obs.setValue(this.obs.ecommerce, {phone: product, eventLabel: eventLabel});
      this.datalayer.datalayerEcommerce({
        gtmCategory: 'Ecommerce',
        gtmAction: 'Product click',
        gtmLabel: eventLabel,
        gtmActionFieldList: eventLabel,
        gtmProducts: [
          {
            name: ValidateHelper.datalayerString(product.nombre),
            id: ValidateHelper.datalayerString(product.codigo),
            price: ValidateHelper.datalayerString(product.precio),
            brand: ValidateHelper.datalayerString(product.brand),
            category: 'equipo',
            variant: 'negro',
            position: `${1}`
          }
        ]
      }, 1);

      // ESTA OK
      this.dataLayerGa4.datalayerEcommerceGa4(
        {
          gtmCategory: "Ecommerce",
          gtmAction: "select_item",
          gtmLabel: ValidateHelper.datalayerString(product.model),
          gtmActionFieldList: eventLabel,
          gtmProducts:{
            productsItems:[
              {
                item_id: ValidateHelper.datalayerString(product.id),
                item_name: ValidateHelper.datalayerString(product.model),
                item_list_name: 'catalogo',
                item_list_id: 'catalogo',
                price: Number(product.planInfo.price),
                item_brand: ValidateHelper.datalayerString(product.brand),
                item_category: ValidateHelper.datalayerString(product.modality?.name),
                item_variant: "unico",
                currency: "PEN",
                quantity: 1,

              },
            ],
            productsName: 'catalogo',
            productsID: 'catalogo'
          }
        },
        1
      );
    }
  }

  swiperNoRepeat() {
    if (this.swiper_banner.originalParams.loop && this.swiper_banner.loopedSlides < this.swiper_banner.originalParams.slidesPerView) {
      this.swiper_banner.params.slidesPerView = this.swiper_banner.loopedSlides;
      this.swiper_banner.destroy(false, false);
      this.swiper_banner.init();
    }

    if (this.swiper_phones.originalParams.loop && this.swiper_phones.loopedSlides < this.swiper_phones.originalParams.slidesPerView) {
      this.swiper_phones.params.slidesPerView = this.swiper_phones.loopedSlides;
      this.swiper_phones.destroy(false, false);
      this.swiper_phones.init();
    }
  }

  public onIndexChange(index: number): void {
  }

  public onSwiperEvent(event: string): void {
  }

  setValueOrder(e) {
    let convertE = e.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
    
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick.catFiltoOrden,
      gtmAction: this.actionClick,
      gtmLabel: convertE,
    },19)

    this.phoneItem.sort((a, b) => {
      if (e == 'popularidad') {
        if (a.popularity == b.popularity) {
          return 0;
        }
        if (a.popularity > b.popularity) {
          return -1;
        }
        return 1;
      } else if (e == 'menorPrecio') {
        if (a.priceCount == b.priceCount) {
          return 0;
        }
        if (a.priceCount < b.priceCount) {
          return -1;
        }
        return 1;
      } else if (e == 'mayorPrecio') {
        if (a.priceCount == b.priceCount) {
          return 0;
        }
        if (a.priceCount > b.priceCount) {
          return -1;
        }
        return 1;
      } else if (e == 'nombre') {
        if (a.model == b.model) {
          return 0;
        }
        if (a.model < b.model) {
          return -1;
        }
        return 1;
      }
    });
    this.datalayer.datalayerBasic({
      gtmCategory: 'catalogo b2b – orden',
      gtmAction: 'change',
      gtmLabel: this.tipoOrdenItem.find(val => val.value === e)?.name
    });
  }

  reload(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 8) {
      this.service.getPhone().subscribe(resp => {
        this.phoneItem = resp;
      });
    }
  }

  // initBanner(){
  //   this.apiService.getServiceJson(GESTION_TYPE.JSON_BANNER).subscribe(response => {
  //     this.bannerList = response;
  //   });
  // }
  // initDestacados(){
  //   this.apiService.getServiceJson(GESTION_TYPE.JSON_DESTACADO).subscribe(responseD => {
  //     this.salientList  = responseD;
  //     console.log('responseD: '+ JSON.stringify(responseD));
  //   });
  // }
  valueDefaultModal() {
    this.valueSelectModal = 'Portabilidad';
    this.storageService.setData('valueSelectModal', this.valueSelectModal);
  }

  ngOnDestroy(): void {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.obs.setValue(this.obs.activeHome, false);

  }

  numberWithSpaces(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return x;
    }
  }
}
