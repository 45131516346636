import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ObsService } from 'src/app/service/obs.service';
import { DatalayerClickGa4Service } from 'src/app/shared/services/datalayer-click-ga4.service';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  @Input() startIcon?: string = "";
  @Input() holderSelect?: string = "";
  @Input() placeholder?: string = "";
  @Input() label: string = "";
  @Input() options: Array<any>;
  @Input() setValue?: string = "";
  @Output() sendValue = new EventEmitter();
  isActive: boolean = false;
  activePanel: boolean = false;
  inputValue: string = "";
  constructor(
  ) { }

  ngOnInit(): void {

  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (this.setValue != "") {
      this.options.find(i => {
        if (i.value == this.setValue || i.name == this.setValue) {
          this.isActive = true;
          this.inputValue = i.name;       
        }
      })
    }
    
  }

  openSelect() {
    if (this.inputValue == "") {
      this.isActive = !this.isActive;
    } else {
      this.isActive = true;
    }
    this.activePanel = !this.activePanel;
  }

  selectItem(item) {
    this.activePanel = false;
    this.inputValue = item.name;    
    this.sendValue.emit(item.value);
  }

}