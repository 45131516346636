import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ErrorHandler, Optional, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GESTION_TYPE, JSON_BANNER_URL, JSON_DESTACADO_URL, JSON_LIST_PAYTYPES_URL,JSON_LIST_MODALITIES_URL } from '../utilities/globalConstants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
    @Optional() @Inject('ERROR_WRAPPER') private errorWrapper: any) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }

  // HttpClient API get() method => Fetch employees list
  getService(base, path): Observable<any> {
    return this.http.get<any>(base + '/' + path, this.httpOptions)
      .pipe(
        retry(1)
      )
  }
  //POST
  sendService(base, path, data): Observable<any> {
    return this.http.post<any>(base + '/' + path, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1)
      )
  }

  // HttpClient API get() method => Fetch employee
  getServiceById(base, path, id): Observable<any> {
    return this.http.get<any>(base + '/' + path + '/' + id, this.httpOptions)
      .pipe(
        retry(1)
      )
  }

  getServiceFilter(base, path, filter): Observable<any> {
    return this.http.get<any>(base + '/' + path + '?' + filter, this.httpOptions)
      .pipe(
        retry(1)
      )
  }

  // HttpClient API post() method => Create employee
  createEmployee(base, path, data): Observable<any> {
    return this.http.post<any>(base + '/' + path, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1)
      )
  }

  // HttpClient API put() method => Update employee
  updateEmployee(base, path, id, data): Observable<any> {
    return this.http.put<any>(base + '/' + path + '/' + id, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1)
      )
  }
  

  // HttpClient API delete() method => Delete employee
  deleteEmployee(base, path, id) {
    return this.http.delete<any>(base + '/' + path + '/' + id, this.httpOptions)
      .pipe(
        retry(1)
      )
  }

  getServiceJson(type): Observable<any> {
    var baseUri = '';
    switch (type) {
      case GESTION_TYPE.JSON_BANNER: {
        baseUri = JSON_BANNER_URL.url;        
        break;
      }
      case GESTION_TYPE.JSON_DESTACADO: {
        baseUri = JSON_DESTACADO_URL.url;  
          break;
      }
      case GESTION_TYPE.JSON_LIST_PAYTYPES: {
        baseUri = JSON_LIST_PAYTYPES_URL.url;  
          break;
      }
      case GESTION_TYPE.JSON_LIST_MODALITIES: {
        baseUri = JSON_LIST_MODALITIES_URL.url;  
          break;
      }
      default: {
          break;
      }
    }
    return this.http.get(baseUri);
  }
}
