export const ROUTE_BASE_API = {
    baseCatalogo: 'https://empresas.catalogo.movistar.com.pe/api'
}

export const ROUTE_PATH_API = {
    autocomplete: 'app/autocomplete',
    banner: 'app/banners',
    destacados: 'app/destacados',
    horario: 'horario',
    marcas: 'app/marcas',
    phone: 'app/phone',
    product: 'product',
    plan: 'app/plan',
    tipoLinea: 'app/tipoLinea',
    terminos: 'app/tac',
    tipoOrden: 'tipoOrden',
    hunter: 'app/hunters',
    typestyle:'app/logo' , 
    planDetail: 'plan',  
    buyPhone: 'app/buy-phone',
    enabledButton: '/app/enabled-button'
}
export const GESTION_TYPE = {
    JSON_BANNER: 'JSON_BANNER',
    JSON_DESTACADO: 'JSON_DESTACADO',
    JSON_LIST_PAYTYPES: 'JSON_LIST_PAYTYPES',
    JSON_LIST_MODALITIES: 'JSON_LIST_MODALITIES_URL'
}
export const JSON_BANNER_URL = {
    url: 'https://empresas.catalogo.movistar.com.pe/api/app/banners'
}
export const JSON_DESTACADO_URL = {
    url: 'https://empresas.catalogo.movistar.com.pe/api/app/destacados'
}
export const JSON_LIST_PAYTYPES_URL = {
    url:'https://empresas.catalogo.movistar.com.pe/api/app/payTypes'
}
export const JSON_LIST_MODALITIES_URL = {
    url:'https://empresas.catalogo.movistar.com.pe/api/app/modalities'
}

