import {Component, ElementRef, HostListener, OnInit, ViewChild, PLATFORM_ID, Inject, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ObsService} from '../service/obs.service';
import {MyErrorStateMatcher} from '../utilities/customErrorInput';
import {ServiceService} from '../service/service.service';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {StorageService} from '../service/storage.service';
import {ValidateHelper} from '../utilities/validate.helper';
import {DatalayerService} from '../shared/services/datalayer.service';
import {CoreService} from '../service/core.service';
import { DatalayerGA4Service } from '../shared/services/datalayer-ga4.service';
import { DatalayerClickGa4Service } from '../shared/services/datalayer-click-ga4.service';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss']
})
export class ModalFormComponent implements OnInit {
  mofalForm = true;
  confirmationForm = false;
  errorForm = false;
  termsAndCondition = false;
  scheduleList: Array<any>;
  feedBackRUC = true;
  counterRUC = true;
  messageFeeback: string;
  invalidInput = false;
  validInput = false;
  focusRuc: boolean;
  focusCelular: boolean;
  blurRuc: boolean;

  /* Valores */
  numberRucValue: string = '';
  numberPhoneValue: string = '';
  hoursValue: string = '';
  idBannerValue: string = '';
  idPhoneValue: string = '';
  modalidadId;
  valuePagoId;
  planId;

  @ViewChild('input1', {static: false, read: ElementRef}) input1: ElementRef;
  @ViewChild('termsAndCond') termsAndCond: ElementRef;

  @HostListener('window:scroll', ['$event']) scrollTerminos() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.termsAndCond != undefined) {
        var scrollTop = this.termsAndCond.nativeElement.scrollTop;
        var offsetHeight = this.termsAndCond.nativeElement.offsetHeight;
        var scrollHeight = this.termsAndCond.nativeElement.scrollHeight;
        var calcFinal = (offsetHeight + scrollTop) == scrollHeight;
      }
    }

    if (scrollTop == 0) {
      this.scrollingTop = true;
      this.scrollingDown = false;
    } else {
      this.scrollingTop = true;
      this.scrollingDown = true;
    }

    if (calcFinal == true) {
      this.scrollingTop = false;
      this.scrollingDown = true;
    }
  }

  validStart: boolean;
  validLength: boolean;
  activeErrorClass: boolean;
  sendDisabled: boolean = true;
  aceptTerms: boolean = false;
  validNumbRuc: boolean = false;
  validNumbPhone: boolean = false;
  validSchedule: boolean = false;

  numberRuc = new FormControl('');

  numbPhone = new FormControl('');

  selectHours = new FormControl('');

  matcher = new MyErrorStateMatcher();

  scrollingDown = false;
  scrollingTop = true;

  isOpenAutocomplete = false;

  showSchedule: boolean;

  scheduleSelect: string;
  phone: any = null;

  origenClickCard= 'cards';
  categoryClick= 'click_to_call';
  actionClick= 'click';
  eventAction={
    vista: 'vista',
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private obs: ObsService,
    private service: ServiceService,
    private storageService: StorageService,
    private readonly datalayer: DatalayerService,
    private dataLayerGa4: DatalayerGA4Service,
    private readonly coreService: CoreService,
    private svcClickDataLayer: DatalayerClickGa4Service,
  ) {
    // Servicio Horario
    // this.service.getHorario().subscribe(resp => this.scheduleList = resp);
    this.scheduleList = [{name: 'De 8:00 a.m. a 10:00 a.m.', value: '8:00-10:00'}, {
      name: 'De 10:00 a.m. a 12:00 p.m.',
      value: '10:00-12:00'
    }, {name: 'De 12:00 p.m. a 2:00 p.m.', value: '12:00-2:00'}, {
      name: 'De 2:00 p.m. a 04:00 p.m.',
      value: '2:00-4:00'
    }];
    if (this.mofalForm && this.coreService.showLoQuiero) {
      this.datalayer.datalayerBasic({gtmCategory: 'catalogo b2b – c2c', gtmAction: 'vista', gtmLabel: 'modal'});

      const phoneId = this.storageService.getData('itemSelectedPhone');
      if (phoneId) {
        this.service.getPhonePrduct(phoneId).subscribe(res => {
          this.phone = res;

          this.service.getPhoneInfo(res.id).subscribe(info => {
            const codigo = info && info instanceof Array ? info.find(val => val.featureId === 'DCP') : null;
            const nombre = info && info instanceof Array ? info.find(val => val.featureId === 'DNP') : null;
            const precio = info && info instanceof Array ? info.find(val => val.featureId === 'DPP') : null;
            this.phone.codigo = codigo ? ValidateHelper.datalayerString(codigo.value) : '';
            this.phone.nombre = nombre ? ValidateHelper.datalayerString(nombre.value) : '';
            this.phone.precio = precio ? ValidateHelper.datalayerString(precio.value) : '';

            const data: any = {
              gtmCategory: 'Ecommerce',
              gtmAction: 'Product Add to Cart',
              gtmLabel: ValidateHelper.datalayerString(res.name),
              gtmCurrencyCode: 'PEN',
              gtmProducts: [
                {
                  name: this.phone.nombre,
                  id: this.phone.codigo,
                  price: this.phone.precio,
                  brand: ValidateHelper.datalayerString(res.brand.name),
                  category: 'equipo',
                  variant: 'negro',
                  quantity: 1
                }
              ]
            };
            this.datalayer.datalayerEcommerce(data, 3);

            data.gtmAction = 'Product Checkout';
            data.gtmLabel = 'step 1';
            data.gtmCheckoutStep = 1;

            this.datalayer.datalayerEcommerce(data, 4);
            
            // ESTA OK
            this.dataLayerGa4.datalayerEcommerceGa4(
              {
                gtmCategory: "Ecommerce",
                gtmAction: "view_item",
                gtmLabel: ValidateHelper.datalayerString(res.name), 
                gtmProducts:{
                  productsItems:[
                    {
                      item_id: ValidateHelper.datalayerString(this.phone.id),
                      item_name: ValidateHelper.datalayerString(this.phone.name),
                      item_list_name: 'catalogo',
                      item_list_id: 'catalogo',
                      price: Number(this.phone.precio),
                      item_brand: ValidateHelper.datalayerString(res.brand.name),
                      item_category: this.storageService.getData("valueSelectModal"),
                      item_variant: "unico",
                      currency: "PEN",
                      quantity: 1,
      
                    },
                  ],
                  value: Number(this.phone.precio),
                  currency: "PEN",
                }
              },
              10
            );

             // ESTA OK 
             const dataGa4: any = {
              gtmCategory: "Ecommerce",
              gtmAction: "add_to_cart",
              gtmLabel: ValidateHelper.datalayerString(res.brand.name+' '+res.name), 
              gtmProducts: {
                productsItems: [
                  {
                    item_id: this.phone.id,
                    item_name: this.phone.name,
                    item_list_id: "catalogo",
                    item_list_name: "catalogo",
                    price: Number(this.phone.precio),
                    item_brand: ValidateHelper.datalayerString(res.brand.name),
                    item_category:  this.storageService.getData("valueSelectModal"),
                    item_variant: "unico",
                    currency: "PEN",
                    quantity: 1,
                  },
                ],
                value: Number(this.phone.precio),
                currency: "PEN",
              },
            };
            this.dataLayerGa4.datalayerEcommerceGa4(dataGa4, 3);
 
            dataGa4.gtmAction = "view_cart";
            dataGa4.gtmLabel = ValidateHelper.datalayerString(res.brand.name+' '+res.name), 
            dataGa4.gtmCheckoutStep = 1;

            this.dataLayerGa4.datalayerEcommerceGa4(dataGa4, 4);

            this.vistaDatalayerModal();
          });
        });
      }

      this.obs.getValue(this.obs.ecommerce).subscribe(res => {
        if (res) {
          this.datalayer.datalayerEcommerce({
            gtmCategory: 'Ecommerce',
            gtmAction: 'Product Detail',
            gtmLabel: ValidateHelper.datalayerString(res.phone.nombre),
            gtmActionFieldList: res.eventLabel,
            gtmProducts: [
              {
                name: ValidateHelper.datalayerString(res.phone.nombre),
                id: ValidateHelper.datalayerString(res.phone.codigo),
                price: ValidateHelper.datalayerString(res.phone.precio),
                brand: ValidateHelper.datalayerString(res.phone.brand),
                category: 'equipo',
                variant: 'negro'
              }
            ]
          }, 2);
        }
      });
      this.coreService.showLoQuiero = false;
    }
  }

  ngOnInit(): void {
    this.service.getShowSchedule().subscribe(resp => this.showSchedule = resp.info);
    this.numberRucValue = '';
    this.numberPhoneValue = '';
    this.hoursValue = '';
    this.idBannerValue = '';
    this.idPhoneValue = '';
    this.messageFeeback = 'Ingresa el n° de tu RUC 20 o tu RUC 10';
 
  }

  vistaDatalayerModal(){
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick,
      gtmAction: 'vista',
      gtmLabel: `modal | ${this.origenClickCard}`,
    },0)
  }
  onClickModalDataLayer(inputNumber: number){
    if (inputNumber === 1) {
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel:`ingresa tu numero de ruc | ${this.origenClickCard}`,
      },1)
    } else if (inputNumber === 2) {
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel: `introduce tu numero de celular | ${this.origenClickCard}`,
      },2)
    }
  }

  close(opcClose: number): void {
    this.numberRucValue = '';
    this.numberPhoneValue = '';
    this.hoursValue = '';
    this.idBannerValue = '';
    this.idPhoneValue = '';
    this.storageService.removeData('itemSelectedPhone');
    this.storageService.removeData('itemSelectedBanner');
    this.obs.setValue(this.obs.showLoQuiero, false);

    if(opcClose === 1){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel: `cerrar | ${this.origenClickCard}`,
      },3)
    }
  }

  returnBlur(e): void {
    this.focusRuc = e;
    this.errMatch();
  }

  valueInputRUC(): void {
    this.errMatch();
  }

  valueInputCel(): void {
    if (this.numbPhone.value.length == 9) {
      this.validNumbPhone = true;
    } else {
      this.validNumbPhone = false;
    }
    this.validButton();
  }

  valueSchedule(e): void {
    //console.log('schedule', e)
    this.validSchedule = true;
    this.validButton();
  }

  errMatch(): void {
    let value = this.numberRuc.value.toString();
    let first = value.charAt(0);
    let second = value.charAt(1);
    if (value.length == 1) {
      if (first != 1 && first != 2) {
        this.invalidInput = true;
        this.validNumbRuc = false;
        this.messageFeeback = 'Tu nº de RUC debe iniciar con 20 o 10';
      }
    } else if (value.length >= 2) {
      if (second != '0') {
        this.invalidInput = true;
        this.validNumbRuc = false;
        this.messageFeeback = 'Ingresa el n° de tu RUC 20 o tu RUC 10';
      } else if ((first == 1 || first == 2) && second == 0) {
        this.invalidInput = false;
        this.validNumbRuc = false;
        this.messageFeeback = 'Ingresa el n° de tu RUC 20 o tu RUC 10';
      }
    }
    if (value.length == 0) {
      this.invalidInput = false;
      this.validNumbRuc = false;
      this.messageFeeback = 'Ingresa el n° de tu RUC 20 o tu RUC 10';
    }
    if (value.length < 11 && this.focusRuc == false) {
      this.invalidInput = true;
      this.messageFeeback = 'Recuerda que debes ingresar 11 dígitos';
      this.validNumbRuc = false;
    }
    if (value.length < 11 && this.focusRuc == false && this.validInput == true) {
      this.validInput = false;
      this.invalidInput = true;
      this.messageFeeback = 'Recuerda que debes ingresar 11 dígitos';
      this.validNumbRuc = false;
      this.feedBackRUC = true;
      this.counterRUC = true;
    }
    if (value.length == 11 && this.invalidInput == false) {
      this.validInput = true;
      this.feedBackRUC = false;
      this.counterRUC = false;
      this.validNumbRuc = true;
      this.validButton();
    }
  }

  validButton(): void {
    if (this.showSchedule == true) {
      if (this.validNumbRuc == true && this.validNumbPhone == true && this.aceptTerms == true && this.validSchedule == true) {
        this.sendDisabled = false;
      } else {
        this.sendDisabled = true;
      }
    } else {
      if (this.validNumbRuc == true && this.validNumbPhone == true && this.aceptTerms == true) {
        this.sendDisabled = false;
      } else {
        this.sendDisabled = true;
      }
    }
  }

  validTerms(e): void {
    this.aceptTerms = e.checked;
    //console.log(this.aceptTerms)
    if(this.aceptTerms == true){
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel: `autorizo el tratamiento de datos - acepto | ${this.origenClickCard}`,
      },4)
    }else{
      this.svcClickDataLayer.datalayerClickGa4({
        gtmCategory: this.categoryClick,
        gtmAction: this.actionClick,
        gtmLabel: `autorizo el tratamiento de datos - rechazo | ${this.origenClickCard}`,
      },5)
    }
    this.validButton();
  }

  showTerms(): void {
    this.termsAndCondition = true;
  }

  closeTerms(): void {
    this.termsAndCondition = false;
  }

  aceptTyC(): void {
    this.aceptTerms = true;
    this.termsAndCondition = false;
  }

  buyPhones(item): void {
    if (item === 1) {
      this.mofalForm = false;
      this.errorForm = false;
      this.confirmationForm = true;

      this.numberRucValue = this.numberRuc.value.toString();
      this.numberPhoneValue = this.numbPhone.value.toString();
      this.hoursValue;
      this.idBannerValue = this.storageService.getData('itemSelectedBanner');
      this.idPhoneValue = this.storageService.getData('itemSelectedPhone');
      this.modalidadId = this.storageService.getData('modalidadId');
      this.valuePagoId = this.storageService.getData('valuePagoId');
      this.planId = this.storageService.getData('planId');
      const data = {
        bannerId: ValidateHelper.validString(this.idBannerValue),
        modalityId: ValidateHelper.validString(this.modalidadId),
        callHour: ValidateHelper.validString(this.hoursValue),
        numPhone: ValidateHelper.validString(this.numberPhoneValue),
        numRuc: ValidateHelper.validString(this.numberRucValue),
        phoneId: ValidateHelper.validString(this.idPhoneValue),
        planId: ValidateHelper.validString(this.planId),
        way2PayId: ValidateHelper.validString(this.valuePagoId)
      };

      
      this.service.sendModal(data).subscribe(resp => {
        this.datalayer.datalayerBasic({
          gtmCategory: 'catalogo b2b – c2c',
          gtmAction: 'vista',
          gtmLabel: 'confirmación pedido',
          gtmAuto: true,
          gtmDataId: data.phoneId
        });
        this.datalayer.datalayerEcommerce({
          gtmCategory: 'Ecommerce',
          gtmAction: 'Product Purchase',
          gtmLabel: resp.info.phoneId,
          gtmPurchaseId: resp.info.phoneId,
          gtmPurchaseRevenue: ValidateHelper.datalayerString(this.phone.fullPrice),
          gtmProducts: [
            {
              name: ValidateHelper.datalayerString(this.phone.nombre),
              id: ValidateHelper.datalayerString(this.phone.codigo),
              price: ValidateHelper.datalayerString(this.phone.precio),
              brand: ValidateHelper.datalayerString(this.phone.brand.name),
              category: 'equipo',
              variant: 'negro',
              quantity: 1
            }
          ]
        }, 5);

        // ESTA OK
        const dataToLayer:any={
          gtmCategory: "Ecommerce",
          gtmAction: "begin_checkout",
          gtmLabel: this.phone.brand.name+' - '+this.phone.nombre,
          gtmPurchaseId: resp.info.phoneId,
          gtmPurchaseRevenue: ValidateHelper.datalayerString(
            this.phone.fullPrice
          ),
          gtmProducts: {
            productsItems: [
              {
                item_id: ValidateHelper.datalayerString(this.phone.id),
                item_name: ValidateHelper.datalayerString(this.phone.nombre),
                item_list_name:"catalogo",
                item_list_id:"catalogo",
                price: Number(this.phone.precio),
                item_brand: ValidateHelper.datalayerString(
                  this.phone.brand.name
                ),
                item_category: this.storageService.getData("valueSelectModal"),
                item_variant: "unico",
                currency: "PEN",
                quantity: 1,
              },
            ],
            value: Number(this.phone.precio),
            currency: "PEN",
          },
        };
        // ESTA OK
        this.dataLayerGa4.datalayerEcommerceGa4( dataToLayer, 5 );

        dataToLayer.gtmAction="add_payment_info";
        dataToLayer.gtmProducts.payment_type="c2c"
        this.dataLayerGa4.datalayerEcommerceGa4( dataToLayer, 7 );

        dataToLayer.gtmAction="purchase";
        dataToLayer.gtmProducts.shipping=0;
        dataToLayer.gtmProducts.transaction_id=ValidateHelper.datalayerString(resp.info.id);
        this.dataLayerGa4.datalayerEcommerceGa4( dataToLayer, 8 );

        this.svcClickDataLayer.datalayerClickGa4({
          gtmCategory: this.categoryClick,
          gtmAction: this.actionClick,
          gtmLabel: `enviar | ${this.origenClickCard}`,
        },6)

        this.svcClickDataLayer.datalayerClickGa4({
          gtmCategory: this.categoryClick,
          gtmAction: this.eventAction.vista,
          gtmLabel: `confirmar pedido | ${this.origenClickCard}`,
          gtmMarcaEquipo: this.phone.brand.name,
          gtmProductoNombre: this.phone.nombre,
          gtmProductoPrecio: this.phone.precio,
          gtmTipoProducto: this.storageService.getData('valueSelectModal'),
        },7)

      });
    } else if (item === 2) {
      this.mofalForm = false;
      this.errorForm = true;
      this.confirmationForm = false;
      this.numberRucValue = '';
      this.numberPhoneValue = '';
      this.hoursValue = '';
      this.idBannerValue = '';
      this.idPhoneValue = '';
      this.storageService.removeData('itemSelectedPhone');
      this.storageService.removeData('itemSelectedBanner');
      this.storageService.removeData('modalidadId');
      this.storageService.removeData('valuePagoId');
      this.storageService.removeData('planId');
    } else {
      this.mofalForm = true;
      this.errorForm = false;
      this.confirmationForm = false;
      this.numberRucValue = '';
      this.numberPhoneValue = '';
      this.hoursValue = '';
      this.idBannerValue = '';
      this.idPhoneValue = '';
      this.storageService.removeData('itemSelectedPhone');
      this.storageService.removeData('itemSelectedBanner');
      this.storageService.removeData('modalidadId');
      this.storageService.removeData('valuePagoId');
      this.storageService.removeData('planId');
    }
  }

  changed(e): void {
    this.hoursValue = e;
  }

  //Funciones cuando se abre y cierra el input de autocompletado
  openPanel(): void {
    this.isOpenAutocomplete = true;
  }

  closePanel(): void {
    this.isOpenAutocomplete = false;
  }

}
