<div class="formulario-input">
  <div class="buscador--input"
    [ngClass]="{'active-focus': focusInput, 'active-invalid': showInvalid, 'active-valid': showValid}">
    <label class="buscador--input-container">
      <input class="buscador--input-buscador" [type]="type != undefined ? type : 'text'" (keypress)="getValue($event)"
        (keyup)="counterCharacter($event)" type="text" [(ngModel)]="modelInput" [formControl]="controler"
        (focus)="onFocus()" (focusout)="outFocus()" (blur)="outBlur()" [maxlength]="maxlength" [minlength]="minlength">
      <span *ngIf="startIcon" class="buscador--input--icon-left">
        <custom-icon [svgName]="startIcon" [classIcon]="'iconsClass'"></custom-icon>
      </span>
      <span class="buscador--input-placeholder">
        <span class="buscador--input-placeholder-top" *ngIf="!focusInput">{{labelRegular}}</span>
        <span class="buscador--input-placeholder-bottom" *ngIf="focusInput">{{labelFocus}}</span>
      </span>
      <span class="buscador--input--icon-invalid" *ngIf="showInvalid">
        <custom-icon [svgName]="'icono-invalid_codigo'" [classIcon]="'invalidIcon'"></custom-icon>
      </span>
      <span class="buscador--input--icon-valid" *ngIf="showValid">
        <custom-icon [svgName]="'icono-valid_codigo'" [classIcon]="'validIcon'"></custom-icon>
      </span>
      <span class="buscador--input-feedback">
        <span class="buscador--input-feedback__content">
          <span class="buscador--input-feedback--left" *ngIf="showFeedback">{{feedbackMessage}}</span>
          <div class="marcador" *ngIf="showCounter">
            <span class="marcador-1">{{counter || 0}}</span>
            <span class="marcador-center">/</span>
            <span class="marcador-2">11</span>
          </div>
        </span>
      </span>
    </label>
  </div>
</div>
