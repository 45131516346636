import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class CoreService {
  private _showLoQuiero = false;

  constructor() {
  }

  get showLoQuiero(): boolean {
    return this._showLoQuiero;
  }

  set showLoQuiero(value: boolean) {
    this._showLoQuiero = value;
  }
}
