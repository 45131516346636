import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit {

  @Input() startIcon?: string;
  @Input() labelRegular?: string;
  @Input() labelFocus?: string;
  @Input() showFeedback?: boolean = false;
  @Output() blurInput?= new EventEmitter<any>();
  @Input() feedbackMessage?: string;
  @Input() showCounter?: boolean = false;
  @Input() showInvalid?: boolean = false;
  @Input() showValid?: boolean = false;
  @Input() controler: FormControl;
  @Input() model: string
  @Input() type: string;
  @Input() typeNumber: boolean = false;
  @Output() catchValue?= new EventEmitter<any>();
  @Input() focusInput: boolean = false;
  @Input() maxlength: number;
  @Input() minlength: number;
  counter: number = 0;
  modelInput: string;

  constructor() { }

  ngOnInit(): void {
  }

  onFocus() {
    this.focusInput = true;
  }

  outFocus() {
    if (this.controler.value == undefined) {
      this.focusInput = false;
    }
  }

  outBlur() {
    if (this.controler.value == undefined) {
      this.focusInput = false;
    } else {
      this.blurInput.emit(false);
    }
  }

  counterCharacter(event) {
    if (this.controler.value.length <= 11) {
      this.counter = this.controler.value.length;
    }
    this.catchValue.emit(this.modelInput)
  }

  getValue(event) {
    var charCode = (event.which) ? event.which : event.keyCode
    if (this.typeNumber == true) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      } else {
        return true;
      }
    }

  }

}
