export class ValidateHelper {
  public static validString(value: any, resultNoValid = ''): string {
    if (value && value.toString().trim() && value !== 'null') {
      return value.toString().trim();
    }
    return resultNoValid;
  }

  public static datalayerString(value: any, resultNoValid = ''): string {
    if (value && value.toString().trim() && value !== 'null') {
      return value.toString().toLowerCase().trim()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    return resultNoValid;
  }
}
