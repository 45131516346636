<header #header>
  <div class="header-content" [class.bgTransparent]="topHeader" [style.background-color]="'#0B2739'">
    <div class="contenedor-general">
      <a appDatalayer gtm-category="catalogo b2b - header" gtm-label="logo"
         class="header-content-logo" href="https://empresas.movistar.com.pe/" target="_blank" rel="noopener noreferrer" (click)="onClickHeaderDataLayer()">
        <div class="header-content-logo__img"><img [src]="urlLogoDefault" alt="" /></div>
        <div class="header-content-logo__frase"><span [style.color]="typeStyle"
            [class.hidden]="activeAutocomplete">Empresas</span></div>
      </a>
      <div class="buscador-input-header" *ngIf="showHeaderAutocomplete || activeAutocomplete"
        [class.active]="activeAutocomplete">
        <form class="autocomplete-form">
          <mat-form-field class="example-full-width" appearance="outline" [class.activeInput]="isOpenAutocomplete">
            <mat-label class="autocompleteLabel">¿Qué equipo necesitas?</mat-label>
            <input type="text" aria-label="Number" matInput
                   appDatalayer gtm-category="catalogo b2b – buscador header" gtm-label="¿que equipo necesitas?"
                   [formControl]="myControl" [matAutocomplete]="auto" (keyup)="reload($event)" (click)="onClickBuscadorDataLayer()">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (opened)="openPanel()"
              (closed)="closePanel()" class="autoPanelHeader respAutocomplete" classList="autoPanelHeader">
              <hr *ngIf="isOpenAutocomplete" class="inputSeparator">
              <mat-optgroup label="Marca">
                <mat-option *ngFor="let brand of filterBrand | async" [value]="brand.name"
                            appDatalayer gtm-category="catalogo b2b – buscador header" gtm-label="sugerencia marcas - {{brand.name}}"
                            (click)="sendFilter(brand.name, 'brand')">
                  {{brand.name}}
                </mat-option>
              </mat-optgroup>
              <hr style="width:95%; margin:0 auto; border: 1px solid #D3D4D3;">
              <mat-optgroup label="Equipos">
                <mat-option *ngFor="let option of filterPhones | async" [value]="option.name"
                            appDatalayer gtm-category="catalogo b2b – buscador header" gtm-label="sugerencia equipos - {{option.name}}"
                            (click)="sendFilter(option.name, 'phones')">
                  {{option.name}}
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
            <span matSuffix>
              <custom-icon *ngIf="isOpenAutocomplete" [svgName]="'flecha-arriba_gris'" [classIcon]="'searchIcon'">
              </custom-icon>
              <custom-icon *ngIf="!isOpenAutocomplete" [class]='typeClass' [typeStyle]="typeStyle"
                           appDatalayer gtm-category="catalogo b2b – buscador header" gtm-label="buscar"
                           [svgName]="'icono-search_codigo'" [classIcon]="'searchIcon'" (click)="showSearch()">
              </custom-icon>
            </span>
                    </mat-form-field>
                </form>
            </div>
            <div class="headers-button" [class]='typeClass'>
                <div [class.hidden]="activeAutocomplete" class="header-content-search" id="boton-modal-search" (click)="showSearch()">
                    <custom-icon class="center-icon" [typeStyle]="typeStyle" [svgName]="'icono-search_codigo'" [classIcon]="'menuSearch'"></custom-icon>
                </div>
                <div *ngIf="isShowFilter" class="header-content-filtro" id="boton-modal-filtro" (click)="showFilter()">
                    <custom-icon class="center-icon" [typeStyle]="typeStyle" [svgName]="'icon-filtros_codigo_blanco'" [classIcon]="'menuFilter'">
                    </custom-icon>
                </div>
            </div>
        </div>
    </div>
</header>
