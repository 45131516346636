import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import {Observable} from 'rxjs';
import {ObsService} from 'src/app/service/obs.service';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {ServiceService} from '../../service/service.service';
import {Router} from '@angular/router';
import {DatalayerService} from '../../shared/services/datalayer.service';
import { DatalayerClickGa4Service } from 'src/app/shared/services/datalayer-click-ga4.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  topHeader: boolean;
  top;
  @ViewChild('header') header: ElementRef;

  @HostListener('window:scroll', ['$event']) scrollAutocomplete() {
    this.obs.getValue(this.obs.activeHome).subscribe(resp => {
      if (resp == true) {
        this.scrollStyle();
      }
    });
  }

  @Input() isShowFilter: boolean = true;
  showHeaderAutocomplete: boolean;
  myControl = new FormControl();
  filterPhones: Observable<any[]>;
  filterBrand: Observable<any[]>;
  brandItem: Array<any> = [];
  autocompleteItem: Array<any> = [];

  isOpenAutocomplete: boolean = false;
  activeAutocomplete: boolean = false;
  /* Valor*/
  typeStyle: string = '#FFFFFF';
  typeClass: string = '';
  urlLogoDefault: string = '../../../assets/images/logo-blanco.svg';

  categoryClick= 'header';
  actionClick= 'click';
  labelClick= 'logo';
  categoryClick2= 'buscador';
  labelClick2= '¿que equipo necesitas?';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private obs: ObsService,
    private service: ServiceService,
    private router: Router,
    private readonly datalayer: DatalayerService,
    private svcClickDataLayer: DatalayerClickGa4Service,
  ) {

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.matchMedia('(min-width: 425px)').matches) {
        this.obs.getValue(this.obs.showAutocomplete).subscribe(resp => {
          this.showHeaderAutocomplete = resp;
        });
      } else {
        this.showHeaderAutocomplete = false;
      }
    }

    this.obs.getValue(this.obs.activeHome).subscribe(resp => {
      this.topHeader = resp;
    });

    this.obs.getValue(this.obs.brandList).subscribe(resp => this.brandItem = resp);
    this.obs.getValue(this.obs.autocompleteList).subscribe(resp => this.autocompleteItem = resp);

    //Filtro de autocomplete para nombre de equipos
    this.filterPhones = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterPhone(value))
    );

    //Filtro de autocomplete para nombre de marcas
    this.filterBrand = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBrand(value))
    );

    // Servicio Banner
    this.service.getLogo().subscribe(resp => {
      this.typeStyle = '';
      if (resp.id === 2) {
        this.typeStyle = '#019DF4';
        this.typeClass = 'classAzulType';
        this.urlLogoDefault = '../../../assets/images/logo-celeste.svg';
      } else {
        this.typeClass = '';
        this.typeStyle = '#FFFFFF';
        this.urlLogoDefault = '../../../assets/images/logo-blanco.svg';
      }
    });
  }

  onClickHeaderDataLayer() {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick,
    },8)
  }
  onClickBuscadorDataLayer() {
    this.svcClickDataLayer.datalayerClickGa4({
      gtmCategory: this.categoryClick2,
      gtmAction: this.actionClick,
      gtmLabel: this.labelClick2,
    },11)
  }

  showFilter() {
    this.obs.setValue(this.obs.showFilter, true);
  }

  showSearch() {
    this.activeAutocomplete = !this.activeAutocomplete;
  }

  ngAfterContentChecked(): void {
    this.obs.getValue(this.obs.activeHome).subscribe(resp => {
      if (resp == true) {
        this.scrollStyle();
      }
    });
  }

  //Funcion que filtrar el nombre de equipos segun el valor del input
  private _filterPhone(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.autocompleteItem.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  //Funcion que filtrar el nombre de marcas segun el valor del input
  private _filterBrand(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.brandItem.filter(brand => brand.name.toLowerCase().indexOf(filterValue) === 0);
  }

  //Funciones cuando se abre y cierra el input de autocompletado
  openPanel() {
    this.isOpenAutocomplete = true;
  }

  closePanel() {
    this.isOpenAutocomplete = false;
  }

  scrollStyle() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.header != undefined) {
        this.top = this.header.nativeElement.getBoundingClientRect().top;
        if (this.top == 0) {
          this.topHeader = true;
        } else {
          this.topHeader = false;
        }
      }
    }
  }

  sendFilter(item, type): void {
    this.router.navigate(['/']);
    this.obs.setValue(this.obs.autoFilterHeader, {element: item, type});
  }

  reload(event): void {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 8) {
      this.obs.setValue(this.obs.reload, true);
    }
  }

}
