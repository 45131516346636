import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, DomSanitizer, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './utilities/header/header.component';
import { FooterComponent } from './utilities/footer/footer.component';
import { DetailComponent } from './detail/detail.component';
import { ModalComparadorComponent } from './modal-comparador/modal-comparador.component';
// import { SwiperModule } from 'swiper/angular';
import { ComparadorComponent } from './comparador/comparador.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { NgxPaginationModule } from 'ngx-pagination';
import { ModalFormComponent } from './modal-form/modal-form.component';
import { ApiService } from './service/api.service';
import { ServiceService } from './service/service.service';
import { HttpClientModule } from '@angular/common/http';
import { CustomSelectComponent } from './utilities/custom-select/custom-select.component';
import {
  SwiperModule,
  SwiperConfigInterface,
  SWIPER_CONFIG
} from 'ngx-swiper-wrapper';
import { SwiperNgxComponent } from './swiper-ngx/swiper-ngx.component';
import { CustomInputComponent } from './utilities/custom-input/custom-input.component';
import { CustomIconComponent } from './utilities/custom-icon/custom-icon.component';
import { MaterialModule } from './utilities/material.module';
import { ModalFilterComponent } from './modal-filter/modal-filter.component';
import { ErrorPageComponent } from './utilities/error-page/error-page.component';
import {DirectivesModule} from './shared/directives/directives.module';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ComparadorComponent,
    ModalFormComponent,
    DetailComponent,
    ModalComparadorComponent,
    ModalFormComponent,
    ModalFilterComponent,
    CustomSelectComponent,
    SwiperNgxComponent,
    CustomInputComponent,
    CustomIconComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SwiperModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSliderModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    HttpClientModule,
    MaterialModule,
    DirectivesModule
  ],

  exports: [
    SwiperModule
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  providers: [
    ApiService,
    // ServiceService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
