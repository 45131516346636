import {Directive, HostListener, Input} from '@angular/core';
import {ServiceService} from '../../service/service.service';
import {ValidateHelper} from '../../utilities/validate.helper';

export class Datalayer {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  marca?: string;
  tipo?: string;
  codigo?: string;
  nombre?: string;
  precio?: string;

  static basicPush(obj: Datalayer): void {
    const data = {
      event: obj.event,
      eventCategory: obj.eventCategory,
      eventAction: obj.eventAction,
      eventLabel: obj.eventLabel
    };
    // console.log(JSON.stringify(data));
    (window as any).dataLayer.push(data);
  }

  static equipmentPush(obj: Datalayer): void {
    const data = {
      event: obj.event,
      eventCategory: obj.eventCategory,
      eventAction: obj.eventAction,
      eventLabel: obj.eventLabel,
      marca: ValidateHelper.validString(obj.marca),
      tipo: ValidateHelper.validString(obj.tipo)
    };
    // console.log(JSON.stringify(data));
    (window as any).dataLayer.push(data);
  }

  static dataPush(obj: Datalayer): void {
    const data = {
      event: obj.event,
      eventCategory: obj.eventCategory,
      eventAction: obj.eventAction,
      eventLabel: obj.eventLabel,
      marca: ValidateHelper.validString(obj.marca),
      tipo: ValidateHelper.validString(obj.tipo),
      codigo: ValidateHelper.validString(obj.codigo),
      nombre: ValidateHelper.validString(obj.nombre),
      precio: ValidateHelper.validString(obj.precio)
    };
    // console.log(JSON.stringify(data));
    (window as any).dataLayer.push(data);
  }
}

@Directive({
  selector: '[appDatalayer]'
})
export class DatalayerDirective {
  @Input('gtm-equiment') gtmEquiment = false;
  @Input('gtm-event') gtmEvent = 'hv.event';
  @Input('gtm-action') gtmAction = 'click';
  @Input('gtm-category') gtmCategory = '';
  @Input('gtm-label') gtmLabel = '';
  @Input('gtm-brand') gtmBrand = '';
  @Input('gtm-type') gtmType = '';

  @Input('data-auto') dataAuto = false;
  @Input('data-id') dataId = '';

  constructor(private readonly service: ServiceService) {
  }

  @HostListener('click', ['$event'])
  onClick($event): void {
    if (this.dataAuto) {
      if (this.dataId) {
        this.service.getPhoneInfo(this.dataId).subscribe(resp => {
          const codigo = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DCP') : null;
          const nombre = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DNP') : null;
          const precio = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DPP') : null;
          Datalayer.dataPush({
            event: this.gtmEvent,
            eventCategory: this.gtmCategory,
            eventAction: this.gtmAction,
            eventLabel: this.gtmLabel,
            marca: ValidateHelper.datalayerString(this.gtmBrand),
            tipo: ValidateHelper.datalayerString(this.gtmType),
            codigo: codigo ? ValidateHelper.datalayerString(codigo.value) : '',
            nombre: nombre ? ValidateHelper.datalayerString(nombre.value) : '',
            precio: precio ? ValidateHelper.datalayerString(precio.value) : ''
          });
        }, () => {
          Datalayer.dataPush({
            event: this.gtmEvent,
            eventCategory: this.gtmCategory,
            eventAction: this.gtmAction,
            eventLabel: this.gtmLabel,
            marca: ValidateHelper.datalayerString(this.gtmBrand),
            tipo: ValidateHelper.datalayerString(this.gtmType)
          });
        });
      } else {
        Datalayer.dataPush({
          event: this.gtmEvent,
          eventCategory: this.gtmCategory,
          eventAction: this.gtmAction,
          eventLabel: this.gtmLabel,
          marca: ValidateHelper.datalayerString(this.gtmBrand),
          tipo: ValidateHelper.datalayerString(this.gtmType)
        });
      }
    } else {
      if (this.gtmEquiment) {
        Datalayer.equipmentPush({
          event: this.gtmEvent,
          eventCategory: this.gtmCategory,
          eventAction: this.gtmAction,
          eventLabel: this.gtmLabel,
          marca: ValidateHelper.datalayerString(this.gtmBrand),
          tipo: ValidateHelper.datalayerString(this.gtmType)
        });
      } else {
        Datalayer.basicPush({
          event: this.gtmEvent,
          eventCategory: this.gtmCategory,
          eventAction: this.gtmAction,
          eventLabel: this.gtmLabel
        });
      }
    }
  }
}
