import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComparadorComponent } from './comparador/comparador.component';
import { DetailComponent } from './detail/detail.component';
import { HomeComponent } from './home/home.component';
import { SwiperNgxComponent } from './swiper-ngx/swiper-ngx.component';
import { ErrorPageComponent } from './utilities/error-page/error-page.component';


const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "comparar-equipos", component: ComparadorComponent },
  { path: "detalle/:name", component: DetailComponent },
  { path: "no-encontrada", component: ErrorPageComponent },
  { path: "**", redirectTo: "/no-encontrada" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
