<section class="section-modales">
  <div class="modal-bg modal-filtro modal-bg-active" id="modal-filtro">
    <div class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-header-titulo">Filtros</h3>
          <p class="modal-header-descripcion">Encuentra el equipo ideal</p>
          <button class="modal-header-button-cerrar" (click)="close()"><span><img
                src="../../assets/images/icon-cerrar-celeste.svg" alt="icon cerrar"></span></button>
        </div>
        <div class="modal-body">
          <div class="modal-body-content">
            <div class="palabras-filtradas--modal">
              <mat-chip-list #chipList aria-label="filter selection" class="palabras-filtradas__item">
                <mat-chip class="palabras-filtradas__item-body" *ngFor="let filter of filterItem"
                  [selectable]="selectable" [removable]="removable" (removed)="remove(filter.name)"
                  [removable]="removable">
                  {{filter.name}}
                  <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <button class="button-limpiar--modal" (click)="clean()">Limpiar filtros</button>
            <h3 class="filtros-subtitulo--modal">Ordenar por</h3>
            <div class="buscador--rutas">
              <mat-chip-list class="ordenar-contenedor--modal" aria-label="line selection">
                <mat-chip *ngFor="let sort of sortItem" class="ordenar-item" (click)="activateClassSort(sort)"
                  [class.active]="sort.active">{{sort.name}}</mat-chip>
              </mat-chip-list>
            </div>
            <h3 class="filtros-subtitulo--modal">Modalidad</h3>
            <div class="buscador--rutas">
              <mat-chip-list class="ordenar-contenedor--modal" aria-label="line selection">
                <mat-chip *ngFor="let search of searchItem" class="ordenar-item" (click)="activateClassSearch(search)"
                  [class.active]="search.active">{{search.name}}</mat-chip>
              </mat-chip-list>
            </div>
            <h3 class="filtros-subtitulo--modal">Marca</h3>
            <ul class="checkbox-contenedor--modal" id="brandCheckbox">
              <li class="checkbox-contenedor__item" *ngFor="let brand of brandItem">
                <mat-checkbox (change)="selectBrand($event, brand.name, 'brand')" [(ngModel)]="brand.active">
                  {{brand.name}}
                </mat-checkbox>
              </li>
            </ul>
            <h3 class="filtros-subtitulo--modal">Planes</h3>
            <ul class="checkbox-contenedor--modal" id="planCheckbox">
              <li class="checkbox-contenedor__item" *ngFor="let plan of planItem">
                <mat-checkbox (change)="selectBrand($event, plan.name, 'plan')" [(ngModel)]="plan.active">{{plan.name}}
                </mat-checkbox>
              </li>
            </ul>
            <h3 class="filtros-subtitulo--modal filtros-subtitulo--modal_precio">Precios de equipos</h3>
            <div class="range-container">
              <ngx-slider class="multi-range-slider" [(value)]="value" [(highValue)]="highValue" [options]="options"
                (touchend)="sliderEvent(value, highValue)">
              </ngx-slider>
              <div class="flex-form">
                <mat-form-field>
                  <span matPrefix>S/ &nbsp;</span>
                  <input matInput [(ngModel)]="value">
                </mat-form-field>
                <mat-form-field>
                  <span matPrefix>S/ &nbsp;</span>
                  <input matInput [(ngModel)]="highValue">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="button-modal-filtrar" (click)="apliFilter()">APLICAR FILTROS</button>
  </div>

</section>
