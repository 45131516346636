<app-header [isShowFilter]="false"></app-header>
<section class="section-header-paginacion">
    <div class="section-header-paginacion-contenedor"><a class="section-header-paginacion__enlace" href="#">Inicio</a><span class="section-header-paginacion_-separador"><svg width="7" height="12"
        viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.628989 11.9995C0.452839 11.9995 0.284921 11.921 0.166391 11.7861C-0.0690243 11.5129 -0.0525617 11.0963 0.202608 10.8436L5.19077 5.95531L0.205901 1.16091C-0.0509155 0.909924 -0.0690244 0.493317 0.164744 0.218425C0.390281 -0.0479308 0.780444 -0.0752492 1.03726 0.158665C1.0422 0.163788 1.04714 0.167202 1.05208 0.172325L6.54399 5.45674C6.67569 5.58309 6.74977 5.76066 6.74977 5.94677C6.75142 6.13288 6.67898 6.31216 6.54893 6.44021L1.05537 11.822C0.940754 11.9361 0.787917 11.9998 0.628989 11.9995Z"
          fill="#86888C" />
      </svg></span><a class="section-header-paginacion__enlace active" href="javascript:void(0);"
                      appDatalayer [gtm-equiment]="true" gtm-category="catalogo b2b – detalle equipo"
                      gtm-label="breadcrumbs - {{phoneModel}}" [gtm-brand]="phoneBrand" [gtm-type]="valorModalidad">{{phoneModel}}</a></div>
</section>
<section class="section-detalle-equipo" style="padding-top: 64px;">
    <div class="contenedor-general">
        <div class="detalle-equipo">
            <div class="detalle-equipo__left swiper-thumbs">

                <div class="container">
                    <div class="gallery">
                        <img [src]="selectImage">
                    </div>
                    <div class="row"> 
                        <div class="upBtn">
                            <custom-icon [svgName]="'flecha-arriba_gris'" [classIcon]="'upArrow'"></custom-icon>
                        </div>
                        <swiper fxFlex="auto" [config]="config" (swiper)="onSwiperThumbs($event)" (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')" (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
                            <div class="column" *ngFor="let phoneT of phonesItem; let i = index" (click)="DataLayerClick(1)">
                                <img class="demo cursor thumbs" [class.active]="slideIndex == i" [src]="phoneT.url"
                                     appDatalayer [gtm-equiment]="true" gtm-category="catalogo b2b – detalle equipo "
                                     gtm-label="foto" [gtm-brand]="phoneBrand" [gtm-type]="valorModalidad"
                                     (click)="showSlides(phoneT.url)">
                            </div>
                        </swiper>
                        <div class="downBtn">
                            <custom-icon [svgName]="'flecha-abajo-gris'" [classIcon]="'downArrow'"></custom-icon>
                        </div>
                    </div>
                </div>

            </div>
            <div class="detalle-equipo__right">
                <div class="detalle-equipo__right-content">
                    <div class="detalle-equipo-info">
                        <p class="detalle-equipo-info__marca">{{phoneBrand}}</p>
                        <h1 class="detalle-equipo-info__nombre">{{phoneModel}}</h1>
                        <div class="detalle-equipo-info__colores">
                            <div class="detalle-equipo-info__colores-left">
                                <p><span class="detalle-equipo-info__colores-left-top">Colores</span><span class="detalle-equipo-info__colores-left-bottom">(Sujeto a stock) </span></p>
                            </div>
                            <div class="detalle-equipo-info__colores-right">
                                <button class="button-color button-color--azul" appDatalayer [gtm-equiment]="true"
                                        gtm-category="catalogo b2b – detalle equipo" gtm-label="color - azul"
                                        [gtm-brand]="phoneBrand" [gtm-type]="valorModalidad"
                                        (click)="DataLayerClick(2)"></button>
                                <button class="button-color button-color--gris" appDatalayer [gtm-equiment]="true"
                                        gtm-category="catalogo b2b – detalle equipo" gtm-label="color - gris"
                                        [gtm-brand]="phoneBrand" [gtm-type]="valorModalidad"
                                        (click)="DataLayerClick(3)"></button>
                            </div>
                        </div>
                        <div class="detalle-equipo-info__memoria">
                            <div class="detalle-equipo-info__memoria-left">
                                <p><span class="detalle-equipo-info__memoria-left-top">Almacenamiento Interno</span></p>
                            </div>
                            <mat-chip-list class="detalle-equipo-info__memoria-right">
                                <mat-chip *ngFor="let memory of memoryList" class="button-memoria"
                                          appDatalayer [gtm-equiment]="true" gtm-category="catalogo b2b – detalle equipo"
                                          gtm-label="memoria - {{memory.name}}" [gtm-brand]="phoneBrand" [gtm-type]="valorModalidad"
                                          (click)="selectMemory(memory)" [class.active]="memory.selected">{{memory.name}}</mat-chip>
                            </mat-chip-list>
                            <p class="detalle-equipo-info__memoria-bottom">Stock referencial de acuerdo a consumo diario del día anterior.
                            </p>
                        </div>
                        <div class="detalle-equipo-info__caracteristicas detalle-equipo-info__caracteristicas-top">
                            <div class="select-modalidad" id="select-modalidad" (click)="DataLayerClick(4)">
                                <app-custom-select (sendValue)='changed($event,"modalidad")' [label]="'Modalidad'" [holderSelect]="'Elegir'" [options]="modalidadList" [setValue]="valorModalidad">
                                </app-custom-select>
                            </div>
                            <div class="select-pay-type" id="select-pay-type" (click)="DataLayerClick(5)">
                                <app-custom-select (sendValue)='changed($event,"payType")' [label]="'Formade de pago'" [holderSelect]="'Elegir'" [options]="payTypeList" [setValue]='valorPayType'>
                                </app-custom-select>
                            </div>
                        </div>
                        <div class="detalle-equipo-info__caracteristicas">
                            <div class="select-plan" id="select-plan" (click)="DataLayerClick(6)">
                                <app-custom-select (sendValue)='changed($event,"plan")' [label]="'Plan'" [holderSelect]="'Elegir'" [options]="planList" [setValue]='valorPlan'>
                                </app-custom-select>
                            </div>
                        </div>
                        <div class="detalle-equipo-info__dettale-plan">
                            <div class="acordion-general acordion-dettale-plan" id="acordion_detalle_plan" [class.active-acordion]="openedDetail">
                                <div class="acordion-general__header acordion-dettale-plan__header" (click)="openDetail()">
                                    <button class="acordion-general__button acordion-dettale-plan__button" id="acordion_detalle_button"
                                            appDatalayer [gtm-equiment]="true" gtm-category="catalogo b2b – detalle equipo"
                                            gtm-label="ver detalle del plan" [gtm-brand]="phoneBrand" [gtm-type]="valorModalidad"><span class="acordion-dettale-plan__button-info" (click)="DataLayerClick(7)">Ver detalle del
                      Plan</span><span><svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 0.869831C0 0.626233 0.104724 0.394018 0.284576 0.230102C0.648833 -0.0954539 1.20433 -0.0726878 1.54126 0.280188L8.05919 7.17833L14.4519 0.284741C14.7866 -0.0704113 15.3421 -0.095454 15.7086 0.227825C16.0637 0.539722 16.1002 1.07928 15.7883 1.43443C15.7814 1.44126 15.7769 1.44809 15.7701 1.45492L8.72396 9.04971C8.55549 9.23184 8.31873 9.33429 8.07058 9.33429C7.82243 9.33656 7.58338 9.23639 7.41264 9.05654L0.236767 1.45947C0.0845137 1.30097 -0.000356674 1.08961 0 0.869831Z"
                          fill="#019DF4" />
                      </svg></span></button>
                                </div>
                                <div class="acordion-general__body acordion-dettale-plan__body" id="acordion_detalle_plan_body">
                                    <div class="acordion-general__body-content acordion-dettale-plan__content">
                                        <p class="acordion-dettale-plan__content-titulo">{{listDetatitle}}</p>
                                        <h3 class="acordion-dettale-plan__content-subtitulo">{{listDetaDes}}</h3>
                                        <ul class="acordion-dettale-plan__content_list">
                                            <li class="acordion-dettale-plan__content_item" *ngFor="let list of listDeta; let i = index">
                                                <div class="acordion-dettale-plan__content_content">
                                                    <div class="acordion-dettale-plan__content_content--icono">
                                                        <img [src]='list.icon' [alt]="list.title">
                                                    </div>
                                                    <div class="acordion-dettale-plan__content_content--info">
                                                        <p class="acordion-dettale-plan__content_content--info-top">{{list.title}}</p>
                                                        <p class="acordion-dettale-plan__content_content--info-bottom">{{list.description}}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detalle-equipo-info__resumen">
                            <h3 class="detalle-equipo-info__resumen-titulo">Resumen de precioss</h3>
                            <div class="detalle-equipo-info__resumen-cuota" *ngIf="detailNoAll === false">
                                <div class="detalle-equipo-info__resumen-cuota--left">
                                    <div class="info">
                                        <p class="info__body"><span class="info__top">Al contado</span><span class="info__center">S/
                        <span [innerHTML]='numberWithSpaces(pricebasic)' ></span></span>
                                        </p>
                                        <p class="info__bottom" style="display: none;"><span>En tienda: </span><span class="info__antes frase-tachada">S/
                        3150</span></p>
                                    </div>
                                </div>
                                <div class="detalle-equipo-info__resumen-cuota--right">
                                    <div class="info">
                                        <p class="info__body">
                                            <span class="info__top">Precio del plan</span>
                                            <span class="info__center">
                        S/ <span [innerHTML]='valuePlanDinamic'></span>
                                            </span>
                                        </p>
                                        <p class="info__bottom" style="display: none;"><span>Antes: </span><span class="info__antes frase-tachada">S/
                        95.90</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="detalle-equipo-info__resumen-plazos" *ngIf="detailNoAll ===true">
                                <div class="detalle-equipo-info__resumen-plazos--left">
                                    <div class="info">
                                        <p class="info__body"><span class="info__top">Cuota inicial</span><span class="info__center">S/
                        0</span></p>
                                        <p class="info__bottom" style="display: none;"><span>En tienda: </span><span class="info__antes frase-tachada">S/
                        3150</span></p>
                                    </div>
                                </div>
                                <div class="detalle-equipo-info__resumen-plazos--right">
                                    <div class="detalle-equipo-info__resumen-plazos--top">
                                        <div class="info info-1">
                                            <p class="info__body"><span class="info__top"><span [innerHTML]='valuePagoDinamic'></span> por</span>
                                                <span class="info__center">S/<span [innerHTML]='valuePricePagoDinamic'></span>
                                                </span>
                                            </p>
                                        </div>
                                        <div class="info info-2">
                                            <p class="info__body"><span class="info__top">Precio del plan</span><span class="info__center">S/
                    <span [innerHTML]='valuePlanDinamic' ></span></span>
                                            </p>
                                        </div>
                                        <div class="icono-mas"><svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2.98769 5.392H3.99569V3.232H6.15569V2.224H3.99569V0.0799994H2.98769V2.224H0.843688V3.232H2.98769V5.392Z"
                          fill="#019DF4" />
                      </svg>
                                        </div>
                                    </div>
                                    <div class="detalle-equipo-info__resumen-plazos--bottom">
                                        <div class="info">
                                            <p class="info__body">
                                                <span class="info__top">Precio total del mes</span>
                                                <span class="info__center">S/<span [innerHTML]='valueTotalDinamic'  ></span>
                                                </span>
                                            </p>
                                            <p class="info__bottom" style="display: none;"><span>Antes: </span><span class="info__antes frase-tachada">S/
                          215.90</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detalle-equipo-info__delivery">
                            <p class="detalle-equipo-info__delivery--top"> <span>Delivery gratis</span><span><svg width="24"
                    height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.63516 18.8C6.12195 18.8 5.25391 17.9327 5.25391 16.4201C5.25391 14.9076 6.12195 14.04 7.63516 14.04C9.14837 14.04 10.0164 14.9076 10.0164 16.4201C10.0164 17.9327 9.14837 18.8 7.63516 18.8ZM7.63516 15.1317C6.73188 15.1317 6.34648 15.517 6.34648 16.4201C6.34648 17.3232 6.73188 17.7083 7.63516 17.7083C8.53845 17.7083 8.92385 17.323 8.92385 16.4201C8.92385 15.5173 8.53845 15.1314 7.63516 15.1314V15.1317Z"
                      fill="#019DF4" />
                    <path
                      d="M16.6884 18.8C15.1752 18.8 14.3071 17.9327 14.3071 16.4201C14.3071 14.9076 15.1752 14.04 16.6884 14.04C18.2016 14.04 19.0696 14.9076 19.0696 16.4201C19.0696 17.9327 18.2016 18.8 16.6884 18.8ZM16.6884 15.1317C15.7851 15.1317 15.3997 15.517 15.3997 16.4201C15.3997 17.3232 15.7851 17.7083 16.6884 17.7083C17.5917 17.7083 17.9771 17.323 17.9771 16.4201C17.9771 15.5173 17.5914 15.1314 16.6884 15.1314V15.1317Z"
                      fill="#019DF4" />
                    <path
                      d="M14.6028 17.204H9.70449C9.5596 17.204 9.42065 17.1465 9.31821 17.0442C9.21576 16.9418 9.1582 16.803 9.1582 16.6582C9.1582 16.5134 9.21576 16.3746 9.31821 16.2722C9.42065 16.1699 9.5596 16.1124 9.70449 16.1124H14.6028C14.7476 16.1124 14.8866 16.1699 14.989 16.2722C15.0915 16.3746 15.149 16.5134 15.149 16.6582C15.149 16.803 15.0915 16.9418 14.989 17.0442C14.8866 17.1465 14.7476 17.204 14.6028 17.204Z"
                      fill="#019DF4" />
                    <path
                      d="M19.5882 17.2034H18.674C18.5291 17.2034 18.3902 17.1459 18.2877 17.0435C18.1853 16.9412 18.1277 16.8023 18.1277 16.6576C18.1277 16.5128 18.1853 16.374 18.2877 16.2716C18.3902 16.1692 18.5291 16.1117 18.674 16.1117H19.5885C19.8212 16.1117 20.1075 15.8257 20.1075 15.5932V12.4C20.1075 12.2136 19.7917 11.8031 19.6879 11.6683C19.367 11.251 16.9068 7.98687 16.4818 7.39681C16.3586 7.22569 16.0855 7.09168 15.8598 7.09168H5.2092C4.97648 7.09168 4.69023 7.37771 4.69023 7.61024V15.5932C4.69023 15.8257 4.97648 16.1117 5.2092 16.1117H5.74182C5.88671 16.1117 6.02566 16.1692 6.12811 16.2716C6.23055 16.374 6.28811 16.5128 6.28811 16.6576C6.28811 16.8023 6.23055 16.9412 6.12811 17.0435C6.02566 17.1459 5.88671 17.2034 5.74182 17.2034H5.2092C4.8115 17.2034 4.4037 17.0238 4.08931 16.7122C3.77493 16.4005 3.59766 15.9916 3.59766 15.5932V7.61024C3.59766 7.21286 3.77738 6.80539 4.08931 6.49126C4.40124 6.17713 4.81041 6 5.2092 6H15.8598C16.4351 6 17.0415 6.30513 17.3684 6.75927C17.7899 7.34414 20.235 10.5884 20.5541 11.0029C20.7955 11.3171 21.1998 11.8424 21.1998 12.4V15.5932C21.1998 15.9906 21.02 16.398 20.7081 16.7122C20.3962 17.0263 19.9859 17.2034 19.5882 17.2034Z"
                      fill="#019DF4" />
                    <path
                      d="M4.14368 9.22042H2.5458C2.40091 9.22042 2.26196 9.16291 2.15951 9.06055C2.05707 8.95818 1.99951 8.81935 1.99951 8.67458C1.99951 8.52981 2.05707 8.39098 2.15951 8.28861C2.26196 8.18625 2.40091 8.12874 2.5458 8.12874H4.14368C4.28856 8.12874 4.42751 8.18625 4.52996 8.28861C4.63241 8.39098 4.68996 8.52981 4.68996 8.67458C4.68996 8.81935 4.63241 8.95818 4.52996 9.06055C4.42751 9.16291 4.28856 9.22042 4.14368 9.22042Z"
                      fill="#019DF4" />
                    <path
                      d="M4.14368 11.8814H2.5458C2.40091 11.8814 2.26196 11.8239 2.15951 11.7215C2.05707 11.6191 1.99951 11.4803 1.99951 11.3355C1.99951 11.1908 2.05707 11.0519 2.15951 10.9496C2.26196 10.8472 2.40091 10.7897 2.5458 10.7897H4.14368C4.28856 10.7897 4.42751 10.8472 4.52996 10.9496C4.63241 11.0519 4.68996 11.1908 4.68996 11.3355C4.68996 11.4803 4.63241 11.6191 4.52996 11.7215C4.42751 11.8239 4.28856 11.8814 4.14368 11.8814Z"
                      fill="#019DF4" />
                    <path
                      d="M4.14368 14.5425H2.5458C2.40091 14.5425 2.26196 14.485 2.15951 14.3826C2.05707 14.2802 1.99951 14.1414 1.99951 13.9966C1.99951 13.8519 2.05707 13.713 2.15951 13.6106C2.26196 13.5083 2.40091 13.4508 2.5458 13.4508H4.14368C4.28856 13.4508 4.42751 13.5083 4.52996 13.6106C4.63241 13.713 4.68996 13.8519 4.68996 13.9966C4.68996 14.1414 4.63241 14.2802 4.52996 14.3826C4.42751 14.485 4.28856 14.5425 4.14368 14.5425Z"
                      fill="#019DF4" />
                  </svg></span></p>
                            <p class="detalle-equipo-info__delivery--bottom">*Sujeto a evaluación crediticia</p>
                        </div>
                        <div class="detalle-equipo-info__loquiero">
                            <button class="detalle-equipo-info__loquiero--button" (click)="quieroEquipo(idPhone,null)">LO QUIERO</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 

<section class="section-terminos-y-condiciones">
    <div class="contenedor-general">
        <div class="section-terminos-y-condiciones-content">
            <div class="acordion-general acordion-terminos-y-condiciones" id="acordion_detalle_planwww" [class.active-acordion]="openedSpecs">
                <div class="acordion-general__header acordion-terminos-y-condiciones__header" (click)="openSpecs()">
                    <button class="acordion-general__button" id="acordion_detalle_buttonwww"><span>Especificaciones
              técnicas</span><span><svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 0.869831C0 0.626233 0.104724 0.394018 0.284576 0.230102C0.648833 -0.0954539 1.20433 -0.0726878 1.54126 0.280188L8.05919 7.17833L14.4519 0.284741C14.7866 -0.0704113 15.3421 -0.095454 15.7086 0.227825C16.0637 0.539722 16.1002 1.07928 15.7883 1.43443C15.7814 1.44126 15.7769 1.44809 15.7701 1.45492L8.72396 9.04971C8.55549 9.23184 8.31873 9.33429 8.07058 9.33429C7.82243 9.33656 7.58338 9.23639 7.41264 9.05654L0.236767 1.45947C0.0845137 1.30097 -0.000356674 1.08961 0 0.869831Z"
                  fill="#019DF4" />
              </svg></span></button>
                </div>
                <div class="acordion-general__body acordion-especificaciones-tecnicas__body" id="acordion_detalle_plan_bodywww" *ngIf="openedSpecs">
                    <div class="acordion-general__body-content">
                        <ul class="especificaciones-tecnicas__list">
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Tecnología</span><span class="especificaciones-tecnicas__item-right">{{tecnologia}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Procesador</span><span class="especificaciones-tecnicas__item-right">{{procesador}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Almacenamiento Interno</span><span class="especificaciones-tecnicas__item-right">{{memoriaInterna}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Memoria RAM</span><span class="especificaciones-tecnicas__item-right">{{memoriaRAM}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Batería</span><span class="especificaciones-tecnicas__item-right">{{bateria}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Cámara</span><span class="especificaciones-tecnicas__item-right">Principal {{camaraTracera}} / Frontal
                    {{camaraFrontal}}</span></p>
                            </li>
                            <li class="especificaciones-tecnicas__item">
                                <p><span class="especificaciones-tecnicas__item-left">Pantalla</span><span class="especificaciones-tecnicas__item-right">{{pantalla}}</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="acordion-general acordion-terminos-y-condiciones" [class.active-acordion]="openedTerms">
                <div class="acordion-general__header acordion-terminos-y-condiciones__header" (click)="openTermsCond()">
                    <button class="acordion-general__button"><span>Terminos y condiciones</span><span><svg width="16" height="10"
                viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 0.869831C0 0.626233 0.104724 0.394018 0.284576 0.230102C0.648833 -0.0954539 1.20433 -0.0726878 1.54126 0.280188L8.05919 7.17833L14.4519 0.284741C14.7866 -0.0704113 15.3421 -0.095454 15.7086 0.227825C16.0637 0.539722 16.1002 1.07928 15.7883 1.43443C15.7814 1.44126 15.7769 1.44809 15.7701 1.45492L8.72396 9.04971C8.55549 9.23184 8.31873 9.33429 8.07058 9.33429C7.82243 9.33656 7.58338 9.23639 7.41264 9.05654L0.236767 1.45947C0.0845137 1.30097 -0.000356674 1.08961 0 0.869831Z"
                  fill="#019DF4" />
              </svg></span></button>
                </div>
                <div class="acordion-general__body acordion-terminos-y-condiciones__body" *ngIf="openedTerms">
                    <div class="acordion-general__body-content" [innerHTML]="termsInfo">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div>
    <!--class="section-footer"-->
    <app-footer [showNav]="false" [showTerms]="showTermsFooter"></app-footer>
</div>
<!--
<app-modal-filter *ngIf="isFilter"></app-modal-filter>
-->
