import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';

@NgModule({

    imports: [
        MatIconModule,
        MatChipsModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
    ],
    exports: [
        MatIconModule,
        // MatIconRegistry,
        MatChipsModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatListModule,
    ],
    providers: [],
    declarations: [
    ]
})

export class MaterialModule { }