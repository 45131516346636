import {Injectable} from '@angular/core';
import {ServiceService} from '../../service/service.service';
import {ValidateHelper} from '../../utilities/validate.helper';

@Injectable({
  providedIn: 'root'
})
export class DatalayerService {

  constructor(private readonly service: ServiceService) {
  }

  datalayerBasic(datalayer: DatalayerBasic): void {
    const data: any = {
      event: 'hv.event',
      eventCategory: datalayer.gtmCategory,
      eventAction: datalayer.gtmAction,
      eventLabel: datalayer.gtmLabel
    };
    if (datalayer.gtmAuto && datalayer.gtmDataId) {
      this.service.getPhoneInfo(datalayer.gtmDataId).subscribe(resp => {
        const codigo = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DCP') : null;
        const nombre = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DNP') : null;
        const precio = resp && resp instanceof Array ? resp.find(val => val.featureId === 'DPP') : null;
        data.codigo = codigo ? ValidateHelper.datalayerString(codigo.value) : '';
        data.nombre = nombre ? ValidateHelper.datalayerString(nombre.value) : '';
        data.precio = precio ? ValidateHelper.datalayerString(precio.value) : '';
        // console.log(JSON.stringify(data));
        (window as any).dataLayer.push(data);
      }, () => {
        // console.log(JSON.stringify(data));
        (window as any).dataLayer.push(data);
      });
    } else {
      // console.log(JSON.stringify(data));
      (window as any).dataLayer.push(data);
    }
  }

  datalayerView(datalayer: DatalayerView): void {
    const data = {
      event: 'hv.event',
      eventCategory: datalayer.gtmCategory,
      eventAction: datalayer.gtmAction,
      eventLabel: datalayer.gtmLabel,
      nonInteraction: datalayer.gtmNonInteraction
    };
    // console.log(JSON.stringify(data));
    (window as any).dataLayer.push(data);
  }

  datalayerEquiment(datalayer: DatalayerEquipment): void {
    const data: any = {
      event: 'hv.event',
      eventCategory: datalayer.gtmCategory,
      eventAction: datalayer.gtmAction,
      eventLabel: datalayer.gtmLabel,
      marca: ValidateHelper.datalayerString(datalayer.gtmBrand),
      tipo: ValidateHelper.datalayerString(datalayer.gtmType)
    };
    if (datalayer.gtmCode || datalayer.gtmName || datalayer.gtmPrice) {
      data.codigo = datalayer.gtmCode;
      data.nombre = datalayer.gtmName;
      data.precio = datalayer.gtmPrice;
    }
    // console.log(JSON.stringify(data));
    (window as any).dataLayer.push(data);
  }

  datalayerEcommerce(datalayer: DatalayerList, event = 0): void {
    const data: any = {
      event: 'hv.ecommerce',
      eventName: 'Ecommerce',
      eventCategory: datalayer.gtmCategory,
      eventAction: datalayer.gtmAction,
      eventLabel: datalayer.gtmLabel
    };
    if (event === 0) {
      data.ecommerce = {
        currencyCode: datalayer.gtmCurrencyCode,
        impressions: datalayer.gtmProducts
      };
    } else if (event === 1) {
      data.ecommerce = {
        click: {
          actionField: {
            list: datalayer.gtmActionFieldList
          },
          products: datalayer.gtmProducts
        }
      };
    } else if (event === 2) {
      data.ecommerce = {
        detail: {
          actionField: {
            list: datalayer.gtmActionFieldList
          },
          products: datalayer.gtmProducts
        }
      };
    } else if (event === 3) {
      data.ecommerce = {
        currencyCode: datalayer.gtmCurrencyCode,
        add: {
          products: datalayer.gtmProducts
        }
      };
    } else if (event === 4) {
      data.ecommerce = {
        checkout: {
          actionField: {
            step: datalayer.gtmCheckoutStep,
            option: 'c2c'
          },
          products: datalayer.gtmProducts
        }
      };
    } else if (event === 5) {
      data.ecommerce = {
        purchase: {
          actionField: {
            id: datalayer.gtmPurchaseId,
            revenue: datalayer.gtmPurchaseRevenue
          },
          products: datalayer.gtmProducts
        }
      };
    }
    // console.log(JSON.stringify(data));
    (window as any).dataLayer.push(data);
  }
}

export interface DatalayerBasic {
  gtmCategory: string;
  gtmAction: string;
  gtmLabel: string;
  gtmAuto?: boolean;
  gtmDataId?: string;
}

export interface DatalayerView extends DatalayerBasic {
  gtmNonInteraction: number;
}

export interface DatalayerEquipment extends DatalayerBasic {
  gtmBrand: string;
  gtmType: string;
  gtmCode?: string;
  gtmName?: string;
  gtmPrice?: string;
}

export interface DatalayerList extends DatalayerBasic {
  gtmCurrencyCode?: string;
  gtmActionFieldList?: string;
  gtmCheckoutStep?: number;
  gtmPurchaseId?: string;
  gtmPurchaseRevenue?: string;
  gtmProducts: Array<{
    name: string,
    id: string,
    price: string,
    brand: string,
    category: string,
    variant: string,
    list?: string,
    position?: string,
    quantity?: number
  }>;
}
